/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberReturn = {
  __typename?: 'AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberReturn';
  agreementAcceptedSuccessfully: Scalars['Boolean'];
};

export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberReturn = {
  __typename?: 'AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberReturn';
  agreementAcceptedSuccessfully: Scalars['Boolean'];
};

export type AcceptPerksLinkRequestReturn = {
  __typename?: 'AcceptPerksLinkRequestReturn';
  perksLinkAcceptedSuccessfully: Scalars['Boolean'];
};

export type ActivateBannerForCategoryAdvertisingBannerReturn = {
  __typename?: 'ActivateBannerForCategoryAdvertisingBannerReturn';
  bannerActivatedSuccessfully: Scalars['Boolean'];
};

export type ActivateMemberProfileAndPerksBannersForChamberMemberReturn = {
  __typename?: 'ActivateMemberProfileAndPerksBannersForChamberMemberReturn';
  activationSuccessful: Scalars['Boolean'];
};

export type ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn = {
  __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn';
  startDateUtcMilli: Scalars['Float'];
  memberProfileAndPerkBannerActivationFeeInDollarsAtTimeOfActivation: Scalars['Float'];
  memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation: Scalars['Float'];
  sentEmailNotificationOfUpcomingExpiry: Scalars['Boolean'];
  chamberMemberHasAcceptedAgreement: Scalars['Boolean'];
};

export type ActiveMemberProfileAndPerksBannersSettingsReturn = {
  __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn';
  activationInfo: ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn;
  reactivationInfo?: Maybe<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn>;
};

export type AddressInput = {
  streetAddress: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  postalCode: Scalars['String'];
};

export type AddressReturn = {
  __typename?: 'AddressReturn';
  streetAddress: Scalars['String'];
  city: Scalars['String'];
  province: Scalars['String'];
  postalCode: Scalars['String'];
};

export type AdminConsoleLoginEmailReturn = {
  __typename?: 'AdminConsoleLoginEmailReturn';
  adminConsoleLoginEmail: Scalars['String'];
};

export type AllCategoryAdvertisingBannersForChamberReturn = {
  __typename?: 'AllCategoryAdvertisingBannersForChamberReturn';
  id: Scalars['String'];
  chamberId: Scalars['String'];
  categoryAdvertisingBanners: Array<CategoryAdvertisingBannerReturn>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum BannerContentTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type BannerInput = {
  contentUrl: Scalars['String'];
  imageLink?: Maybe<Scalars['String']>;
  contentType: BannerContentTypeEnum;
  position: Scalars['Float'];
  waitingForApprovalByChamberAdmin?: Maybe<Scalars['Boolean']>;
};

export type BannerReturn = {
  __typename?: 'BannerReturn';
  contentUrl: Scalars['String'];
  imageLink?: Maybe<Scalars['String']>;
  contentType: BannerContentTypeEnum;
  position: Scalars['Float'];
  waitingForApprovalByChamberAdmin?: Maybe<Scalars['Boolean']>;
};

export type CpaSignUpChambersForCityReturn = {
  __typename?: 'CPASignUpChambersForCityReturn';
  singleChamberInCity?: Maybe<SignUpSingleMatchingChamberReturn>;
  multipleChambersInCity?: Maybe<SignUpMultipleMatchingChambersReturn>;
  noChambersInCity_closestChambersToCity?: Maybe<SignUpNoMatchingChambersReturn>;
};

export type CapUserAgreedToRewardCampaignMutationReturn = {
  __typename?: 'CapUserAgreedToRewardCampaignMutationReturn';
  capUserAgreedSuccessfully: Scalars['Boolean'];
};

export type CapUserAgreedToRewardCampaignReturn = {
  __typename?: 'CapUserAgreedToRewardCampaignReturn';
  id: Scalars['String'];
  rewardCampaignId: Scalars['String'];
  capUserId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
};

export type CapUserPerkPartnerDiscountReturn = {
  __typename?: 'CapUserPerkPartnerDiscountReturn';
  unverified_eligiblePerkPartnerDiscountsForCurrentlyLoggedInCapUser?: Maybe<Array<PerkPartnerDiscountReturn>>;
  verified_eligiblePerkPartnerDiscountsForCurrentlyLoggedInCapUser?: Maybe<Array<PerkPartnerDiscountReturn>>;
  ineligiblePerkPartnerDiscountsForCurrentlyLoggedInCapUser?: Maybe<Array<IneligiblePerkPartnerDiscountReturn>>;
};

export type CapUserShareLeadingToOpenRecordInput = {
  sharerUserId: Scalars['String'];
  sharedItemId: Scalars['String'];
  shareType: CapUserShareLeadingToOpenRecordShareTypeEnum;
  sharedItemOwnedByChamberId: Scalars['String'];
  sharerChamberIdWhenShareExecuted: Scalars['String'];
  shareeIsBrandNewUser: Scalars['Boolean'];
  onlyTrackingAppOpenAndNotSharedItemOpen?: Maybe<Scalars['Boolean']>;
  capUniqueInstallationIdForLoggedOutCapUserTracking?: Maybe<Scalars['String']>;
};

export type CapUserShareLeadingToOpenRecordReturn = {
  __typename?: 'CapUserShareLeadingToOpenRecordReturn';
  id: Scalars['String'];
  sharerUserId: Scalars['String'];
  shareeUserId: Scalars['String'];
  sharedItemId: Scalars['String'];
  sharedItemOwnedByChamberId: Scalars['String'];
  sharerChamberIdWhenShareExecuted: Scalars['String'];
  shareeChamberIdWhenShareOpened: Scalars['String'];
  shareType: CapUserShareLeadingToOpenRecordShareTypeEnum;
  shareeIsBrandNewUser: Scalars['Boolean'];
  onlyTrackingAppOpenAndNotSharedItemOpen?: Maybe<Scalars['Boolean']>;
  capUniqueInstallationIdForLoggedOutCapUserTracking?: Maybe<Scalars['String']>;
  createdAtUtcMilli: Scalars['Float'];
};

export enum CapUserShareLeadingToOpenRecordShareTypeEnum {
  Discount = 'DISCOUNT',
  ChamberMember = 'CHAMBER_MEMBER'
}

export type CapUserShareRecordReturn = {
  __typename?: 'CapUserShareRecordReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  sharedItemId: Scalars['String'];
  sharedItemOwnedByChamberId: Scalars['String'];
  sharerChamberIdWhenShareExecuted: Scalars['String'];
  shareType: InAppShareTypes;
  createdAtUtcMilli: Scalars['Float'];
};

export type CapUserViewedRewardCampaignMutationReturn = {
  __typename?: 'CapUserViewedRewardCampaignMutationReturn';
  capUserViewedSuccessfully: Scalars['Boolean'];
};

export type CapUserViewedRewardCampaignReturn = {
  __typename?: 'CapUserViewedRewardCampaignReturn';
  id: Scalars['String'];
  rewardCampaignId: Scalars['String'];
  capUserId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
};

export type CategoryAdvertisingBannerActivationInfoInput = {
  chamberMemberId: Scalars['String'];
  bannerFeeOptionSetAtActivationTime: CategoryAdvertisingBannersFeeOptionsInput;
  activationStartDateUtcMilli: Scalars['Float'];
  autoApproveBannerContentUpdate?: Maybe<Scalars['Boolean']>;
};

export type CategoryAdvertisingBannerActivationInfoReturn = {
  __typename?: 'CategoryAdvertisingBannerActivationInfoReturn';
  banner?: Maybe<Array<BannerReturn>>;
  chamberMemberId: Scalars['String'];
  bannerFeeOptionSetAtActivationTime: CategoryAdvertisingBannersFeeOptionsReturn;
  activationStartDateUtcMilli: Scalars['Float'];
  categoryAdvertisingBannersActivationFeeCpaSplitPercentageSetAtActivationTime: Scalars['Float'];
  sentEmailNotificationOfUpcomingExpiry: Scalars['Boolean'];
  chamberMemberHasAcceptedAgreement: Scalars['Boolean'];
  autoApproveBannerContentUpdate?: Maybe<Scalars['Boolean']>;
};

export type CategoryAdvertisingBannerReactivationInfoInput = {
  chamberMemberId: Scalars['String'];
  bannerFeeOptionSetAtActivationTime: CategoryAdvertisingBannersFeeOptionsInput;
  autoApproveBannerContentUpdate?: Maybe<Scalars['Boolean']>;
};

export type CategoryAdvertisingBannerReturn = {
  __typename?: 'CategoryAdvertisingBannerReturn';
  categoryId: ChamberMemberCategory;
  bannerSettings?: Maybe<CategoryAdvertisingBannerSettingsReturn>;
};

export type CategoryAdvertisingBannerSettingsReturn = {
  __typename?: 'CategoryAdvertisingBannerSettingsReturn';
  activationInfo: CategoryAdvertisingBannerActivationInfoReturn;
  reactivationInfo?: Maybe<CategoryAdvertisingBannerActivationInfoReturn>;
};

export enum CategoryAdvertisingBannerUpdateRecordEventEnum {
  Activation = 'ACTIVATION',
  Reactivation = 'REACTIVATION',
  Expiration = 'EXPIRATION',
  ChamberMemberAgreementAcceptance = 'CHAMBER_MEMBER_AGREEMENT_ACCEPTANCE',
  ContentUpdate = 'CONTENT_UPDATE',
  ContentUpdateAutoApprovalChange = 'CONTENT_UPDATE_AUTO_APPROVAL_CHANGE'
}

export type CategoryAdvertisingBannerUpdateRecordReturn = {
  __typename?: 'CategoryAdvertisingBannerUpdateRecordReturn';
  id: Scalars['String'];
  triggeringUserId: Scalars['String'];
  chamberId: Scalars['String'];
  categoryId: ChamberMemberCategory;
  recordEvent: CategoryAdvertisingBannerUpdateRecordEventEnum;
  bannerAtEventTrigger: CategoryAdvertisingBannerSettingsReturn;
  excludeFromInvoice?: Maybe<Scalars['Boolean']>;
  invoiceSentAtUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  activationDateUtcMilli: Scalars['Float'];
  expirationDateUtcMilli: Scalars['Float'];
};

export type CategoryAdvertisingBannersFeeOptionsInput = {
  timeLengthInMonths: Scalars['Float'];
  bannerFeeInDollars: Scalars['Float'];
};

export type CategoryAdvertisingBannersFeeOptionsReturn = {
  __typename?: 'CategoryAdvertisingBannersFeeOptionsReturn';
  timeLengthInMonths: Scalars['Float'];
  bannerFeeInDollars: Scalars['Float'];
};

export type CategoryReturn = {
  __typename?: 'CategoryReturn';
  id: ChamberMemberCategory;
  name: Scalars['String'];
};

export type ChamberAdminUserReturn = {
  __typename?: 'ChamberAdminUserReturn';
  chamberAddedSuccessfully: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
  failureReason?: Maybe<Scalars['String']>;
};

export type ChamberEngageActivationToMarkInvoicedInput = {
  activationId: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
  invoiceSentAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum ChamberEngageActivationTypeEnum {
  CategoryAdvertising = 'CATEGORY_ADVERTISING',
  MemberProfileAndPerksBanners = 'MEMBER_PROFILE_AND_PERKS_BANNERS'
}

export type ChamberEngageMonthlyInvoiceChamberDataReturn = {
  __typename?: 'ChamberEngageMonthlyInvoiceChamberDataReturn';
  chamberId: Scalars['String'];
  chamberName: Scalars['String'];
  memberProfileAndPerksBannersActivated: Scalars['Float'];
  memberProfileAndPerksBannersTotalActivationFeeInDollars: Scalars['Float'];
  categoryAdvertisingActivated: Scalars['Float'];
  categoryAdvertisingTotalActivationFeeInDollars: Scalars['Float'];
  activations?: Maybe<Array<ChamberEngageMonthlyInvoiceIndividualActivationDataReturn>>;
  allInvoicesSentForChamberInMonth: Scalars['Boolean'];
};

export type ChamberEngageMonthlyInvoiceDataReturn = {
  __typename?: 'ChamberEngageMonthlyInvoiceDataReturn';
  year: Scalars['Float'];
  monthNum: Scalars['Float'];
  totalActivationFeeInDollars: Scalars['Float'];
  allInvoicesSentInMonth: Scalars['Boolean'];
  chamberData?: Maybe<Array<ChamberEngageMonthlyInvoiceChamberDataReturn>>;
};

export type ChamberEngageMonthlyInvoiceIndividualActivationDataReturn = {
  __typename?: 'ChamberEngageMonthlyInvoiceIndividualActivationDataReturn';
  activationId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  chamberMemberName: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
  activationLengthInMonths: Scalars['Float'];
  activationFeeInDollars: Scalars['Float'];
  includeInInvoice: Scalars['Boolean'];
  invoiceSentAtUtcMilli?: Maybe<Scalars['Float']>;
  categoryAdvertisingCategoryId?: Maybe<ChamberMemberCategory>;
};

export type ChamberEngageSettingsInput = {
  isActive: Scalars['Boolean'];
  memberProfileAndPerkBannerActivationFeeInDollars: Scalars['Float'];
  memberProfileAndPerkBannerAnnualBannerFeeInDollars: Scalars['Float'];
  categoryAdvertisingBannersFeeOptions: Array<CategoryAdvertisingBannersFeeOptionsInput>;
  categoryAdvertisingBannersActivationFeeCpaSplitPercentage: Scalars['Float'];
};

export type ChamberEngageSettingsReturn = {
  __typename?: 'ChamberEngageSettingsReturn';
  isActive: Scalars['Boolean'];
  memberProfileAndPerkBannerActivationFeeInDollars: Scalars['Float'];
  memberProfileAndPerkBannerAnnualBannerFeeInDollars: Scalars['Float'];
  categoryAdvertisingBannersFeeOptions: Array<CategoryAdvertisingBannersFeeOptionsReturn>;
  categoryAdvertisingBannersActivationFeeCpaSplitPercentage: Scalars['Float'];
  activationTimeUtcMilli?: Maybe<Scalars['Float']>;
};

export type ChamberInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  address: AddressInput;
  supportContactEmail: Scalars['String'];
  supportContactName?: Maybe<Scalars['String']>;
  supportLink?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  threads?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  jobPostingLink?: Maybe<Scalars['String']>;
  jobPostingLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  jobPostingLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  eventsLink?: Maybe<Scalars['String']>;
  eventsLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  eventsLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  newsletterLink?: Maybe<Scalars['String']>;
  newsletterLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  newsletterLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  surveyLink?: Maybe<Scalars['String']>;
  surveyLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  surveyLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  advocacyWinsLink?: Maybe<Scalars['String']>;
  advocacyWinsLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  advocacyWinsLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  memberSignupLink?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  sponsorLogo?: Maybe<Scalars['String']>;
  sponsorLogoLink?: Maybe<Scalars['String']>;
  adminConsoleLoginEmail?: Maybe<Scalars['String']>;
  numberOfMembersFromInitialSubscriptionAgreement?: Maybe<Scalars['Float']>;
  subscriptionDate?: Maybe<Scalars['String']>;
  premiumChamberMarketerLink?: Maybe<Scalars['String']>;
  directoryBanner?: Maybe<Array<BannerInput>>;
  profileBanner?: Maybe<Array<BannerInput>>;
  hideInApp?: Maybe<Scalars['Boolean']>;
  isSandbox?: Maybe<Scalars['Boolean']>;
  purchasedItems?: Maybe<Array<ItemsPurchasableByChamberEnum>>;
};

export enum ChamberMemberCategory {
  Restaurant = 'RESTAURANT',
  Retail = 'RETAIL',
  PersonalServices = 'PERSONAL_SERVICES',
  CommunicationTechnology = 'COMMUNICATION_TECHNOLOGY',
  MarketingAdvertising = 'MARKETING_ADVERTISING',
  ProfessionalServices = 'PROFESSIONAL_SERVICES',
  AutomotiveTransportation = 'AUTOMOTIVE_TRANSPORTATION',
  ConstructionSuppliers = 'CONSTRUCTION_SUPPLIERS',
  Industries = 'INDUSTRIES',
  Financial = 'FINANCIAL',
  AccommodationsAttractions = 'ACCOMMODATIONS_ATTRACTIONS',
  SportsRecreation = 'SPORTS_RECREATION',
  HealthSafety = 'HEALTH_SAFETY',
  GovernmentEducation = 'GOVERNMENT_EDUCATION',
  NonProfit = 'NON_PROFIT',
  RealEstate = 'REAL_ESTATE',
  ConstructionIndustrial = 'CONSTRUCTION_INDUSTRIAL',
  FarmingAgriculture = 'FARMING_AGRICULTURE'
}

export type ChamberMemberInput = {
  id: Scalars['String'];
  chamberId: Scalars['String'];
  categoryId: Array<ChamberMemberCategory>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  threads?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  address: AddressInput;
  phoneNumber: Scalars['String'];
  contactName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  showInApp: Scalars['Boolean'];
  adminConsoleLoginEmail?: Maybe<Scalars['String']>;
  adminConsoleContactName?: Maybe<Scalars['String']>;
  customContacts?: Maybe<Array<CustomContactInput>>;
  hasSignedUp?: Maybe<Scalars['Boolean']>;
  isSuspended?: Maybe<Scalars['Boolean']>;
  banner?: Maybe<Array<BannerInput>>;
};

export enum ChamberMemberProPagePermissionsEnum {
  AllPages = 'ALL_PAGES',
  ChamberAdminMyChamberAnalytics = 'CHAMBER_ADMIN__MY_CHAMBER_ANALYTICS'
}

export type ChamberMemberProPermissionsNeededInput = {
  anyEnabledPermissionNeeded?: Maybe<Scalars['Boolean']>;
  chamberAdmin_EnabledPagesNeeded?: Maybe<Array<ChamberMemberProPagePermissionsEnum>>;
};

export type ChamberMemberReturn = {
  __typename?: 'ChamberMemberReturn';
  id: Scalars['String'];
  isChambersRootMember?: Maybe<Scalars['Boolean']>;
  chamberId: Scalars['String'];
  categoryId: Array<ChamberMemberCategory>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  threads?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  address: AddressReturn;
  phoneNumber: Scalars['String'];
  contactName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  showInApp: Scalars['Boolean'];
  coordinates?: Maybe<CoordinateReturn>;
  hasSignedUp?: Maybe<Scalars['Boolean']>;
  numberOfUsers?: Maybe<Scalars['Float']>;
  adminConsoleLoginEmail?: Maybe<Scalars['String']>;
  adminConsoleContactName?: Maybe<Scalars['String']>;
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  customContacts?: Maybe<Array<CustomContactReturn>>;
  credentialsSentAtUtcMilli?: Maybe<Scalars['Float']>;
  credentialsSentAtUtcMilliBeforeSigningUp?: Maybe<Scalars['Float']>;
  numberOfFavorites?: Maybe<Scalars['Float']>;
  currentLoggedInCapUserHasFavorited?: Maybe<Scalars['Boolean']>;
  activeMemberProfileAndPerksBannersSettings?: Maybe<ActiveMemberProfileAndPerksBannersSettingsReturn>;
  isSuspended?: Maybe<Scalars['Boolean']>;
  banner?: Maybe<Array<BannerReturn>>;
  showRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  views?: Maybe<Scalars['Float']>;
};

export type ChamberMemberUserReturn = {
  __typename?: 'ChamberMemberUserReturn';
  chamberMemberAddedSuccessfully: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
  failureReason?: Maybe<Scalars['String']>;
};

export type ChamberReturn = {
  __typename?: 'ChamberReturn';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  address: AddressReturn;
  supportContactEmail: Scalars['String'];
  supportContactName?: Maybe<Scalars['String']>;
  supportLink?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  threads?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  jobPostingLink?: Maybe<Scalars['String']>;
  jobPostingLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  jobPostingLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  eventsLink?: Maybe<Scalars['String']>;
  eventsLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  eventsLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  newsletterLink?: Maybe<Scalars['String']>;
  newsletterLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  newsletterLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  surveyLink?: Maybe<Scalars['String']>;
  surveyLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  surveyLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  advocacyWinsLink?: Maybe<Scalars['String']>;
  advocacyWinsLinkUpdatedAtUtcMilli?: Maybe<Scalars['Float']>;
  advocacyWinsLinkShowRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  memberSignupLink?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  sponsorLogo?: Maybe<Scalars['String']>;
  sponsorLogoLink?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinateReturn>;
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  numberOfMembersFromInitialSubscriptionAgreement?: Maybe<Scalars['Float']>;
  subscriptionDate?: Maybe<Scalars['String']>;
  premiumChamberMarketerLink?: Maybe<Scalars['String']>;
  googleMapsApi_City_ProvinceShort_CountryShort: Scalars['String'];
  isSandbox?: Maybe<Scalars['Boolean']>;
  allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli?: Maybe<Scalars['Float']>;
  directoryBanner?: Maybe<Array<BannerReturn>>;
  profileBanner?: Maybe<Array<BannerReturn>>;
  chamberEngageSettings: ChamberEngageSettingsReturn;
  hideInApp?: Maybe<Scalars['Boolean']>;
  purchasedItems?: Maybe<Array<ItemsPurchasableByChamberEnum>>;
};

export type ChambersStatsReturn = {
  __typename?: 'ChambersStatsReturn';
  name: Scalars['String'];
  subscriptionDate: Scalars['String'];
  subscriptionUTCMilli: Scalars['Float'];
  numberOfAppUsers: Scalars['Float'];
  numberOfAppUsersSignedUpWithAnEmployer: Scalars['Float'];
  numberOfAppUsersSignedUpWithoutAnEmployer: Scalars['Float'];
  numberOfLiveDiscounts: Scalars['Float'];
  numberOfDiscountViews: Scalars['Float'];
  numberOfDiscountRedemptions: Scalars['Float'];
  numberOfChamberMembersLiveOnApp: Scalars['Float'];
  numberOfSuspendedChamberMembers: Scalars['Float'];
  numberOfMembersFromInitialSubscriptionAgreement: Scalars['Float'];
  numberChangeInNumberOfChamberMembersBetweenNowAndInitial: Scalars['Float'];
  percentageChangeInNumberOfChamberMembersBetweenNowAndInitial: Scalars['Float'];
  numberOfChamberMembersLoggedInToAdminPortal: Scalars['Float'];
  percentageOfChamberMembersLoggedInToAdminPortal: Scalars['Float'];
  numberOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin: Scalars['Float'];
  percentageOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin: Scalars['Float'];
  numberOfChamberMembersOfferingLiveDiscounts: Scalars['Float'];
  percentageOfChamberMembersOfferingLiveDiscounts: Scalars['Float'];
  discountsPerCategoryBreakdown: Array<DiscountsPerCategoryBreakdownReturn>;
  numberOfAppUsersWithNewPerkNotificationEnabled: Scalars['Float'];
  percentageOfAppUsersWithNewPerkNotificationEnabled: Scalars['Float'];
  numberOfAppUsersWithNearbyPerkNotificationEnabled: Scalars['Float'];
  percentageOfAppUsersWithNearbyPerkNotificationEnabled: Scalars['Float'];
};

export type ChangeAdminConsoleLoginEmailReturn = {
  __typename?: 'ChangeAdminConsoleLoginEmailReturn';
  adminConsoleLoginEmailChangedSuccessfully: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
  failureReason?: Maybe<Scalars['String']>;
};

export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerReturn = {
  __typename?: 'ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerReturn';
  autoApprovalUpdateSuccessful: Scalars['Boolean'];
};

export type ChangeChamberEventInput = {
  newChamberName: Scalars['String'];
  newChamberId: Scalars['String'];
  previousChamberName: Scalars['String'];
  previousChamberId: Scalars['String'];
};

export enum ClientsThatCanAccessApiEnum {
  ChamberPerksApp = 'CHAMBER_PERKS_APP',
  ChamberPerksAppAdminConsole = 'CHAMBER_PERKS_APP_ADMIN_CONSOLE',
  ChamberMemberPro = 'CHAMBER_MEMBER_PRO'
}

export type ContactableChamberReturn = {
  __typename?: 'ContactableChamberReturn';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcTimestamp?: Maybe<Scalars['String']>;
};

export type ContactableCompanyReturn = {
  __typename?: 'ContactableCompanyReturn';
  id: Scalars['String'];
  chamberId: Scalars['String'];
  chamberName: Scalars['String'];
  name: Scalars['String'];
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcTimestamp?: Maybe<Scalars['String']>;
};

export type CoordinateReturn = {
  __typename?: 'CoordinateReturn';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateAllMemberLoginCredentialsReturn = {
  __typename?: 'CreateAllMemberLoginCredentialsReturn';
  processId: Scalars['String'];
};

export type CreateCapUserShareLeadingToOpenRecordMutationReturn = {
  __typename?: 'CreateCapUserShareLeadingToOpenRecordMutationReturn';
  recordCreatedSuccessfully: Scalars['Boolean'];
};

export type CreateChamberContactableCompanyMutationInput = {
  id: Scalars['String'];
  chamberId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateChamberMembersMutationInput = {
  data: Array<ChamberMemberInput>;
};

export type CreateChamberMembersMutationReturn = {
  __typename?: 'CreateChamberMembersMutationReturn';
  results: Array<ChamberMemberUserReturn>;
};

export type CreateChamberMutationInput = {
  data: Array<ChamberInput>;
};

export type CreateChamberMutationReturn = {
  __typename?: 'CreateChamberMutationReturn';
  results: Array<ChamberAdminUserReturn>;
};

export type CreateContactableChamberMutationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDiscountsMutationInput = {
  data: Array<DiscountInput>;
};

export type CreateDiscountsMutationReturn = {
  __typename?: 'CreateDiscountsMutationReturn';
  discountsAddedSuccessfully: Scalars['Boolean'];
};

export type CreateFavoritedChamberMemberMutationReturn = {
  __typename?: 'CreateFavoritedChamberMemberMutationReturn';
  favoritedChamberMemberAddedSuccessfully: Scalars['Boolean'];
};

export type CreateFavoritedDiscountMutationReturn = {
  __typename?: 'CreateFavoritedDiscountMutationReturn';
  favoritedDiscountAddedSuccessfully: Scalars['Boolean'];
};

export type CreatePerkPartnerDiscountAdminUserMutationReturn = {
  __typename?: 'CreatePerkPartnerDiscountAdminUserMutationReturn';
  perkPartnerDiscountAdminUserCreatedSuccessfully: Scalars['Boolean'];
  email: Scalars['String'];
  password: Scalars['String'];
  failureReason?: Maybe<Scalars['String']>;
};

export type CreatePerkPartnerDiscountMutationReturn = {
  __typename?: 'CreatePerkPartnerDiscountMutationReturn';
  perkPartnerDiscountAddedSuccessfully: Scalars['Boolean'];
};

export type CreateRedeemedDiscountMutationReturn = {
  __typename?: 'CreateRedeemedDiscountMutationReturn';
  redeemedDiscountAddedSuccessfully: Scalars['Boolean'];
};

export type CreateRedeemedDiscountsMutationInput = {
  data: Array<RedeemedDiscountInput>;
};

export type CreateRedeemedDiscountsMutationReturn = {
  __typename?: 'CreateRedeemedDiscountsMutationReturn';
  redeemedDiscountsAddedSuccessfully: Scalars['Boolean'];
};

export type CreateRewardCampaignMutationReturn = {
  __typename?: 'CreateRewardCampaignMutationReturn';
  campaignCreatedSuccessfully: Scalars['Boolean'];
};

export type CreateShopLocalCampaignPrizeDrawParticipantReturn = {
  __typename?: 'CreateShopLocalCampaignPrizeDrawParticipantReturn';
  shopLocalCampaignPrizeDrawParticipantCreatedSuccessfully: Scalars['Boolean'];
};

export type CreateShopLocalCampaignReturn = {
  __typename?: 'CreateShopLocalCampaignReturn';
  shopLocalCampaignCreatedSuccessfully: Scalars['Boolean'];
};

export type CreateUserMutationInput = {
  data: Array<UserInput>;
};

export type CreateUserMutationReturn = {
  __typename?: 'CreateUserMutationReturn';
  usersAddedSuccessfully: Scalars['Boolean'];
};

export type CustomContactInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  jobTitle: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomContactReturn = {
  __typename?: 'CustomContactReturn';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  jobTitle: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum DayEnum {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type DeactivateMemberProfileAndPerksBannersForChamberMemberReturn = {
  __typename?: 'DeactivateMemberProfileAndPerksBannersForChamberMemberReturn';
  deactivationSuccessful: Scalars['Boolean'];
};

export type DeleteFavoritedChamberMemberMutationReturn = {
  __typename?: 'DeleteFavoritedChamberMemberMutationReturn';
  favoritedChamberMemberDeletedSuccessfully: Scalars['Boolean'];
};

export type DeleteFavoritedDiscountMutationReturn = {
  __typename?: 'DeleteFavoritedDiscountMutationReturn';
  favoritedDiscountDeletedSuccessfully: Scalars['Boolean'];
};

export type DeletePerksLinkRequestReturn = {
  __typename?: 'DeletePerksLinkRequestReturn';
  perksLinkDeletedSuccessfully: Scalars['Boolean'];
};

export type DeleteReturn = {
  __typename?: 'DeleteReturn';
  id: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type DeleteUserMutationReturn = {
  __typename?: 'DeleteUserMutationReturn';
  id: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type DeletedAndNonDeletedChamberMemberStatsReturn = {
  __typename?: 'DeletedAndNonDeletedChamberMemberStatsReturn';
  name: Scalars['String'];
  chamberName: Scalars['String'];
  membershipStatus: Scalars['String'];
  category1: Scalars['String'];
  category2: Scalars['String'];
  numberOfAppUsersWithThisMemberSetAsTheirEmployer: Scalars['Float'];
  numberOfDeletedAndNonDeletedDiscounts: Scalars['Float'];
  hasLoggedInToAdminPortal: Scalars['String'];
  hasLogo: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
  createdAtUtcTimestamp: Scalars['String'];
};

export type DeletedAndNonDeletedDiscountStatsReturn = {
  __typename?: 'DeletedAndNonDeletedDiscountStatsReturn';
  title: Scalars['String'];
  chamberName: Scalars['String'];
  chamberMemberName: Scalars['String'];
  offeredTo: Scalars['String'];
  views: Scalars['Float'];
  numberOfRedemptions: Scalars['Float'];
  category1: Scalars['String'];
  category2: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
  createdAtUtcTimestamp: Scalars['String'];
  deletedAtUtcMilli: Scalars['Float'];
  deletedAtUtcTimestamp: Scalars['String'];
};

export type DeletedDiscountReturn = {
  __typename?: 'DeletedDiscountReturn';
  id: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  chamberId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  views: Scalars['Float'];
  chamberInfo: ChamberReturn;
  chamberMemberInfo: ChamberMemberReturn;
  memberOnly: Scalars['Boolean'];
  isAvailableToAllMembers?: Maybe<Scalars['Boolean']>;
  isReoccuring: Scalars['Boolean'];
  daysAvailable?: Maybe<Array<DayEnum>>;
  monthsAvailable?: Maybe<Array<MonthEnum>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  privateEmails?: Maybe<Array<Scalars['String']>>;
  redeemSettings: RedeemSettingsReturn;
  numberOfRedemptions: Scalars['Float'];
  numberOfFavorites: Scalars['Float'];
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  deletedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export type DeletedPerkPartnerDiscountReturn = {
  __typename?: 'DeletedPerkPartnerDiscountReturn';
  id: Scalars['String'];
  companyName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  verifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  unverifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  logo: Scalars['String'];
  availableToChamberIds?: Maybe<Array<Scalars['String']>>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  deletedAtUtcMilli: Scalars['Float'];
  banner?: Maybe<Array<BannerReturn>>;
};

export type DeletedPerksLinkRequestReturn = {
  __typename?: 'DeletedPerksLinkRequestReturn';
  status: PerksLinkRequestStatusEnum;
  requestorChamberId: Scalars['String'];
  requesteeChamberId: Scalars['String'];
  linkedAtTimeUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  deletedAtUtcMilli: Scalars['Float'];
  deletionReason: PerksLinkDeletionReasonEnum;
};

export type DiscountFilterEventInput = {
  chamberId: Scalars['String'];
  chamberMemberId?: Maybe<Scalars['String']>;
  sort: Scalars['String'];
  stringifiedCategories: Scalars['String'];
};

export type DiscountInUserPolygonBoundaryCoordsReturn = {
  __typename?: 'DiscountInUserPolygonBoundaryCoordsReturn';
  id: Scalars['String'];
  chamberMemberLocationCoords: CoordinateReturn;
};

export type DiscountInput = {
  id: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  chamberId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  memberOnly: Scalars['Boolean'];
  isAvailableToAllMembers?: Maybe<Scalars['Boolean']>;
  isReoccuring: Scalars['Boolean'];
  daysAvailable?: Maybe<Array<DayEnum>>;
  monthsAvailable?: Maybe<Array<MonthEnum>>;
  isPrivate: Scalars['Boolean'];
  privateEmails?: Maybe<Array<Scalars['String']>>;
  redeemSettings: RedeemSettingsInput;
  notify?: Maybe<Scalars['Boolean']>;
  startTimeUtcMilli?: Maybe<Scalars['Float']>;
  expiryTimeUtcMilli?: Maybe<Scalars['Float']>;
  banner?: Maybe<Array<BannerInput>>;
};

export enum DiscountNotificationTypes {
  NewDiscount = 'NEW_DISCOUNT',
  NearbyDiscount = 'NEARBY_DISCOUNT',
  NearbyDiscountOpenProfile = 'NEARBY_DISCOUNT_OPEN_PROFILE',
  RewardCampaign = 'REWARD_CAMPAIGN',
  ShopLocalCampaign = 'SHOP_LOCAL_CAMPAIGN'
}

export type DiscountReturn = {
  __typename?: 'DiscountReturn';
  id: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  chamberId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  views: Scalars['Float'];
  chamberInfo: ChamberReturn;
  chamberMemberInfo: ChamberMemberReturn;
  memberOnly: Scalars['Boolean'];
  isAvailableToAllMembers?: Maybe<Scalars['Boolean']>;
  isReoccuring: Scalars['Boolean'];
  daysAvailable?: Maybe<Array<DayEnum>>;
  monthsAvailable?: Maybe<Array<MonthEnum>>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  privateEmails?: Maybe<Array<Scalars['String']>>;
  redeemSettings: RedeemSettingsReturn;
  numberOfRedemptions: Scalars['Float'];
  numberOfFavorites: Scalars['Float'];
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  currentLoggedInCapUserHasRedeemed?: Maybe<Scalars['Boolean']>;
  currentLoggedInCapUserHasFavorited?: Maybe<Scalars['Boolean']>;
  currentLoggedInCapUserIsInPrizeDrawOfActiveShopLocalCampaignUnderDiscountsMember?: Maybe<Scalars['Boolean']>;
  activeShopLocalCampaignInfo?: Maybe<ShopLocalCampaignReturn>;
  chamberMemberLocationCoords?: Maybe<CoordinateReturn>;
  openStreetMapsGeneratedProvince?: Maybe<Scalars['String']>;
  chamberMemberIsSuspended?: Maybe<Scalars['Boolean']>;
  startTimeUtcMilli?: Maybe<Scalars['Float']>;
  expiryTimeUtcMilli?: Maybe<Scalars['Float']>;
  banner?: Maybe<Array<BannerReturn>>;
  showRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
};

export type DiscountsPerCategoryBreakdownReturn = {
  __typename?: 'DiscountsPerCategoryBreakdownReturn';
  categoryName: Scalars['String'];
  numberOfDiscounts: Scalars['Float'];
  percentageVersusOtherCategories: Scalars['Float'];
};

export type EmailUserVerificationCodeMutationReturn = {
  __typename?: 'EmailUserVerificationCodeMutationReturn';
  emailUserVerificationCodeSentSuccessfully: Scalars['Boolean'];
};

export type EndRewardCampaignMutationReturn = {
  __typename?: 'EndRewardCampaignMutationReturn';
  campaignEndedSuccessfully: Scalars['Boolean'];
};

export type EndShopLocalCampaignReturn = {
  __typename?: 'EndShopLocalCampaignReturn';
  shopLocalCampaignEndedSuccessfully: Scalars['Boolean'];
};

export enum ErrorCodeEnum {
  DisallowAccessTokenWithUserAuthYoungerThanUtcMilli = 'DISALLOW_ACCESS_TOKEN_WITH_USER_AUTH_YOUNGER_THAN_UTC_MILLI',
  DisallowAccessTokenPermanently = 'DISALLOW_ACCESS_TOKEN_PERMANENTLY',
  DisallowUserTypeAccess = 'DISALLOW_USER_TYPE_ACCESS'
}

export enum EventTypeEnum {
  NavbarButtonTap = 'NAVBAR_BUTTON_TAP',
  PerkPartner = 'PERK_PARTNER',
  ChangeChamber = 'CHANGE_CHAMBER',
  DiscountFilter = 'DISCOUNT_FILTER'
}

export type ExcludeChamberEngageActivationFromInvoiceReturn = {
  __typename?: 'ExcludeChamberEngageActivationFromInvoiceReturn';
  excluded: Scalars['Boolean'];
};

export type FavoritedChamberMemberReturn = {
  __typename?: 'FavoritedChamberMemberReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
};

export type FavoritedDiscountReturn = {
  __typename?: 'FavoritedDiscountReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  discountId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
};

export type GetAllMemberLoginCredentialsReturn = {
  __typename?: 'GetAllMemberLoginCredentialsReturn';
  status: GetAllMemberLoginCredentialsStatusEnum;
  successCSVRawTextOfAllMemberLoginCredentials?: Maybe<Scalars['String']>;
};

export enum GetAllMemberLoginCredentialsStatusEnum {
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export type GetSampleMemberLoginCredentialsReturn = {
  __typename?: 'GetSampleMemberLoginCredentialsReturn';
  sampleCSVRawTextOfAllMemberLoginCredentials: Scalars['String'];
};

export type HowToRedeemInput = {
  code?: Maybe<Scalars['String']>;
  websiteToEnterCodeAt?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
};

export type HowToRedeemReturn = {
  __typename?: 'HowToRedeemReturn';
  code?: Maybe<Scalars['String']>;
  websiteToEnterCodeAt?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
};

export enum InAppShareTypes {
  Discount = 'DISCOUNT',
  ChamberMemberProfile = 'CHAMBER_MEMBER_PROFILE'
}

export type IncludeChamberEngageActivationInInvoiceReturn = {
  __typename?: 'IncludeChamberEngageActivationInInvoiceReturn';
  included: Scalars['Boolean'];
};

export type IncrementChamberMemberViewCountReturn = {
  __typename?: 'IncrementChamberMemberViewCountReturn';
  incrementSuccessful: Scalars['Boolean'];
};

export type IndividualRewardCampaignChallengeReturn = {
  __typename?: 'IndividualRewardCampaignChallengeReturn';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  challengeType: RewardCampaignChallengeTypeEnum;
  ballotsNeededToComplete: Scalars['Float'];
  ballotsEarnedForCapUser: Scalars['Float'];
  showRedBubbleNotificationForNewShareForCurrentlyLoggedInCapUser: Scalars['Boolean'];
};

export type IneligiblePerkPartnerDiscountReturn = {
  __typename?: 'IneligiblePerkPartnerDiscountReturn';
  id: Scalars['String'];
  companyName: Scalars['String'];
  title: Scalars['String'];
  logo: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum ItemsPurchasableByChamberEnum {
  ChamberPerksApp = 'CHAMBER_PERKS_APP',
  ChamberMemberPro = 'CHAMBER_MEMBER_PRO',
  ChamberMemberProWebsiteBuilderOnly = 'CHAMBER_MEMBER_PRO_WEBSITE_BUILDER_ONLY'
}

export enum LanguageCodeEnum {
  En = 'EN',
  Fr = 'FR'
}

export enum LoginInfoEmailTypes {
  ResetPassword = 'RESET_PASSWORD',
  BrandNew = 'BRAND_NEW',
  ChangeEmailUsername = 'CHANGE_EMAIL_USERNAME'
}

export type MarkChamberEngageActivationInvoicedReturn = {
  __typename?: 'MarkChamberEngageActivationInvoicedReturn';
  marked: Scalars['Boolean'];
};

export enum MemberProfileAndPerksBannersUpdateRecordEventEnum {
  Activation = 'ACTIVATION',
  Reactivation = 'REACTIVATION',
  Deactivation = 'DEACTIVATION',
  Expiration = 'EXPIRATION',
  ChamberMemberAgreementAcceptance = 'CHAMBER_MEMBER_AGREEMENT_ACCEPTANCE',
  ActivationFeeUpdateDoneByCpa = 'ACTIVATION_FEE_UPDATE_DONE_BY_CPA'
}

export type MemberProfileAndPerksBannersUpdateRecordReturn = {
  __typename?: 'MemberProfileAndPerksBannersUpdateRecordReturn';
  id: Scalars['String'];
  triggeringUserId: Scalars['String'];
  chamberId: Scalars['String'];
  chamberMemberId: Scalars['String'];
  recordEvent: MemberProfileAndPerksBannersUpdateRecordEventEnum;
  memberProfileAndPerksBannersSettingsAtEventTrigger: ActiveMemberProfileAndPerksBannersSettingsReturn;
  excludeFromInvoice?: Maybe<Scalars['Boolean']>;
  invoiceSentAtUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  activationDateUtcMilli: Scalars['Float'];
  expirationDateUtcMilli: Scalars['Float'];
};

export enum MonthEnum {
  January = 'JANUARY',
  February = 'FEBRUARY',
  March = 'MARCH',
  April = 'APRIL',
  May = 'MAY',
  June = 'JUNE',
  July = 'JULY',
  August = 'AUGUST',
  September = 'SEPTEMBER',
  October = 'OCTOBER',
  November = 'NOVEMBER',
  December = 'DECEMBER'
}

export type Mutation = {
  __typename?: 'Mutation';
  createChambers: CreateChamberMutationReturn;
  createContactableChamber: ContactableChamberReturn;
  updateChamber: ChamberReturn;
  deleteChamber: DeleteReturn;
  chamberChangeAdminConsoleLoginEmail: ChangeAdminConsoleLoginEmailReturn;
  createChamberMembers: CreateChamberMembersMutationReturn;
  createChamberContactableCompany: ContactableCompanyReturn;
  deleteChamberMember: DeleteReturn;
  updateChamberMember: ChamberMemberReturn;
  setNewTemporaryChamberMemberPassword: NewTemporaryChamberMemberPasswordReturn;
  sendLoginInfoEmailToChamberMember: SendLoginInfoEmailToChamberMemberReturn;
  chamberMemberChangeAdminConsoleLoginEmail: ChangeAdminConsoleLoginEmailReturn;
  setHasSignedUpForChamberMember: SetHasSignedUpForChamberMemberReturn;
  createAllMemberLoginCredentials: CreateAllMemberLoginCredentialsReturn;
  incrementChamberMemberViewCount: IncrementChamberMemberViewCountReturn;
  createUsers: CreateUserMutationReturn;
  updateUser: UpdateUserMutationReturn;
  updateLoggedInUserLanguage: UpdateLoggedInUserLanguageMutationReturn;
  deleteUser: DeleteUserMutationReturn;
  emailUserVerificationCode: EmailUserVerificationCodeMutationReturn;
  verifyUserVerificationCode: VerifyUserVerificationCodeMutationReturn;
  createCapUserShareLeadingToOpenRecord: CreateCapUserShareLeadingToOpenRecordMutationReturn;
  upsertCapUserShareRecord: UpsertCapUserShareRecordMutationReturn;
  createDiscounts: CreateDiscountsMutationReturn;
  createRedeemedDiscounts: CreateRedeemedDiscountsMutationReturn;
  createRedeemedDiscount: CreateRedeemedDiscountMutationReturn;
  createFavoritedDiscount: CreateFavoritedDiscountMutationReturn;
  deleteFavoritedDiscount: DeleteFavoritedDiscountMutationReturn;
  createFavoritedChamberMember: CreateFavoritedChamberMemberMutationReturn;
  deleteFavoritedChamberMember: DeleteFavoritedChamberMemberMutationReturn;
  updateDiscount: UpdateDiscountReturn;
  deleteDiscount: DeleteReturn;
  incrementDiscountViewCount: UpdateDiscountReturn;
  createPerkPartnerDiscount: CreatePerkPartnerDiscountMutationReturn;
  updatePerkPartnerDiscount: UpdatePerkPartnerDiscountReturn;
  deletePerkPartnerDiscount: DeleteReturn;
  createPerkPartnerDiscountAdminUser: CreatePerkPartnerDiscountAdminUserMutationReturn;
  deletePerkPartnerDiscountAdminUser: DeleteReturn;
  createShopLocalCampaign: CreateShopLocalCampaignReturn;
  updateShopLocalCampaign: UpdateShopLocalCampaignReturn;
  endShopLocalCampaign: EndShopLocalCampaignReturn;
  createShopLocalCampaignPrizeDrawParticipant: CreateShopLocalCampaignPrizeDrawParticipantReturn;
  requestPerksLink: RequestPerksLinkReturn;
  acceptPerksLinkRequest: AcceptPerksLinkRequestReturn;
  deletePerksLinkRequest: DeletePerksLinkRequestReturn;
  updateChamberEngageSettingsForChamber: UpdateChamberEngageSettingsForChamberReturn;
  excludeChamberEngageActivationFromInvoice: ExcludeChamberEngageActivationFromInvoiceReturn;
  includeChamberEngageActivationInInvoice: IncludeChamberEngageActivationInInvoiceReturn;
  markChamberEngageActivationInvoiced: MarkChamberEngageActivationInvoicedReturn;
  updateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivation: UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationReturn;
  upsertRedBubbleNotificationView: UpsertRedBubbleNotificationViewReturn;
  activateBannerForCategoryAdvertisingBanner: ActivateBannerForCategoryAdvertisingBannerReturn;
  reactivateBannerForCategoryAdvertisingBanner: ActivateBannerForCategoryAdvertisingBannerReturn;
  updateBannerContentForCategoryAdvertisingBanner: ActivateBannerForCategoryAdvertisingBannerReturn;
  acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember: AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberReturn;
  activateMemberProfileAndPerksBannersForChamberMember: ActivateMemberProfileAndPerksBannersForChamberMemberReturn;
  reactivateMemberProfileAndPerksBannersForChamberMember: ActivateMemberProfileAndPerksBannersForChamberMemberReturn;
  deactivateMemberProfileAndPerksBannersForChamberMember: DeactivateMemberProfileAndPerksBannersForChamberMemberReturn;
  acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember: AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberReturn;
  createRewardCampaign: CreateRewardCampaignMutationReturn;
  updateRewardCampaign: UpdateRewardCampaignMutationReturn;
  endRewardCampaign: EndRewardCampaignMutationReturn;
  capUserAgreedToRewardCampaign?: Maybe<CapUserAgreedToRewardCampaignMutationReturn>;
  capUserViewedRewardCampaign?: Maybe<CapUserViewedRewardCampaignMutationReturn>;
  changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner: ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerReturn;
  getSupportCenterUrl: Scalars['String'];
};


export type MutationCreateChambersArgs = {
  input: CreateChamberMutationInput;
};


export type MutationCreateContactableChamberArgs = {
  input: CreateContactableChamberMutationInput;
};


export type MutationUpdateChamberArgs = {
  input: ChamberInput;
  id: Scalars['String'];
};


export type MutationDeleteChamberArgs = {
  id: Scalars['String'];
};


export type MutationChamberChangeAdminConsoleLoginEmailArgs = {
  currentAdminConsoleLoginEmail: Scalars['String'];
  newAdminConsoleLoginEmail: Scalars['String'];
  chamberId: Scalars['String'];
};


export type MutationCreateChamberMembersArgs = {
  input: CreateChamberMembersMutationInput;
};


export type MutationCreateChamberContactableCompanyArgs = {
  input: CreateChamberContactableCompanyMutationInput;
};


export type MutationDeleteChamberMemberArgs = {
  id: Scalars['String'];
};


export type MutationUpdateChamberMemberArgs = {
  input: ChamberMemberInput;
  id: Scalars['String'];
};


export type MutationSetNewTemporaryChamberMemberPasswordArgs = {
  adminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
};


export type MutationSendLoginInfoEmailToChamberMemberArgs = {
  adminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
  type: LoginInfoEmailTypes;
};


export type MutationChamberMemberChangeAdminConsoleLoginEmailArgs = {
  currentAdminConsoleLoginEmail: Scalars['String'];
  newAdminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
};


export type MutationIncrementChamberMemberViewCountArgs = {
  id: Scalars['String'];
};


export type MutationCreateUsersArgs = {
  input: CreateUserMutationInput;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
  id: Scalars['String'];
};


export type MutationUpdateLoggedInUserLanguageArgs = {
  languageCode: LanguageCodeEnum;
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationVerifyUserVerificationCodeArgs = {
  code: Scalars['String'];
};


export type MutationCreateCapUserShareLeadingToOpenRecordArgs = {
  input: CapUserShareLeadingToOpenRecordInput;
};


export type MutationUpsertCapUserShareRecordArgs = {
  sharedItemId: Scalars['String'];
  shareType: InAppShareTypes;
};


export type MutationCreateDiscountsArgs = {
  input: CreateDiscountsMutationInput;
};


export type MutationCreateRedeemedDiscountsArgs = {
  input: CreateRedeemedDiscountsMutationInput;
};


export type MutationCreateRedeemedDiscountArgs = {
  discountId: Scalars['String'];
};


export type MutationCreateFavoritedDiscountArgs = {
  discountId: Scalars['String'];
};


export type MutationDeleteFavoritedDiscountArgs = {
  discountId: Scalars['String'];
};


export type MutationCreateFavoritedChamberMemberArgs = {
  chamberMemberId: Scalars['String'];
};


export type MutationDeleteFavoritedChamberMemberArgs = {
  chamberMemberId: Scalars['String'];
};


export type MutationUpdateDiscountArgs = {
  input: DiscountInput;
  id: Scalars['String'];
};


export type MutationDeleteDiscountArgs = {
  id: Scalars['String'];
};


export type MutationIncrementDiscountViewCountArgs = {
  id: Scalars['String'];
};


export type MutationCreatePerkPartnerDiscountArgs = {
  input: PerkPartnerDiscountInput;
};


export type MutationUpdatePerkPartnerDiscountArgs = {
  input: PerkPartnerDiscountInput;
  id: Scalars['String'];
};


export type MutationDeletePerkPartnerDiscountArgs = {
  id: Scalars['String'];
};


export type MutationCreatePerkPartnerDiscountAdminUserArgs = {
  input: PerkPartnerDiscountAdminUserInput;
};


export type MutationDeletePerkPartnerDiscountAdminUserArgs = {
  id: Scalars['String'];
};


export type MutationCreateShopLocalCampaignArgs = {
  input: ShopLocalCampaignInput;
};


export type MutationUpdateShopLocalCampaignArgs = {
  id: Scalars['String'];
  input: ShopLocalCampaignInput;
};


export type MutationEndShopLocalCampaignArgs = {
  id: Scalars['String'];
};


export type MutationCreateShopLocalCampaignPrizeDrawParticipantArgs = {
  shopLocalCampaignId: Scalars['String'];
  participatingForMemberId: Scalars['String'];
  participatingForDiscountId: Scalars['String'];
  participationCode: Scalars['String'];
};


export type MutationRequestPerksLinkArgs = {
  requestingLinkToChamberId: Scalars['String'];
};


export type MutationAcceptPerksLinkRequestArgs = {
  requestId: Scalars['String'];
};


export type MutationDeletePerksLinkRequestArgs = {
  requestId: Scalars['String'];
  deletionReason: PerksLinkDeletionReasonEnum;
};


export type MutationUpdateChamberEngageSettingsForChamberArgs = {
  chamberId: Scalars['String'];
  chamberEngageSettings: ChamberEngageSettingsInput;
};


export type MutationExcludeChamberEngageActivationFromInvoiceArgs = {
  activationId: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
};


export type MutationIncludeChamberEngageActivationInInvoiceArgs = {
  activationId: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
};


export type MutationMarkChamberEngageActivationInvoicedArgs = {
  activationsToMarkInvoiced: Array<ChamberEngageActivationToMarkInvoicedInput>;
};


export type MutationUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationArgs = {
  activationId: Scalars['String'];
  newActivationFeeInDollars: Scalars['Float'];
};


export type MutationUpsertRedBubbleNotificationViewArgs = {
  viewType: RedBubbleNotificationViewTypeEnum;
  viewTypeItemId: Scalars['String'];
};


export type MutationActivateBannerForCategoryAdvertisingBannerArgs = {
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  activationInfo: CategoryAdvertisingBannerActivationInfoInput;
};


export type MutationReactivateBannerForCategoryAdvertisingBannerArgs = {
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  reactivationInfo: CategoryAdvertisingBannerReactivationInfoInput;
};


export type MutationUpdateBannerContentForCategoryAdvertisingBannerArgs = {
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  banner?: Maybe<Array<BannerInput>>;
};


export type MutationAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberArgs = {
  categoryId: ChamberMemberCategory;
};


export type MutationActivateMemberProfileAndPerksBannersForChamberMemberArgs = {
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
};


export type MutationReactivateMemberProfileAndPerksBannersForChamberMemberArgs = {
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
};


export type MutationDeactivateMemberProfileAndPerksBannersForChamberMemberArgs = {
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
};


export type MutationCreateRewardCampaignArgs = {
  input: RewardCampaignInput;
};


export type MutationUpdateRewardCampaignArgs = {
  id: Scalars['String'];
  input: RewardCampaignInput;
};


export type MutationEndRewardCampaignArgs = {
  id: Scalars['String'];
};


export type MutationCapUserAgreedToRewardCampaignArgs = {
  rewardCampaignId: Scalars['String'];
};


export type MutationCapUserViewedRewardCampaignArgs = {
  rewardCampaignId: Scalars['String'];
};


export type MutationChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerArgs = {
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  autoApproveBannerContentUpdate: Scalars['Boolean'];
};


export type MutationGetSupportCenterUrlArgs = {
  nonce: Scalars['String'];
  state: Scalars['String'];
};

export enum NavBarButtonNameEnum {
  Directory = 'DIRECTORY',
  Perks = 'PERKS',
  Events = 'EVENTS',
  JobPosts = 'JOB_POSTS',
  PerkPartners = 'PERK_PARTNERS',
  Newsletter = 'NEWSLETTER',
  Survey = 'SURVEY',
  MyChamber = 'MY_CHAMBER',
  Rewards = 'REWARDS',
  BecomeAMember = 'BECOME_A_MEMBER',
  AdvocacyWins = 'ADVOCACY_WINS'
}

export type NavBarButtonTapEventInput = {
  buttonName: NavBarButtonNameEnum;
  chamberId: Scalars['String'];
  chamberMemberId?: Maybe<Scalars['String']>;
};

export type NewTemporaryChamberMemberPasswordReturn = {
  __typename?: 'NewTemporaryChamberMemberPasswordReturn';
  password: Scalars['String'];
  failureReason?: Maybe<Scalars['String']>;
};

export type PerkPartnerDiscountAdminUserInput = {
  adminConsoleLoginEmail: Scalars['String'];
  perkPartnerDiscountId: Scalars['String'];
};

export type PerkPartnerDiscountAdminUserReturn = {
  __typename?: 'PerkPartnerDiscountAdminUserReturn';
  id: Scalars['String'];
  email: Scalars['String'];
  perkPartnerDiscountId: Scalars['String'];
  adminConsoleLoginEmail: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export type PerkPartnerDiscountInput = {
  id: Scalars['String'];
  companyName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  verifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  unverifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  logo: Scalars['String'];
  availableToChamberIds?: Maybe<Array<Scalars['String']>>;
  banner?: Maybe<Array<BannerInput>>;
};

export type PerkPartnerDiscountReturn = {
  __typename?: 'PerkPartnerDiscountReturn';
  id: Scalars['String'];
  companyName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  verifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  unverifiedChamberMemberCapUserRedirectLink: Scalars['String'];
  logo: Scalars['String'];
  availableToChamberIds?: Maybe<Array<Scalars['String']>>;
  availableToChambers?: Maybe<Array<RootAdminPerkPartnerDiscountAvailableToChambersReturn>>;
  showRedBubbleNotificationForLoggedInCapUser?: Maybe<Scalars['Boolean']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
  banner?: Maybe<Array<BannerReturn>>;
};

export type PerkPartnerDiscountStatReturn = {
  __typename?: 'PerkPartnerDiscountStatReturn';
  id: Scalars['String'];
  totalViewCount: Scalars['Float'];
  totalIneligibleViewCount: Scalars['Float'];
  totalUnverifiedViewCount: Scalars['Float'];
  totalVerifiedViewCount: Scalars['Float'];
  totalOpenCount: Scalars['Float'];
  totalIneligibleOpenCount: Scalars['Float'];
  totalUnverifiedOpenCount: Scalars['Float'];
  totalVerifiedOpenCount: Scalars['Float'];
  totalOpensFromViewsConversionRatePercentage: Scalars['Float'];
  totalRedeemCount: Scalars['Float'];
  totalUnverifiedRedeemCount: Scalars['Float'];
  totalVerifiedRedeemCount: Scalars['Float'];
  totalVerifiedNoAccessToEmailRedeemCount: Scalars['Float'];
  totalRedeemCountFromOpensConversionRatePercentage: Scalars['Float'];
  splitByChamber?: Maybe<Array<PerkPartnerDiscountStatSplitByChamberReturn>>;
  timeseriesData?: Maybe<PerkPartnerDiscountTimeseriesDataReturn>;
};

export type PerkPartnerDiscountStatSplitByChamberReturn = {
  __typename?: 'PerkPartnerDiscountStatSplitByChamberReturn';
  chamberId: Scalars['String'];
  chamberName: Scalars['String'];
  chamberIsEnabledForPerkPartnerDiscount: Scalars['Boolean'];
  totalViewCount: Scalars['Float'];
  ineligibleViewCount: Scalars['Float'];
  unverifiedViewCount: Scalars['Float'];
  verifiedViewCount: Scalars['Float'];
  totalOpenCount: Scalars['Float'];
  ineligibleOpenCount: Scalars['Float'];
  unverifiedOpenCount: Scalars['Float'];
  verifiedOpenCount: Scalars['Float'];
  totalRedeemCount: Scalars['Float'];
  unverifiedRedeemCount: Scalars['Float'];
  verifiedRedeemCount: Scalars['Float'];
  verifiedNoAccessToEmailRedeemCount: Scalars['Float'];
};

export type PerkPartnerDiscountTimeseriesDataPoint = {
  __typename?: 'PerkPartnerDiscountTimeseriesDataPoint';
  monthAndYear: Scalars['String'];
  totalOpenCount: Scalars['Float'];
  totalViewCount: Scalars['Float'];
  totalRedeemCount: Scalars['Float'];
};

export type PerkPartnerDiscountTimeseriesDataReturn = {
  __typename?: 'PerkPartnerDiscountTimeseriesDataReturn';
  allChambers: PerkPartnerDiscountTimeseriesDataset;
  chambers: Array<PerkPartnerDiscountTimeseriesDataset>;
};

export type PerkPartnerDiscountTimeseriesDataset = {
  __typename?: 'PerkPartnerDiscountTimeseriesDataset';
  id: Scalars['String'];
  name: Scalars['String'];
  timeseriesData: Array<PerkPartnerDiscountTimeseriesDataPoint>;
};

export type PerkPartnerDiscountUpdateRecordReturn = {
  __typename?: 'PerkPartnerDiscountUpdateRecordReturn';
  id: Scalars['String'];
  perkPartnerDiscountId: Scalars['String'];
  updatingUsersId: Scalars['String'];
  updatingUsersAdminConsoleLoginEmail: Scalars['String'];
  prevVersion: PerkPartnerDiscountReturn;
  newVersion: PerkPartnerDiscountReturn;
  createdAtUtcMilli: Scalars['Float'];
};

export enum PerkPartnerEventActionEnum {
  IneligibleView = 'INELIGIBLE_VIEW',
  UnverifiedView = 'UNVERIFIED_VIEW',
  VerifiedView = 'VERIFIED_VIEW',
  IneligibleOpen = 'INELIGIBLE_OPEN',
  UnverifiedOpen = 'UNVERIFIED_OPEN',
  VerifiedOpen = 'VERIFIED_OPEN',
  UnverifiedRedeem = 'UNVERIFIED_REDEEM',
  VerifiedRedeem = 'VERIFIED_REDEEM',
  VerifiedNoAccessToEmailRedeem = 'VERIFIED_NO_ACCESS_TO_EMAIL_REDEEM'
}

export type PerkPartnerEventInput = {
  perkPartnerId: Scalars['String'];
  chamberId: Scalars['String'];
  chamberMemberId?: Maybe<Scalars['String']>;
  action: PerkPartnerEventActionEnum;
  redeemUserId?: Maybe<Scalars['String']>;
};

export enum PerksLinkDeletionReasonEnum {
  Denied = 'DENIED',
  Cancelled = 'CANCELLED',
  Unlinked = 'UNLINKED'
}

export type PerksLinkRequestReturn = {
  __typename?: 'PerksLinkRequestReturn';
  id: Scalars['String'];
  status: PerksLinkRequestStatusEnum;
  requestorChamberId: Scalars['String'];
  requesteeChamberId: Scalars['String'];
  linkedAtTimeUtcMilli?: Maybe<Scalars['Float']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum PerksLinkRequestStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export type PerksLinkReturn = {
  __typename?: 'PerksLinkReturn';
  pendingOrAcceptedPerksLinkRequestInfo?: Maybe<PerksLinkRequestReturn>;
  chamberId: Scalars['String'];
  chamberName: Scalars['String'];
  chamberContactEmail: Scalars['String'];
  chamberContactNumber: Scalars['String'];
};

export type PrizeDrawParticipantReturn = {
  __typename?: 'PrizeDrawParticipantReturn';
  email: Scalars['String'];
  ballotsEarned: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getAllChambers?: Maybe<Array<ChamberReturn>>;
  getChamber: ChamberReturn;
  getAllContactableCompaniesForChamber?: Maybe<Array<ContactableCompanyReturn>>;
  getAllChamberMembers?: Maybe<Array<ChamberMemberReturn>>;
  getChamberMember: ChamberMemberReturn;
  getSampleLoginInfoEmail: SampleLoginInfoEmailReturn;
  getAllCategories?: Maybe<Array<CategoryReturn>>;
  getDiscount: DiscountReturn;
  getAllDiscountsFromChamberMemberId?: Maybe<Array<DiscountReturn>>;
  getAllDiscountsFromChamberId?: Maybe<Array<DiscountReturn>>;
  getAllDeletedDiscountsFromChamberId?: Maybe<Array<DeletedDiscountReturn>>;
  getAllDiscountsInUserPolygonBoundaryCoords?: Maybe<Array<DiscountInUserPolygonBoundaryCoordsReturn>>;
  getUserIsEligibleBoolAndSendNotificationForEachEligibleNearbyDiscount?: Maybe<Array<UserIsEligibleBoolAndSendNotificationForEachEligibleNearbyDiscountReturn>>;
  getSystemWidePerkStats: SystemWidePerkStatsReturn;
  getAllMemberLoginCredentials: GetAllMemberLoginCredentialsReturn;
  getSampleMemberLoginCredentials: GetSampleMemberLoginCredentialsReturn;
  getAllPerkPartnerDiscounts?: Maybe<Array<PerkPartnerDiscountReturn>>;
  getPerkPartnerDiscount: PerkPartnerDiscountReturn;
  getAllDeletedPerkPartnerDiscounts?: Maybe<Array<DeletedPerkPartnerDiscountReturn>>;
  getAllPerkPartnerDiscountsForCapUser?: Maybe<CapUserPerkPartnerDiscountReturn>;
  getAllAdminUsersForPerkPartnerDiscount?: Maybe<Array<PerkPartnerDiscountAdminUserReturn>>;
  getPerkPartnerDiscountAdminUser: PerkPartnerDiscountAdminUserReturn;
  getPerkPartnerDiscountStats: PerkPartnerDiscountStatReturn;
  getUser: UserReturn;
  getUnverifiedCpaUserInfo: UnverifiedCpaUserInfoReturn;
  getAdminConsoleLoginEmailForChamber: AdminConsoleLoginEmailReturn;
  getAdminConsoleLoginEmailForChamberMember: AdminConsoleLoginEmailReturn;
  getCPASignUpChambersForCity: CpaSignUpChambersForCityReturn;
  getShopLocalCampaign?: Maybe<ShopLocalCampaignReturn>;
  getAllShopLocalCampaignsForChamber?: Maybe<Array<ShopLocalCampaignReturn>>;
  getActiveShopLocalCampaignForChamber?: Maybe<ShopLocalCampaignReturn>;
  getAllPerksLinksForChamber: Array<PerksLinkReturn>;
  getAllCategoryAdvertisingBannersForChamber: AllCategoryAdvertisingBannersForChamberReturn;
  getActiveCategoryAdvertisingBannersForChamberMember?: Maybe<Array<CategoryAdvertisingBannerReturn>>;
  getActiveCategoryAdvertisingBannersForCapUser?: Maybe<Array<CategoryAdvertisingBannerReturn>>;
  getRewardCampaign?: Maybe<RewardCampaignReturn>;
  getAllRewardCampaignsForChamber?: Maybe<Array<RewardCampaignReturn>>;
  getActiveRewardCampaignForCapUser?: Maybe<RewardCampaignReturn>;
  getAllChamberEngageInvoiceData?: Maybe<Array<ChamberEngageMonthlyInvoiceDataReturn>>;
  getRootAdminStats: RootAdminStatsReturn;
};


export type QueryGetAllChambersArgs = {
  getAllSandbox?: Maybe<Scalars['Boolean']>;
};


export type QueryGetChamberArgs = {
  chamberId: Scalars['String'];
};


export type QueryGetAllChamberMembersArgs = {
  chamberId: Scalars['String'];
  getHasSignedUp?: Maybe<Scalars['Boolean']>;
  getNumberOfUsers?: Maybe<Scalars['Boolean']>;
};


export type QueryGetChamberMemberArgs = {
  chamberMemberId: Scalars['String'];
};


export type QueryGetSampleLoginInfoEmailArgs = {
  type: LoginInfoEmailTypes;
  chamberMemberId?: Maybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  discountId: Scalars['String'];
};


export type QueryGetAllDiscountsFromChamberMemberIdArgs = {
  chamberMemberId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
};


export type QueryGetAllDiscountsFromChamberIdArgs = {
  chamberId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
};


export type QueryGetAllDeletedDiscountsFromChamberIdArgs = {
  chamberId: Scalars['String'];
};


export type QueryGetAllDiscountsInUserPolygonBoundaryCoordsArgs = {
  openStreetMapsGeneratedProvinceForUserCurrentCoords: Scalars['String'];
  userPolygonBoundaryCoords: Array<Maybe<Array<Maybe<Array<Scalars['Float']>>>>>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
};


export type QueryGetUserIsEligibleBoolAndSendNotificationForEachEligibleNearbyDiscountArgs = {
  discountIds: Array<Scalars['String']>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
};


export type QueryGetAllMemberLoginCredentialsArgs = {
  processId: Scalars['String'];
};


export type QueryGetPerkPartnerDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetAllAdminUsersForPerkPartnerDiscountArgs = {
  perkPartnerDiscountId: Scalars['String'];
};


export type QueryGetPerkPartnerDiscountStatsArgs = {
  id: Scalars['String'];
};


export type QueryGetUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetUnverifiedCpaUserInfoArgs = {
  email: Scalars['String'];
};


export type QueryGetAdminConsoleLoginEmailForChamberArgs = {
  chamberId: Scalars['String'];
};


export type QueryGetAdminConsoleLoginEmailForChamberMemberArgs = {
  chamberMemberId: Scalars['String'];
};


export type QueryGetCpaSignUpChambersForCityArgs = {
  googleMapsApi_City_ProvinceShort_CountryShort: Scalars['String'];
  googleMapsApiFullCityAddressSearchText: Scalars['String'];
  googleMapsApiCityPlaceId?: Maybe<Scalars['String']>;
};


export type QueryGetShopLocalCampaignArgs = {
  id: Scalars['String'];
};


export type QueryGetAllCategoryAdvertisingBannersForChamberArgs = {
  chamberId?: Maybe<Scalars['String']>;
};


export type QueryGetRewardCampaignArgs = {
  id: Scalars['String'];
};

export type RedBubbleNotificationViewReturn = {
  __typename?: 'RedBubbleNotificationViewReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  viewType: RedBubbleNotificationViewTypeEnum;
  viewTypeItemId: Scalars['String'];
  viewedAtUtcMilli: Scalars['Float'];
  createdAtUtcMilli: Scalars['Float'];
};

export enum RedBubbleNotificationViewTypeEnum {
  PerkPartnerDiscount = 'PERK_PARTNER_DISCOUNT',
  Discount = 'DISCOUNT',
  ChamberMember = 'CHAMBER_MEMBER',
  JobPosts = 'JOB_POSTS',
  Events = 'EVENTS',
  Newsletter = 'NEWSLETTER',
  Survey = 'SURVEY',
  AdvocacyWins = 'ADVOCACY_WINS'
}

export type RedeemSettingsInput = {
  whereToRedeem: WhereToRedeemEnum;
  howToRedeem?: Maybe<HowToRedeemInput>;
};

export type RedeemSettingsReturn = {
  __typename?: 'RedeemSettingsReturn';
  whereToRedeem: WhereToRedeemEnum;
  howToRedeem?: Maybe<HowToRedeemReturn>;
};

export type RedeemedDiscountInput = {
  id: Scalars['String'];
  userId: Scalars['String'];
  discountId: Scalars['String'];
  redeemUtcMilli: Scalars['Float'];
};

export type RedeemedDiscountReturn = {
  __typename?: 'RedeemedDiscountReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  discountId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
};

export type RequestPerksLinkReturn = {
  __typename?: 'RequestPerksLinkReturn';
  perksLinkRequestedSuccessfully: Scalars['Boolean'];
};

export enum RewardCampaignChallengeTypeEnum {
  ShareDiscountOrChamberMemberLeadingToNewUser = 'SHARE_DISCOUNT_OR_CHAMBER_MEMBER_LEADING_TO_NEW_USER',
  ShareDiscount = 'SHARE_DISCOUNT',
  ShareChamberMember = 'SHARE_CHAMBER_MEMBER'
}

export type RewardCampaignInput = {
  endDateUtcMilli: Scalars['Float'];
  campaignName: Scalars['String'];
  prizeDescription: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
  sponsoringChamberMemberId: Scalars['String'];
};

export type RewardCampaignReturn = {
  __typename?: 'RewardCampaignReturn';
  id: Scalars['String'];
  chamberId: Scalars['String'];
  sponsoringChamberMemberId: Scalars['String'];
  campaignName: Scalars['String'];
  prizeDescription: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
  endDateUtcMilli: Scalars['Float'];
  prizeDrawParticipants?: Maybe<Array<PrizeDrawParticipantReturn>>;
  capUserHasNeverViewedCampaign: Scalars['Boolean'];
  challengeConfig: Array<IndividualRewardCampaignChallengeReturn>;
  numberOfBonusBallotsWhenAllChallengesCompleted: Scalars['Float'];
  currentlyLoggedInCapUserHasAgreedToCampaign: Scalars['Boolean'];
  currentlyLoggedInCapUserHasCompletedAllChallenges: Scalars['Boolean'];
  isCreatedByCpaAdmin?: Maybe<Scalars['Boolean']>;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export type RootAdminPerkPartnerDiscountAvailableToChambersReturn = {
  __typename?: 'RootAdminPerkPartnerDiscountAvailableToChambersReturn';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RootAdminStatsReturn = {
  __typename?: 'RootAdminStatsReturn';
  totalChambers: Scalars['Float'];
  totalChamberMembers: Scalars['Float'];
  totalSuspendedChamberMembers: Scalars['Float'];
  totalDiscountViews: Scalars['Float'];
  totalDiscountRedemptions: Scalars['Float'];
  numberOfChamberMembersOfferingLiveDiscounts: Scalars['Float'];
  percentOfChamberMembersOfferingLiveDiscounts: Scalars['Float'];
  totalNumberOfLiveDiscounts: Scalars['Float'];
  totalNumberOfLiveAllMemberDiscounts: Scalars['Float'];
  totalNumberOfLiveLocalMemberOnlyDiscounts: Scalars['Float'];
  totalNumberOfLiveGeneralPublicDiscounts: Scalars['Float'];
  totalNumberOfLiveCompanyEmployeesOnlyDiscounts: Scalars['Float'];
  totalNumberOfAppUsers: Scalars['Float'];
  totalNumberOfAppUsersSignedUpWithAnEmployer: Scalars['Float'];
  totalNumberOfAppUsersSignedUpWithoutAnEmployer: Scalars['Float'];
  totalNumberOfAppUsersWithNewPerkNotificationEnabled: Scalars['Float'];
  totalPercentageOfAppUsersWithNewPerkNotificationEnabled: Scalars['Float'];
  totalNumberOfAppUsersWithNearbyPerkNotificationEnabled: Scalars['Float'];
  totalPercentageOfAppUsersWithNearbyPerkNotificationEnabled: Scalars['Float'];
  timeseriesAppUsersData: TimeSeriesAppUsersDataReturn;
  chambersSortedByNewest: Array<ChambersStatsReturn>;
  newestChamberMembers: Array<DeletedAndNonDeletedChamberMemberStatsReturn>;
  newestDiscounts: Array<DeletedAndNonDeletedDiscountStatsReturn>;
  discountsPerCategoryBreakdown: Array<DiscountsPerCategoryBreakdownReturn>;
  contactableChambersSortedByNewest: Array<ContactableChamberReturn>;
  contactableCompaniesSortedByNewest: Array<ContactableCompanyReturn>;
};

export type SampleLoginInfoEmailReturn = {
  __typename?: 'SampleLoginInfoEmailReturn';
  emailHtml: Scalars['String'];
};

export type SendLoginInfoEmailToChamberMemberReturn = {
  __typename?: 'SendLoginInfoEmailToChamberMemberReturn';
  loginInfoEmailSentSuccessfully: Scalars['Boolean'];
};

export type SetHasSignedUpForChamberMemberReturn = {
  __typename?: 'SetHasSignedUpForChamberMemberReturn';
  successfullySetHasSignedUp: Scalars['Boolean'];
};

export type ShopLocalCampaignInput = {
  campaignName: Scalars['String'];
  prizeDescription: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
  endDateUtcMilli: Scalars['Float'];
  participatingMembers: Array<ShopLocalCampaignParticipatingMembersInput>;
};

export type ShopLocalCampaignParticipatingMembersInput = {
  id: Scalars['String'];
};

export type ShopLocalCampaignParticipatingMembersReturn = {
  __typename?: 'ShopLocalCampaignParticipatingMembersReturn';
  memberId: Scalars['String'];
  participationCode: Scalars['String'];
};

export type ShopLocalCampaignPrizeDrawPariticpantReturn = {
  __typename?: 'ShopLocalCampaignPrizeDrawPariticpantReturn';
  id: Scalars['String'];
  userId: Scalars['String'];
  userEmail: Scalars['String'];
  shopLocalCampaignId: Scalars['String'];
  participatingForMemberId: Scalars['String'];
  participatingForDiscountId: Scalars['String'];
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export type ShopLocalCampaignPrizeDrawParticipantsReturn = {
  __typename?: 'ShopLocalCampaignPrizeDrawParticipantsReturn';
  userEmail: Scalars['String'];
  redeemedAtMemberIds: Array<Scalars['String']>;
};

export type ShopLocalCampaignReturn = {
  __typename?: 'ShopLocalCampaignReturn';
  id: Scalars['String'];
  chamberId: Scalars['String'];
  campaignName: Scalars['String'];
  prizeDescription: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
  endDateUtcMilli: Scalars['Float'];
  participatingMemberIds: Array<Scalars['String']>;
  participatingMembers: Array<ShopLocalCampaignParticipatingMembersReturn>;
  prizeDrawParticipants?: Maybe<Array<ShopLocalCampaignPrizeDrawParticipantsReturn>>;
  status: ShopLocalCampaignStatusEnum;
  createdAtUtcMilli: Scalars['Float'];
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum ShopLocalCampaignStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SignUpMultipleMatchingChambersReturn = {
  __typename?: 'SignUpMultipleMatchingChambersReturn';
  chambersInCity: Array<ChamberReturn>;
  chamberMembersFromAllChambersInCity: Array<ChamberMemberReturn>;
};

export type SignUpNoMatchingChambersReturn = {
  __typename?: 'SignUpNoMatchingChambersReturn';
  closestChambers: Array<ChamberReturn>;
};

export type SignUpSingleMatchingChamberReturn = {
  __typename?: 'SignUpSingleMatchingChamberReturn';
  chamberInfo: ChamberReturn;
  chamberMembers: Array<ChamberMemberReturn>;
};

export enum SortKey {
  Chamber = 'CHAMBER',
  ChamberMember = 'CHAMBER_MEMBER',
  ChamberContactableCompany = 'CHAMBER_CONTACTABLE_COMPANY',
  ContactableChamber = 'CONTACTABLE_CHAMBER',
  User = 'USER',
  Discount = 'DISCOUNT',
  RedeemedDiscount = 'REDEEMED_DISCOUNT',
  FavoritedDiscount = 'FAVORITED_DISCOUNT',
  FavoritedChamberMember = 'FAVORITED_CHAMBER_MEMBER',
  DeletedDiscount = 'DELETED_DISCOUNT',
  PerkPartnerDiscount = 'PERK_PARTNER_DISCOUNT',
  DeletedPerkPartnerDiscount = 'DELETED_PERK_PARTNER_DISCOUNT',
  PerkPartnerDiscountUpdateRecord = 'PERK_PARTNER_DISCOUNT_UPDATE_RECORD',
  ShopLocalCampaign = 'SHOP_LOCAL_CAMPAIGN',
  ShopLocalCampaignPrizeDrawParticipant = 'SHOP_LOCAL_CAMPAIGN_PRIZE_DRAW_PARTICIPANT',
  PerksLinkRequest = 'PERKS_LINK_REQUEST',
  DeletedPerksLinkRequest = 'DELETED_PERKS_LINK_REQUEST',
  RedBubbleNotificationView = 'RED_BUBBLE_NOTIFICATION_VIEW',
  AllCategoryAdvertisingBannersForChamber = 'ALL_CATEGORY_ADVERTISING_BANNERS_FOR_CHAMBER',
  CategoryAdvertisingBannerUpdateRecord = 'CATEGORY_ADVERTISING_BANNER_UPDATE_RECORD',
  MemberProfileAndPerksBannersUpdateRecord = 'MEMBER_PROFILE_AND_PERKS_BANNERS_UPDATE_RECORD',
  RewardCampaign = 'REWARD_CAMPAIGN',
  CapUserAgreedToRewardCampaign = 'CAP_USER_AGREED_TO_REWARD_CAMPAIGN',
  CapUserViewedRewardCampaign = 'CAP_USER_VIEWED_REWARD_CAMPAIGN',
  CapUserShareLeadingToOpenRecord = 'CAP_USER_SHARE_LEADING_TO_OPEN_RECORD',
  CapUserShareRecord = 'CAP_USER_SHARE_RECORD'
}

export type SystemWidePerkStatsReturn = {
  __typename?: 'SystemWidePerkStatsReturn';
  totalActivePerks: Scalars['Float'];
  numberOfMembersOfferingPerks: Scalars['Float'];
  percentOfMembersOfferingPerks: Scalars['Float'];
  totalPerkViews: Scalars['Float'];
  numOfAllMemberPerks: Scalars['Float'];
  numOfLocalMemberOnlyPerks: Scalars['Float'];
  numOfGeneralPublicPerks: Scalars['Float'];
  numOfCompanyEmployeesOnlyPerks: Scalars['Float'];
  totalNumOfMembers: Scalars['Float'];
  totalNumOfChambers: Scalars['Float'];
  totalLifeTimeCategoryAdvertisingBannersRevenueForCurrentChamber: Scalars['Float'];
  totalLifeTimeRevenueFromMemberProfileAndPerksBannersForCurrentChamber: Scalars['Float'];
  totalNumOfMembersWithActiveMemberProfileAndPerksBannersForCurrentChamber: Scalars['Float'];
  totalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersForCurrentChamber?: Maybe<Array<TotalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersReturn>>;
};

export type TimeSeriesAppUserDataPointReturn = {
  __typename?: 'TimeSeriesAppUserDataPointReturn';
  monthAndYear: Scalars['String'];
  numberOfAppUsers: Scalars['Float'];
};

export type TimeSeriesAppUsersDataReturn = {
  __typename?: 'TimeSeriesAppUsersDataReturn';
  monthly: Array<TimeSeriesAppUserDataPointReturn>;
};

export type TotalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersReturn = {
  __typename?: 'TotalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersReturn';
  categoryId: Scalars['String'];
  totalNumOfMembers: Scalars['Float'];
};

export type UnverifiedCpaUserInfoReturn = {
  __typename?: 'UnverifiedCpaUserInfoReturn';
  email: Scalars['String'];
  signUpChamber: Scalars['String'];
  signUpChamberMember?: Maybe<Scalars['String']>;
};

export type UpdateChamberEngageSettingsForChamberReturn = {
  __typename?: 'UpdateChamberEngageSettingsForChamberReturn';
  settingsUpdatedSuccessfully: Scalars['Boolean'];
};

export type UpdateChamberMutationInput = {
  data: ChamberInput;
};

export type UpdateDiscountReturn = {
  __typename?: 'UpdateDiscountReturn';
  id: Scalars['String'];
};

export type UpdateLoggedInUserLanguageMutationReturn = {
  __typename?: 'UpdateLoggedInUserLanguageMutationReturn';
  updated: Scalars['Boolean'];
};

export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationReturn = {
  __typename?: 'UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationReturn';
  updated: Scalars['Boolean'];
};

export type UpdatePerkPartnerDiscountReturn = {
  __typename?: 'UpdatePerkPartnerDiscountReturn';
  id: Scalars['String'];
};

export type UpdateRewardCampaignMutationReturn = {
  __typename?: 'UpdateRewardCampaignMutationReturn';
  campaignUpdatedSuccessfully: Scalars['Boolean'];
};

export type UpdateShopLocalCampaignReturn = {
  __typename?: 'UpdateShopLocalCampaignReturn';
  shopLocalCampaignUpdatedSuccessfully: Scalars['Boolean'];
};

export type UpdateUserMutationReturn = {
  __typename?: 'UpdateUserMutationReturn';
  id: Scalars['String'];
  updated: Scalars['Boolean'];
};

export type UpsertCapUserShareRecordMutationReturn = {
  __typename?: 'UpsertCapUserShareRecordMutationReturn';
  upsertedSuccessfully: Scalars['Boolean'];
};

export type UpsertRedBubbleNotificationViewReturn = {
  __typename?: 'UpsertRedBubbleNotificationViewReturn';
  redBubbleNotificationViewCreatedSuccessfully: Scalars['Boolean'];
};

export type UsagePermissionReturn = {
  __typename?: 'UsagePermissionReturn';
  chamberMemberPro_EnabledPages?: Maybe<Array<ChamberMemberProPagePermissionsEnum>>;
};

export type UserInput = {
  id: Scalars['String'];
  email: Scalars['String'];
  chamberId: Scalars['String'];
  chamberMemberId?: Maybe<Scalars['String']>;
  marketingEmailAccepted: Scalars['Boolean'];
  notificationToken?: Maybe<Scalars['String']>;
  newPerkNotificationsActive?: Maybe<Scalars['Boolean']>;
  nearbyPerkNotificationsActive?: Maybe<Scalars['Boolean']>;
};

export type UserIsEligibleBoolAndSendNotificationForEachEligibleNearbyDiscountReturn = {
  __typename?: 'UserIsEligibleBoolAndSendNotificationForEachEligibleNearbyDiscountReturn';
  discountId: Scalars['String'];
  userIsEligible: Scalars['Boolean'];
};

export type UserReturn = {
  __typename?: 'UserReturn';
  id: Scalars['String'];
  email: Scalars['String'];
  chamberId: Scalars['String'];
  chamberInfo: ChamberReturn;
  chamberMemberId?: Maybe<Scalars['String']>;
  chamberMemberInfo?: Maybe<ChamberMemberReturn>;
  marketingEmailAccepted: Scalars['Boolean'];
  notificationToken?: Maybe<Scalars['String']>;
  newPerkNotificationsActive?: Maybe<Scalars['Boolean']>;
  nearbyPerkNotificationsActive?: Maybe<Scalars['Boolean']>;
  languageCode?: Maybe<LanguageCodeEnum>;
  usagePermissions: UsagePermissionReturn;
  createdAtUtcMilli?: Maybe<Scalars['Float']>;
  updatedAtUtcMilli?: Maybe<Scalars['Float']>;
};

export enum UserType {
  CapUser = 'CAP_USER',
  RootAdmin = 'ROOT_ADMIN',
  ChamberAdmin = 'CHAMBER_ADMIN',
  ChamberMemberAdmin = 'CHAMBER_MEMBER_ADMIN',
  PerkPartnerDiscountAdmin = 'PERK_PARTNER_DISCOUNT_ADMIN'
}

export type VerifyUserVerificationCodeMutationReturn = {
  __typename?: 'VerifyUserVerificationCodeMutationReturn';
  userVerificationCodeVerifiedSuccessfully: Scalars['Boolean'];
};

export enum WhereToRedeemEnum {
  InPerson = 'IN_PERSON',
  Online = 'ONLINE',
  ContactCompany = 'CONTACT_COMPANY'
}

export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationVariables = Exact<{
  categoryId: ChamberMemberCategory;
}>;


export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember: (
    { __typename?: 'AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberReturn' }
    & Pick<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberReturn, 'agreementAcceptedSuccessfully'>
  ) }
);

export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember: (
    { __typename?: 'AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberReturn' }
    & Pick<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberReturn, 'agreementAcceptedSuccessfully'>
  ) }
);

export type AcceptPerksLinkRequestMutationVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type AcceptPerksLinkRequestMutation = (
  { __typename?: 'Mutation' }
  & { acceptPerksLinkRequest: (
    { __typename?: 'AcceptPerksLinkRequestReturn' }
    & Pick<AcceptPerksLinkRequestReturn, 'perksLinkAcceptedSuccessfully'>
  ) }
);

export type ActivateBannerForCategoryAdvertisingBannerMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  activationInfo: CategoryAdvertisingBannerActivationInfoInput;
}>;


export type ActivateBannerForCategoryAdvertisingBannerMutation = (
  { __typename?: 'Mutation' }
  & { activateBannerForCategoryAdvertisingBanner: (
    { __typename?: 'ActivateBannerForCategoryAdvertisingBannerReturn' }
    & Pick<ActivateBannerForCategoryAdvertisingBannerReturn, 'bannerActivatedSuccessfully'>
  ) }
);

export type ActivateMemberProfileAndPerksBannersForChamberMemberMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
  startDateUtcMilli: Scalars['Float'];
}>;


export type ActivateMemberProfileAndPerksBannersForChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { activateMemberProfileAndPerksBannersForChamberMember: (
    { __typename?: 'ActivateMemberProfileAndPerksBannersForChamberMemberReturn' }
    & Pick<ActivateMemberProfileAndPerksBannersForChamberMemberReturn, 'activationSuccessful'>
  ) }
);

export type ChamberChangeAdminConsoleLoginEmailMutationVariables = Exact<{
  currentAdminConsoleLoginEmail: Scalars['String'];
  newAdminConsoleLoginEmail: Scalars['String'];
  chamberId: Scalars['String'];
}>;


export type ChamberChangeAdminConsoleLoginEmailMutation = (
  { __typename?: 'Mutation' }
  & { chamberChangeAdminConsoleLoginEmail: (
    { __typename?: 'ChangeAdminConsoleLoginEmailReturn' }
    & Pick<ChangeAdminConsoleLoginEmailReturn, 'password' | 'failureReason' | 'adminConsoleLoginEmailChangedSuccessfully' | 'email'>
  ) }
);

export type ChamberMemberChangeAdminConsoleLoginEmailMutationVariables = Exact<{
  currentAdminConsoleLoginEmail: Scalars['String'];
  newAdminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
}>;


export type ChamberMemberChangeAdminConsoleLoginEmailMutation = (
  { __typename?: 'Mutation' }
  & { chamberMemberChangeAdminConsoleLoginEmail: (
    { __typename?: 'ChangeAdminConsoleLoginEmailReturn' }
    & Pick<ChangeAdminConsoleLoginEmailReturn, 'password' | 'failureReason' | 'adminConsoleLoginEmailChangedSuccessfully' | 'email'>
  ) }
);

export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  autoApproveBannerContentUpdate: Scalars['Boolean'];
}>;


export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation = (
  { __typename?: 'Mutation' }
  & { changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner: (
    { __typename?: 'ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerReturn' }
    & Pick<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerReturn, 'autoApprovalUpdateSuccessful'>
  ) }
);

export type CreateAllMemberLoginCredentialsMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateAllMemberLoginCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { createAllMemberLoginCredentials: (
    { __typename?: 'CreateAllMemberLoginCredentialsReturn' }
    & Pick<CreateAllMemberLoginCredentialsReturn, 'processId'>
  ) }
);

export type CreateChamberContactableCompanyMutationVariables = Exact<{
  input: CreateChamberContactableCompanyMutationInput;
}>;


export type CreateChamberContactableCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createChamberContactableCompany: (
    { __typename?: 'ContactableCompanyReturn' }
    & Pick<ContactableCompanyReturn, 'id' | 'chamberId' | 'name'>
  ) }
);

export type CreateChamberMembersMutationVariables = Exact<{
  input: CreateChamberMembersMutationInput;
}>;


export type CreateChamberMembersMutation = (
  { __typename?: 'Mutation' }
  & { createChamberMembers: (
    { __typename?: 'CreateChamberMembersMutationReturn' }
    & { results: Array<(
      { __typename?: 'ChamberMemberUserReturn' }
      & Pick<ChamberMemberUserReturn, 'chamberMemberAddedSuccessfully' | 'failureReason' | 'email' | 'password'>
    )> }
  ) }
);

export type CreateChambersMutationVariables = Exact<{
  input: CreateChamberMutationInput;
}>;


export type CreateChambersMutation = (
  { __typename?: 'Mutation' }
  & { createChambers: (
    { __typename?: 'CreateChamberMutationReturn' }
    & { results: Array<(
      { __typename?: 'ChamberAdminUserReturn' }
      & Pick<ChamberAdminUserReturn, 'chamberAddedSuccessfully' | 'email' | 'password' | 'failureReason'>
    )> }
  ) }
);

export type CreateDiscountsMutationVariables = Exact<{
  input: CreateDiscountsMutationInput;
}>;


export type CreateDiscountsMutation = (
  { __typename?: 'Mutation' }
  & { createDiscounts: (
    { __typename?: 'CreateDiscountsMutationReturn' }
    & Pick<CreateDiscountsMutationReturn, 'discountsAddedSuccessfully'>
  ) }
);

export type CreatePerkPartnerDiscountMutationVariables = Exact<{
  input: PerkPartnerDiscountInput;
}>;


export type CreatePerkPartnerDiscountMutation = (
  { __typename?: 'Mutation' }
  & { createPerkPartnerDiscount: (
    { __typename?: 'CreatePerkPartnerDiscountMutationReturn' }
    & Pick<CreatePerkPartnerDiscountMutationReturn, 'perkPartnerDiscountAddedSuccessfully'>
  ) }
);

export type CreatePerkPartnerDiscountAdminUserMutationVariables = Exact<{
  input: PerkPartnerDiscountAdminUserInput;
}>;


export type CreatePerkPartnerDiscountAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { createPerkPartnerDiscountAdminUser: (
    { __typename?: 'CreatePerkPartnerDiscountAdminUserMutationReturn' }
    & Pick<CreatePerkPartnerDiscountAdminUserMutationReturn, 'perkPartnerDiscountAdminUserCreatedSuccessfully' | 'email' | 'password' | 'failureReason'>
  ) }
);

export type CreateRewardCampaignMutationVariables = Exact<{
  input: RewardCampaignInput;
}>;


export type CreateRewardCampaignMutation = (
  { __typename?: 'Mutation' }
  & { createRewardCampaign: (
    { __typename?: 'CreateRewardCampaignMutationReturn' }
    & Pick<CreateRewardCampaignMutationReturn, 'campaignCreatedSuccessfully'>
  ) }
);

export type CreateShopLocalCampaignMutationVariables = Exact<{
  input: ShopLocalCampaignInput;
}>;


export type CreateShopLocalCampaignMutation = (
  { __typename?: 'Mutation' }
  & { createShopLocalCampaign: (
    { __typename?: 'CreateShopLocalCampaignReturn' }
    & Pick<CreateShopLocalCampaignReturn, 'shopLocalCampaignCreatedSuccessfully'>
  ) }
);

export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
}>;


export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { deactivateMemberProfileAndPerksBannersForChamberMember: (
    { __typename?: 'DeactivateMemberProfileAndPerksBannersForChamberMemberReturn' }
    & Pick<DeactivateMemberProfileAndPerksBannersForChamberMemberReturn, 'deactivationSuccessful'>
  ) }
);

export type DeleteChamberMemberMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { deleteChamberMember: (
    { __typename?: 'DeleteReturn' }
    & Pick<DeleteReturn, 'id'>
  ) }
);

export type DeleteChamberMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteChamberMutation = (
  { __typename?: 'Mutation' }
  & { deleteChamber: (
    { __typename?: 'DeleteReturn' }
    & Pick<DeleteReturn, 'id'>
  ) }
);

export type DeleteDiscountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscount: (
    { __typename?: 'DeleteReturn' }
    & Pick<DeleteReturn, 'id'>
  ) }
);

export type DeletePerkPartnerDiscountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePerkPartnerDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deletePerkPartnerDiscount: (
    { __typename?: 'DeleteReturn' }
    & Pick<DeleteReturn, 'id'>
  ) }
);

export type DeletePerkPartnerDiscountAdminUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePerkPartnerDiscountAdminUserMutation = (
  { __typename?: 'Mutation' }
  & { deletePerkPartnerDiscountAdminUser: (
    { __typename?: 'DeleteReturn' }
    & Pick<DeleteReturn, 'id'>
  ) }
);

export type DeletePerksLinkRequestMutationVariables = Exact<{
  requestId: Scalars['String'];
  deletionReason: PerksLinkDeletionReasonEnum;
}>;


export type DeletePerksLinkRequestMutation = (
  { __typename?: 'Mutation' }
  & { deletePerksLinkRequest: (
    { __typename?: 'DeletePerksLinkRequestReturn' }
    & Pick<DeletePerksLinkRequestReturn, 'perksLinkDeletedSuccessfully'>
  ) }
);

export type EndRewardCampaignMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type EndRewardCampaignMutation = (
  { __typename?: 'Mutation' }
  & { endRewardCampaign: (
    { __typename?: 'EndRewardCampaignMutationReturn' }
    & Pick<EndRewardCampaignMutationReturn, 'campaignEndedSuccessfully'>
  ) }
);

export type EndShopLocalCampaignMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type EndShopLocalCampaignMutation = (
  { __typename?: 'Mutation' }
  & { endShopLocalCampaign: (
    { __typename?: 'EndShopLocalCampaignReturn' }
    & Pick<EndShopLocalCampaignReturn, 'shopLocalCampaignEndedSuccessfully'>
  ) }
);

export type ExcludeChamberEngageActivationFromInvoiceMutationVariables = Exact<{
  activationId: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
}>;


export type ExcludeChamberEngageActivationFromInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { excludeChamberEngageActivationFromInvoice: (
    { __typename?: 'ExcludeChamberEngageActivationFromInvoiceReturn' }
    & Pick<ExcludeChamberEngageActivationFromInvoiceReturn, 'excluded'>
  ) }
);

export type GetSupportCenterUrlMutationVariables = Exact<{
  nonce: Scalars['String'];
  state: Scalars['String'];
}>;


export type GetSupportCenterUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getSupportCenterUrl'>
);

export type IncludeChamberEngageActivationInInvoiceMutationVariables = Exact<{
  activationId: Scalars['String'];
  activationType: ChamberEngageActivationTypeEnum;
}>;


export type IncludeChamberEngageActivationInInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { includeChamberEngageActivationInInvoice: (
    { __typename?: 'IncludeChamberEngageActivationInInvoiceReturn' }
    & Pick<IncludeChamberEngageActivationInInvoiceReturn, 'included'>
  ) }
);

export type MarkChamberEngageActivationInvoicedMutationVariables = Exact<{
  activationsToMarkInvoiced: Array<ChamberEngageActivationToMarkInvoicedInput> | ChamberEngageActivationToMarkInvoicedInput;
}>;


export type MarkChamberEngageActivationInvoicedMutation = (
  { __typename?: 'Mutation' }
  & { markChamberEngageActivationInvoiced: (
    { __typename?: 'MarkChamberEngageActivationInvoicedReturn' }
    & Pick<MarkChamberEngageActivationInvoicedReturn, 'marked'>
  ) }
);

export type ReactivateBannerForCategoryAdvertisingBannerMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  reactivationInfo: CategoryAdvertisingBannerReactivationInfoInput;
}>;


export type ReactivateBannerForCategoryAdvertisingBannerMutation = (
  { __typename?: 'Mutation' }
  & { reactivateBannerForCategoryAdvertisingBanner: (
    { __typename?: 'ActivateBannerForCategoryAdvertisingBannerReturn' }
    & Pick<ActivateBannerForCategoryAdvertisingBannerReturn, 'bannerActivatedSuccessfully'>
  ) }
);

export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  chamberMemberId: Scalars['String'];
}>;


export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { reactivateMemberProfileAndPerksBannersForChamberMember: (
    { __typename?: 'ActivateMemberProfileAndPerksBannersForChamberMemberReturn' }
    & Pick<ActivateMemberProfileAndPerksBannersForChamberMemberReturn, 'activationSuccessful'>
  ) }
);

export type RequestPerksLinkMutationVariables = Exact<{
  requestingLinkToChamberId: Scalars['String'];
}>;


export type RequestPerksLinkMutation = (
  { __typename?: 'Mutation' }
  & { requestPerksLink: (
    { __typename?: 'RequestPerksLinkReturn' }
    & Pick<RequestPerksLinkReturn, 'perksLinkRequestedSuccessfully'>
  ) }
);

export type SendLoginInfoEmailToChamberMemberMutationVariables = Exact<{
  adminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
  type: LoginInfoEmailTypes;
}>;


export type SendLoginInfoEmailToChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { sendLoginInfoEmailToChamberMember: (
    { __typename?: 'SendLoginInfoEmailToChamberMemberReturn' }
    & Pick<SendLoginInfoEmailToChamberMemberReturn, 'loginInfoEmailSentSuccessfully'>
  ) }
);

export type SetHasSignedUpForChamberMemberMutationVariables = Exact<{ [key: string]: never; }>;


export type SetHasSignedUpForChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { setHasSignedUpForChamberMember: (
    { __typename?: 'SetHasSignedUpForChamberMemberReturn' }
    & Pick<SetHasSignedUpForChamberMemberReturn, 'successfullySetHasSignedUp'>
  ) }
);

export type SetNewTemporaryChamberMemberPasswordMutationVariables = Exact<{
  adminConsoleLoginEmail: Scalars['String'];
  chamberMemberId: Scalars['String'];
}>;


export type SetNewTemporaryChamberMemberPasswordMutation = (
  { __typename?: 'Mutation' }
  & { setNewTemporaryChamberMemberPassword: (
    { __typename?: 'NewTemporaryChamberMemberPasswordReturn' }
    & Pick<NewTemporaryChamberMemberPasswordReturn, 'password' | 'failureReason'>
  ) }
);

export type UpdateBannerContentForCategoryAdvertisingBannerMutationVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
  categoryId: ChamberMemberCategory;
  banner?: Maybe<Array<BannerInput> | BannerInput>;
}>;


export type UpdateBannerContentForCategoryAdvertisingBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateBannerContentForCategoryAdvertisingBanner: (
    { __typename?: 'ActivateBannerForCategoryAdvertisingBannerReturn' }
    & Pick<ActivateBannerForCategoryAdvertisingBannerReturn, 'bannerActivatedSuccessfully'>
  ) }
);

export type UpdateChamberMutationVariables = Exact<{
  id: Scalars['String'];
  input: ChamberInput;
}>;


export type UpdateChamberMutation = (
  { __typename?: 'Mutation' }
  & { updateChamber: (
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'logo' | 'numberOfMembersFromInitialSubscriptionAgreement' | 'description' | 'hideInApp'>
    & { address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ) }
  ) }
);

export type UpdateChamberEngageSettingsForChamberMutationVariables = Exact<{
  chamberId: Scalars['String'];
  chamberEngageSettings: ChamberEngageSettingsInput;
}>;


export type UpdateChamberEngageSettingsForChamberMutation = (
  { __typename?: 'Mutation' }
  & { updateChamberEngageSettingsForChamber: (
    { __typename?: 'UpdateChamberEngageSettingsForChamberReturn' }
    & Pick<UpdateChamberEngageSettingsForChamberReturn, 'settingsUpdatedSuccessfully'>
  ) }
);

export type UpdateChamberMemberMutationVariables = Exact<{
  id: Scalars['String'];
  input: ChamberMemberInput;
}>;


export type UpdateChamberMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateChamberMember: (
    { __typename?: 'ChamberMemberReturn' }
    & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
    & { customContacts?: Maybe<Array<(
      { __typename?: 'CustomContactReturn' }
      & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>>, address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ) }
  ) }
);

export type UpdateDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  input: DiscountInput;
}>;


export type UpdateDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscount: (
    { __typename?: 'UpdateDiscountReturn' }
    & Pick<UpdateDiscountReturn, 'id'>
  ) }
);

export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationVariables = Exact<{
  activationId: Scalars['String'];
  newActivationFeeInDollars: Scalars['Float'];
}>;


export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation = (
  { __typename?: 'Mutation' }
  & { updateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivation: (
    { __typename?: 'UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationReturn' }
    & Pick<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationReturn, 'updated'>
  ) }
);

export type UpdatePerkPartnerDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  input: PerkPartnerDiscountInput;
}>;


export type UpdatePerkPartnerDiscountMutation = (
  { __typename?: 'Mutation' }
  & { updatePerkPartnerDiscount: (
    { __typename?: 'UpdatePerkPartnerDiscountReturn' }
    & Pick<UpdatePerkPartnerDiscountReturn, 'id'>
  ) }
);

export type UpdateRewardCampaignMutationVariables = Exact<{
  id: Scalars['String'];
  input: RewardCampaignInput;
}>;


export type UpdateRewardCampaignMutation = (
  { __typename?: 'Mutation' }
  & { updateRewardCampaign: (
    { __typename?: 'UpdateRewardCampaignMutationReturn' }
    & Pick<UpdateRewardCampaignMutationReturn, 'campaignUpdatedSuccessfully'>
  ) }
);

export type UpdateShopLocalCampaignMutationVariables = Exact<{
  id: Scalars['String'];
  input: ShopLocalCampaignInput;
}>;


export type UpdateShopLocalCampaignMutation = (
  { __typename?: 'Mutation' }
  & { updateShopLocalCampaign: (
    { __typename?: 'UpdateShopLocalCampaignReturn' }
    & Pick<UpdateShopLocalCampaignReturn, 'shopLocalCampaignUpdatedSuccessfully'>
  ) }
);

export type GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCategoryAdvertisingBannersForChamberMemberQuery = (
  { __typename?: 'Query' }
  & { getActiveCategoryAdvertisingBannersForChamberMember?: Maybe<Array<(
    { __typename?: 'CategoryAdvertisingBannerReturn' }
    & Pick<CategoryAdvertisingBannerReturn, 'categoryId'>
    & { bannerSettings?: Maybe<(
      { __typename?: 'CategoryAdvertisingBannerSettingsReturn' }
      & { activationInfo: (
        { __typename?: 'CategoryAdvertisingBannerActivationInfoReturn' }
        & Pick<CategoryAdvertisingBannerActivationInfoReturn, 'chamberMemberId' | 'activationStartDateUtcMilli' | 'chamberMemberHasAcceptedAgreement' | 'autoApproveBannerContentUpdate'>
        & { banner?: Maybe<Array<(
          { __typename?: 'BannerReturn' }
          & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position' | 'waitingForApprovalByChamberAdmin'>
        )>> }
      ) }
    )> }
  )>> }
);

export type GetActiveShopLocalCampaignForChamberMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveShopLocalCampaignForChamberMemberQuery = (
  { __typename?: 'Query' }
  & { getActiveShopLocalCampaignForChamber?: Maybe<(
    { __typename?: 'ShopLocalCampaignReturn' }
    & Pick<ShopLocalCampaignReturn, 'id' | 'chamberId' | 'campaignName' | 'prizeDescription' | 'startDateUtcMilli' | 'endDateUtcMilli' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { participatingMembers: Array<(
      { __typename?: 'ShopLocalCampaignParticipatingMembersReturn' }
      & Pick<ShopLocalCampaignParticipatingMembersReturn, 'memberId' | 'participationCode'>
    )> }
  )> }
);

export type GetAdminConsoleLoginEmailForChamberQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetAdminConsoleLoginEmailForChamberQuery = (
  { __typename?: 'Query' }
  & { getAdminConsoleLoginEmailForChamber: (
    { __typename?: 'AdminConsoleLoginEmailReturn' }
    & Pick<AdminConsoleLoginEmailReturn, 'adminConsoleLoginEmail'>
  ) }
);

export type GetAdminConsoleLoginEmailForChamberMemberQueryVariables = Exact<{
  chamberMemberId: Scalars['String'];
}>;


export type GetAdminConsoleLoginEmailForChamberMemberQuery = (
  { __typename?: 'Query' }
  & { getAdminConsoleLoginEmailForChamberMember: (
    { __typename?: 'AdminConsoleLoginEmailReturn' }
    & Pick<AdminConsoleLoginEmailReturn, 'adminConsoleLoginEmail'>
  ) }
);

export type GetAllAdminUsersForPerkPartnerDiscountQueryVariables = Exact<{
  perkPartnerDiscountId: Scalars['String'];
}>;


export type GetAllAdminUsersForPerkPartnerDiscountQuery = (
  { __typename?: 'Query' }
  & { getAllAdminUsersForPerkPartnerDiscount?: Maybe<Array<(
    { __typename?: 'PerkPartnerDiscountAdminUserReturn' }
    & Pick<PerkPartnerDiscountAdminUserReturn, 'id' | 'email' | 'perkPartnerDiscountId' | 'adminConsoleLoginEmail' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
  )>> }
);

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = (
  { __typename?: 'Query' }
  & { getAllCategories?: Maybe<Array<(
    { __typename?: 'CategoryReturn' }
    & Pick<CategoryReturn, 'id' | 'name'>
  )>> }
);

export type GetAllCategoryAdvertisingBannersForChamberQueryVariables = Exact<{
  chamberId?: Maybe<Scalars['String']>;
}>;


export type GetAllCategoryAdvertisingBannersForChamberQuery = (
  { __typename?: 'Query' }
  & { getAllCategoryAdvertisingBannersForChamber: (
    { __typename?: 'AllCategoryAdvertisingBannersForChamberReturn' }
    & Pick<AllCategoryAdvertisingBannersForChamberReturn, 'id' | 'chamberId' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { categoryAdvertisingBanners: Array<(
      { __typename?: 'CategoryAdvertisingBannerReturn' }
      & Pick<CategoryAdvertisingBannerReturn, 'categoryId'>
      & { bannerSettings?: Maybe<(
        { __typename?: 'CategoryAdvertisingBannerSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'CategoryAdvertisingBannerActivationInfoReturn' }
          & Pick<CategoryAdvertisingBannerActivationInfoReturn, 'chamberMemberId' | 'activationStartDateUtcMilli' | 'categoryAdvertisingBannersActivationFeeCpaSplitPercentageSetAtActivationTime' | 'autoApproveBannerContentUpdate'>
          & { banner?: Maybe<Array<(
            { __typename?: 'BannerReturn' }
            & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position' | 'waitingForApprovalByChamberAdmin'>
          )>>, bannerFeeOptionSetAtActivationTime: (
            { __typename?: 'CategoryAdvertisingBannersFeeOptionsReturn' }
            & Pick<CategoryAdvertisingBannersFeeOptionsReturn, 'timeLengthInMonths' | 'bannerFeeInDollars'>
          ) }
        ), reactivationInfo?: Maybe<(
          { __typename?: 'CategoryAdvertisingBannerActivationInfoReturn' }
          & Pick<CategoryAdvertisingBannerActivationInfoReturn, 'chamberMemberId' | 'activationStartDateUtcMilli' | 'categoryAdvertisingBannersActivationFeeCpaSplitPercentageSetAtActivationTime'>
          & { banner?: Maybe<Array<(
            { __typename?: 'BannerReturn' }
            & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position' | 'waitingForApprovalByChamberAdmin'>
          )>>, bannerFeeOptionSetAtActivationTime: (
            { __typename?: 'CategoryAdvertisingBannersFeeOptionsReturn' }
            & Pick<CategoryAdvertisingBannersFeeOptionsReturn, 'timeLengthInMonths' | 'bannerFeeInDollars'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type GetAllChamberEngageInvoiceDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllChamberEngageInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { getAllChamberEngageInvoiceData?: Maybe<Array<(
    { __typename?: 'ChamberEngageMonthlyInvoiceDataReturn' }
    & Pick<ChamberEngageMonthlyInvoiceDataReturn, 'year' | 'monthNum' | 'totalActivationFeeInDollars' | 'allInvoicesSentInMonth'>
    & { chamberData?: Maybe<Array<(
      { __typename?: 'ChamberEngageMonthlyInvoiceChamberDataReturn' }
      & Pick<ChamberEngageMonthlyInvoiceChamberDataReturn, 'chamberId' | 'chamberName' | 'memberProfileAndPerksBannersActivated' | 'memberProfileAndPerksBannersTotalActivationFeeInDollars' | 'categoryAdvertisingActivated' | 'categoryAdvertisingTotalActivationFeeInDollars' | 'allInvoicesSentForChamberInMonth'>
      & { activations?: Maybe<Array<(
        { __typename?: 'ChamberEngageMonthlyInvoiceIndividualActivationDataReturn' }
        & Pick<ChamberEngageMonthlyInvoiceIndividualActivationDataReturn, 'activationId' | 'chamberMemberId' | 'chamberMemberName' | 'activationType' | 'activationLengthInMonths' | 'activationFeeInDollars' | 'includeInInvoice' | 'invoiceSentAtUtcMilli' | 'categoryAdvertisingCategoryId'>
      )>> }
    )>> }
  )>> }
);

export type GetAllChamberMembersQueryVariables = Exact<{
  chamberId: Scalars['String'];
  getHasSignedUp?: Maybe<Scalars['Boolean']>;
  getNumberOfUsers?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllChamberMembersQuery = (
  { __typename?: 'Query' }
  & { getAllChamberMembers?: Maybe<Array<(
    { __typename?: 'ChamberMemberReturn' }
    & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'hasSignedUp' | 'numberOfUsers' | 'adminConsoleLoginEmail' | 'createdAtUtcMilli' | 'isSuspended'>
    & { customContacts?: Maybe<Array<(
      { __typename?: 'CustomContactReturn' }
      & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
      { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
      & { activationInfo: (
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
        & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
      ) }
    )>, address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ) }
  )>> }
);

export type GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetAllChamberMembers_RootAndChamberAdminOnlyQuery = (
  { __typename?: 'Query' }
  & { getAllChamberMembers?: Maybe<Array<(
    { __typename?: 'ChamberMemberReturn' }
    & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'hasSignedUp' | 'numberOfUsers' | 'adminConsoleLoginEmail' | 'createdAtUtcMilli' | 'isSuspended'>
    & { customContacts?: Maybe<Array<(
      { __typename?: 'CustomContactReturn' }
      & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>>, address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ), activeMemberProfileAndPerksBannersSettings?: Maybe<(
      { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
      & { activationInfo: (
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
        & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'memberProfileAndPerkBannerActivationFeeInDollarsAtTimeOfActivation' | 'memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation' | 'chamberMemberHasAcceptedAgreement'>
      ), reactivationInfo?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
        & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'memberProfileAndPerkBannerActivationFeeInDollarsAtTimeOfActivation' | 'memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation' | 'chamberMemberHasAcceptedAgreement'>
      )> }
    )> }
  )>> }
);

export type GetAllChambersQueryVariables = Exact<{
  getAllSandbox?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllChambersQuery = (
  { __typename?: 'Query' }
  & { getAllChambers?: Maybe<Array<(
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'logo' | 'numberOfMembersFromInitialSubscriptionAgreement' | 'description' | 'createdAtUtcMilli' | 'subscriptionDate' | 'hideInApp' | 'isSandbox'>
    & { address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ) }
  )>> }
);

export type GetAllChambersForRootAdminChamberEngageQueryVariables = Exact<{
  getAllSandbox?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllChambersForRootAdminChamberEngageQuery = (
  { __typename?: 'Query' }
  & { getAllChambers?: Maybe<Array<(
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'logo' | 'numberOfMembersFromInitialSubscriptionAgreement' | 'description' | 'createdAtUtcMilli' | 'subscriptionDate' | 'hideInApp'>
    & { address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ), chamberEngageSettings: (
      { __typename?: 'ChamberEngageSettingsReturn' }
      & Pick<ChamberEngageSettingsReturn, 'isActive' | 'memberProfileAndPerkBannerActivationFeeInDollars' | 'memberProfileAndPerkBannerAnnualBannerFeeInDollars' | 'categoryAdvertisingBannersActivationFeeCpaSplitPercentage'>
      & { categoryAdvertisingBannersFeeOptions: Array<(
        { __typename?: 'CategoryAdvertisingBannersFeeOptionsReturn' }
        & Pick<CategoryAdvertisingBannersFeeOptionsReturn, 'timeLengthInMonths' | 'bannerFeeInDollars'>
      )> }
    ) }
  )>> }
);

export type GetAllContactableCompaniesForChamberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContactableCompaniesForChamberQuery = (
  { __typename?: 'Query' }
  & { getAllContactableCompaniesForChamber?: Maybe<Array<(
    { __typename?: 'ContactableCompanyReturn' }
    & Pick<ContactableCompanyReturn, 'id' | 'chamberId' | 'chamberName' | 'name' | 'createdAtUtcMilli'>
  )>> }
);

export type GetAllDeletedDiscountsFromChamberIdQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetAllDeletedDiscountsFromChamberIdQuery = (
  { __typename?: 'Query' }
  & { getAllDeletedDiscountsFromChamberId?: Maybe<Array<(
    { __typename?: 'DeletedDiscountReturn' }
    & Pick<DeletedDiscountReturn, 'id' | 'title' | 'description' | 'chamberId' | 'chamberMemberId' | 'memberOnly' | 'isAvailableToAllMembers' | 'isReoccuring' | 'daysAvailable' | 'monthsAvailable' | 'isPrivate' | 'views' | 'numberOfRedemptions' | 'createdAtUtcMilli' | 'deletedAtUtcMilli'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ), chamberMemberInfo: (
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ) }
  )>> }
);

export type GetAllDeletedPerkPartnerDiscountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDeletedPerkPartnerDiscountsQuery = (
  { __typename?: 'Query' }
  & { getAllDeletedPerkPartnerDiscounts?: Maybe<Array<(
    { __typename?: 'DeletedPerkPartnerDiscountReturn' }
    & Pick<DeletedPerkPartnerDiscountReturn, 'id' | 'companyName' | 'title' | 'description' | 'verifiedChamberMemberCapUserRedirectLink' | 'unverifiedChamberMemberCapUserRedirectLink' | 'logo' | 'availableToChamberIds' | 'createdAtUtcMilli' | 'updatedAtUtcMilli' | 'deletedAtUtcMilli'>
    & { banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  )>> }
);

export type GetAllDiscountsFromChamberIdQueryVariables = Exact<{
  chamberId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
}>;


export type GetAllDiscountsFromChamberIdQuery = (
  { __typename?: 'Query' }
  & { getAllDiscountsFromChamberId?: Maybe<Array<(
    { __typename?: 'DiscountReturn' }
    & Pick<DiscountReturn, 'id' | 'title' | 'description' | 'chamberId' | 'chamberMemberId' | 'memberOnly' | 'isAvailableToAllMembers' | 'isReoccuring' | 'daysAvailable' | 'monthsAvailable' | 'isPrivate' | 'views' | 'numberOfRedemptions' | 'createdAtUtcMilli'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ), chamberMemberInfo: (
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ) }
  )>> }
);

export type GetAllDiscountsFromChamberMemberIdQueryVariables = Exact<{
  chamberMemberId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  localDay?: Maybe<DayEnum>;
  localMonth?: Maybe<MonthEnum>;
}>;


export type GetAllDiscountsFromChamberMemberIdQuery = (
  { __typename?: 'Query' }
  & { getAllDiscountsFromChamberMemberId?: Maybe<Array<(
    { __typename?: 'DiscountReturn' }
    & Pick<DiscountReturn, 'id' | 'title' | 'description' | 'chamberId' | 'chamberMemberId' | 'memberOnly' | 'isAvailableToAllMembers' | 'isReoccuring' | 'daysAvailable' | 'monthsAvailable' | 'isPrivate' | 'numberOfRedemptions' | 'views'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ), chamberMemberInfo: (
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ) }
  )>> }
);

export type GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery = (
  { __typename?: 'Query' }
  & { getChamber: (
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli'>
  ) }
);

export type GetAllMemberLoginCredentialsQueryVariables = Exact<{
  processId: Scalars['String'];
}>;


export type GetAllMemberLoginCredentialsQuery = (
  { __typename?: 'Query' }
  & { getAllMemberLoginCredentials: (
    { __typename?: 'GetAllMemberLoginCredentialsReturn' }
    & Pick<GetAllMemberLoginCredentialsReturn, 'status' | 'successCSVRawTextOfAllMemberLoginCredentials'>
  ) }
);

export type GetAllPerkPartnerDiscountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPerkPartnerDiscountsQuery = (
  { __typename?: 'Query' }
  & { getAllPerkPartnerDiscounts?: Maybe<Array<(
    { __typename?: 'PerkPartnerDiscountReturn' }
    & Pick<PerkPartnerDiscountReturn, 'id' | 'companyName' | 'title' | 'description' | 'verifiedChamberMemberCapUserRedirectLink' | 'unverifiedChamberMemberCapUserRedirectLink' | 'logo' | 'availableToChamberIds' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { availableToChambers?: Maybe<Array<(
      { __typename?: 'RootAdminPerkPartnerDiscountAvailableToChambersReturn' }
      & Pick<RootAdminPerkPartnerDiscountAvailableToChambersReturn, 'id' | 'name'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  )>> }
);

export type GetAllPerksLinksForChamberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPerksLinksForChamberQuery = (
  { __typename?: 'Query' }
  & { getAllPerksLinksForChamber: Array<(
    { __typename?: 'PerksLinkReturn' }
    & Pick<PerksLinkReturn, 'chamberId' | 'chamberName' | 'chamberContactEmail' | 'chamberContactNumber'>
    & { pendingOrAcceptedPerksLinkRequestInfo?: Maybe<(
      { __typename?: 'PerksLinkRequestReturn' }
      & Pick<PerksLinkRequestReturn, 'id' | 'status' | 'requestorChamberId' | 'requesteeChamberId' | 'linkedAtTimeUtcMilli' | 'createdAtUtcMilli'>
    )> }
  )> }
);

export type GetAllRewardCampaignsForChamberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRewardCampaignsForChamberQuery = (
  { __typename?: 'Query' }
  & { getAllRewardCampaignsForChamber?: Maybe<Array<(
    { __typename?: 'RewardCampaignReturn' }
    & Pick<RewardCampaignReturn, 'id' | 'chamberId' | 'sponsoringChamberMemberId' | 'campaignName' | 'prizeDescription' | 'startDateUtcMilli' | 'endDateUtcMilli' | 'createdAtUtcMilli' | 'updatedAtUtcMilli' | 'isCreatedByCpaAdmin'>
    & { prizeDrawParticipants?: Maybe<Array<(
      { __typename?: 'PrizeDrawParticipantReturn' }
      & Pick<PrizeDrawParticipantReturn, 'email' | 'ballotsEarned'>
    )>> }
  )>> }
);

export type GetAllShopLocalCampaignsForChamberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllShopLocalCampaignsForChamberQuery = (
  { __typename?: 'Query' }
  & { getAllShopLocalCampaignsForChamber?: Maybe<Array<(
    { __typename?: 'ShopLocalCampaignReturn' }
    & Pick<ShopLocalCampaignReturn, 'id' | 'chamberId' | 'campaignName' | 'prizeDescription' | 'startDateUtcMilli' | 'endDateUtcMilli' | 'participatingMemberIds' | 'status' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { participatingMembers: Array<(
      { __typename?: 'ShopLocalCampaignParticipatingMembersReturn' }
      & Pick<ShopLocalCampaignParticipatingMembersReturn, 'memberId' | 'participationCode'>
    )>, prizeDrawParticipants?: Maybe<Array<(
      { __typename?: 'ShopLocalCampaignPrizeDrawParticipantsReturn' }
      & Pick<ShopLocalCampaignPrizeDrawParticipantsReturn, 'userEmail' | 'redeemedAtMemberIds'>
    )>> }
  )>> }
);

export type GetChamberQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetChamberQuery = (
  { __typename?: 'Query' }
  & { getChamber: (
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'logo' | 'numberOfMembersFromInitialSubscriptionAgreement' | 'description' | 'createdAtUtcMilli' | 'hideInApp'>
    & { address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ), directoryBanner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>>, profileBanner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  ) }
);

export type GetChamberMemberQueryVariables = Exact<{
  chamberMemberId: Scalars['String'];
}>;


export type GetChamberMemberQuery = (
  { __typename?: 'Query' }
  & { getChamberMember: (
    { __typename?: 'ChamberMemberReturn' }
    & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
    & { customContacts?: Maybe<Array<(
      { __typename?: 'CustomContactReturn' }
      & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
      { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
      & { activationInfo: (
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
        & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
      ) }
    )>, address: (
      { __typename?: 'AddressReturn' }
      & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
    ) }
  ) }
);

export type GetDiscountQueryVariables = Exact<{
  discountId: Scalars['String'];
}>;


export type GetDiscountQuery = (
  { __typename?: 'Query' }
  & { getDiscount: (
    { __typename?: 'DiscountReturn' }
    & Pick<DiscountReturn, 'id' | 'title' | 'description' | 'chamberId' | 'chamberMemberId' | 'memberOnly' | 'isReoccuring' | 'daysAvailable' | 'monthsAvailable' | 'isAvailableToAllMembers' | 'isPrivate' | 'privateEmails' | 'expiryTimeUtcMilli' | 'startTimeUtcMilli'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ), chamberMemberInfo: (
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    ), redeemSettings: (
      { __typename?: 'RedeemSettingsReturn' }
      & Pick<RedeemSettingsReturn, 'whereToRedeem'>
      & { howToRedeem?: Maybe<(
        { __typename?: 'HowToRedeemReturn' }
        & Pick<HowToRedeemReturn, 'code' | 'websiteToEnterCodeAt' | 'contactEmail' | 'contactNumber' | 'contactName'>
      )> }
    ), banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  ) }
);

export type GetPerkPartnerDiscountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPerkPartnerDiscountQuery = (
  { __typename?: 'Query' }
  & { getPerkPartnerDiscount: (
    { __typename?: 'PerkPartnerDiscountReturn' }
    & Pick<PerkPartnerDiscountReturn, 'id' | 'companyName' | 'title' | 'description' | 'verifiedChamberMemberCapUserRedirectLink' | 'unverifiedChamberMemberCapUserRedirectLink' | 'logo' | 'availableToChamberIds' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { availableToChambers?: Maybe<Array<(
      { __typename?: 'RootAdminPerkPartnerDiscountAvailableToChambersReturn' }
      & Pick<RootAdminPerkPartnerDiscountAvailableToChambersReturn, 'id' | 'name'>
    )>>, banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  ) }
);

export type GetPerkPartnerDiscountAdminUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPerkPartnerDiscountAdminUserQuery = (
  { __typename?: 'Query' }
  & { getPerkPartnerDiscountAdminUser: (
    { __typename?: 'PerkPartnerDiscountAdminUserReturn' }
    & Pick<PerkPartnerDiscountAdminUserReturn, 'id' | 'email' | 'perkPartnerDiscountId' | 'adminConsoleLoginEmail' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
  ) }
);

export type GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery = (
  { __typename?: 'Query' }
  & { getPerkPartnerDiscount: (
    { __typename?: 'PerkPartnerDiscountReturn' }
    & Pick<PerkPartnerDiscountReturn, 'id' | 'companyName' | 'title' | 'description' | 'verifiedChamberMemberCapUserRedirectLink' | 'unverifiedChamberMemberCapUserRedirectLink' | 'logo' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { banner?: Maybe<Array<(
      { __typename?: 'BannerReturn' }
      & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
    )>> }
  ) }
);

export type GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery = (
  { __typename?: 'Query' }
  & { getPerkPartnerDiscountStats: (
    { __typename?: 'PerkPartnerDiscountStatReturn' }
    & Pick<PerkPartnerDiscountStatReturn, 'id' | 'totalViewCount' | 'totalOpenCount' | 'totalOpensFromViewsConversionRatePercentage'>
    & { splitByChamber?: Maybe<Array<(
      { __typename?: 'PerkPartnerDiscountStatSplitByChamberReturn' }
      & Pick<PerkPartnerDiscountStatSplitByChamberReturn, 'chamberId' | 'chamberName' | 'totalViewCount' | 'totalOpenCount'>
    )>>, timeseriesData?: Maybe<(
      { __typename?: 'PerkPartnerDiscountTimeseriesDataReturn' }
      & { allChambers: (
        { __typename?: 'PerkPartnerDiscountTimeseriesDataset' }
        & Pick<PerkPartnerDiscountTimeseriesDataset, 'id' | 'name'>
        & { timeseriesData: Array<(
          { __typename?: 'PerkPartnerDiscountTimeseriesDataPoint' }
          & Pick<PerkPartnerDiscountTimeseriesDataPoint, 'monthAndYear' | 'totalOpenCount' | 'totalViewCount'>
        )> }
      ), chambers: Array<(
        { __typename?: 'PerkPartnerDiscountTimeseriesDataset' }
        & Pick<PerkPartnerDiscountTimeseriesDataset, 'id' | 'name'>
        & { timeseriesData: Array<(
          { __typename?: 'PerkPartnerDiscountTimeseriesDataPoint' }
          & Pick<PerkPartnerDiscountTimeseriesDataPoint, 'monthAndYear' | 'totalOpenCount' | 'totalViewCount'>
        )> }
      )> }
    )> }
  ) }
);

export type GetPerkPartnerDiscountStatsForRootAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPerkPartnerDiscountStatsForRootAdminQuery = (
  { __typename?: 'Query' }
  & { getPerkPartnerDiscountStats: (
    { __typename?: 'PerkPartnerDiscountStatReturn' }
    & Pick<PerkPartnerDiscountStatReturn, 'id' | 'totalViewCount' | 'totalIneligibleViewCount' | 'totalUnverifiedViewCount' | 'totalVerifiedViewCount' | 'totalOpenCount' | 'totalIneligibleOpenCount' | 'totalUnverifiedOpenCount' | 'totalVerifiedOpenCount' | 'totalOpensFromViewsConversionRatePercentage' | 'totalRedeemCount' | 'totalUnverifiedRedeemCount' | 'totalVerifiedRedeemCount' | 'totalVerifiedNoAccessToEmailRedeemCount' | 'totalRedeemCountFromOpensConversionRatePercentage'>
    & { splitByChamber?: Maybe<Array<(
      { __typename?: 'PerkPartnerDiscountStatSplitByChamberReturn' }
      & Pick<PerkPartnerDiscountStatSplitByChamberReturn, 'chamberId' | 'chamberName' | 'chamberIsEnabledForPerkPartnerDiscount' | 'totalViewCount' | 'ineligibleViewCount' | 'unverifiedViewCount' | 'verifiedViewCount' | 'totalOpenCount' | 'ineligibleOpenCount' | 'unverifiedOpenCount' | 'verifiedOpenCount' | 'totalRedeemCount' | 'unverifiedRedeemCount' | 'verifiedRedeemCount' | 'verifiedNoAccessToEmailRedeemCount'>
    )>>, timeseriesData?: Maybe<(
      { __typename?: 'PerkPartnerDiscountTimeseriesDataReturn' }
      & { allChambers: (
        { __typename?: 'PerkPartnerDiscountTimeseriesDataset' }
        & Pick<PerkPartnerDiscountTimeseriesDataset, 'id' | 'name'>
        & { timeseriesData: Array<(
          { __typename?: 'PerkPartnerDiscountTimeseriesDataPoint' }
          & Pick<PerkPartnerDiscountTimeseriesDataPoint, 'monthAndYear' | 'totalOpenCount' | 'totalViewCount' | 'totalRedeemCount'>
        )> }
      ), chambers: Array<(
        { __typename?: 'PerkPartnerDiscountTimeseriesDataset' }
        & Pick<PerkPartnerDiscountTimeseriesDataset, 'id' | 'name'>
        & { timeseriesData: Array<(
          { __typename?: 'PerkPartnerDiscountTimeseriesDataPoint' }
          & Pick<PerkPartnerDiscountTimeseriesDataPoint, 'monthAndYear' | 'totalOpenCount' | 'totalViewCount' | 'totalRedeemCount'>
        )> }
      )> }
    )> }
  ) }
);

export type GetPremiumChamberMarketerLinkQueryVariables = Exact<{
  chamberId: Scalars['String'];
}>;


export type GetPremiumChamberMarketerLinkQuery = (
  { __typename?: 'Query' }
  & { getChamber: (
    { __typename?: 'ChamberReturn' }
    & Pick<ChamberReturn, 'id' | 'premiumChamberMarketerLink'>
  ) }
);

export type GetRewardCampaignQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRewardCampaignQuery = (
  { __typename?: 'Query' }
  & { getRewardCampaign?: Maybe<(
    { __typename?: 'RewardCampaignReturn' }
    & Pick<RewardCampaignReturn, 'id' | 'chamberId' | 'sponsoringChamberMemberId' | 'campaignName' | 'prizeDescription' | 'startDateUtcMilli' | 'endDateUtcMilli' | 'createdAtUtcMilli' | 'updatedAtUtcMilli' | 'isCreatedByCpaAdmin'>
    & { prizeDrawParticipants?: Maybe<Array<(
      { __typename?: 'PrizeDrawParticipantReturn' }
      & Pick<PrizeDrawParticipantReturn, 'email' | 'ballotsEarned'>
    )>> }
  )> }
);

export type GetRootAdminStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRootAdminStatsQuery = (
  { __typename?: 'Query' }
  & { getRootAdminStats: (
    { __typename?: 'RootAdminStatsReturn' }
    & Pick<RootAdminStatsReturn, 'totalChambers' | 'totalChamberMembers' | 'totalSuspendedChamberMembers' | 'totalDiscountViews' | 'totalDiscountRedemptions' | 'numberOfChamberMembersOfferingLiveDiscounts' | 'percentOfChamberMembersOfferingLiveDiscounts' | 'totalNumberOfLiveDiscounts' | 'totalNumberOfLiveAllMemberDiscounts' | 'totalNumberOfLiveLocalMemberOnlyDiscounts' | 'totalNumberOfLiveGeneralPublicDiscounts' | 'totalNumberOfLiveCompanyEmployeesOnlyDiscounts' | 'totalNumberOfAppUsers' | 'totalNumberOfAppUsersSignedUpWithAnEmployer' | 'totalNumberOfAppUsersSignedUpWithoutAnEmployer' | 'totalNumberOfAppUsersWithNewPerkNotificationEnabled' | 'totalPercentageOfAppUsersWithNewPerkNotificationEnabled' | 'totalNumberOfAppUsersWithNearbyPerkNotificationEnabled' | 'totalPercentageOfAppUsersWithNearbyPerkNotificationEnabled'>
    & { timeseriesAppUsersData: (
      { __typename?: 'TimeSeriesAppUsersDataReturn' }
      & { monthly: Array<(
        { __typename?: 'TimeSeriesAppUserDataPointReturn' }
        & Pick<TimeSeriesAppUserDataPointReturn, 'monthAndYear' | 'numberOfAppUsers'>
      )> }
    ), chambersSortedByNewest: Array<(
      { __typename?: 'ChambersStatsReturn' }
      & Pick<ChambersStatsReturn, 'name' | 'subscriptionDate' | 'subscriptionUTCMilli' | 'numberOfAppUsers' | 'numberOfAppUsersSignedUpWithAnEmployer' | 'numberOfAppUsersSignedUpWithoutAnEmployer' | 'numberOfLiveDiscounts' | 'numberOfDiscountViews' | 'numberOfDiscountRedemptions' | 'numberOfChamberMembersLiveOnApp' | 'numberOfSuspendedChamberMembers' | 'numberOfMembersFromInitialSubscriptionAgreement' | 'numberChangeInNumberOfChamberMembersBetweenNowAndInitial' | 'percentageChangeInNumberOfChamberMembersBetweenNowAndInitial' | 'numberOfChamberMembersLoggedInToAdminPortal' | 'percentageOfChamberMembersLoggedInToAdminPortal' | 'numberOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin' | 'percentageOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin' | 'numberOfChamberMembersOfferingLiveDiscounts' | 'percentageOfChamberMembersOfferingLiveDiscounts' | 'numberOfAppUsersWithNewPerkNotificationEnabled' | 'percentageOfAppUsersWithNewPerkNotificationEnabled' | 'numberOfAppUsersWithNearbyPerkNotificationEnabled' | 'percentageOfAppUsersWithNearbyPerkNotificationEnabled'>
      & { discountsPerCategoryBreakdown: Array<(
        { __typename?: 'DiscountsPerCategoryBreakdownReturn' }
        & Pick<DiscountsPerCategoryBreakdownReturn, 'categoryName' | 'numberOfDiscounts' | 'percentageVersusOtherCategories'>
      )> }
    )>, newestChamberMembers: Array<(
      { __typename?: 'DeletedAndNonDeletedChamberMemberStatsReturn' }
      & Pick<DeletedAndNonDeletedChamberMemberStatsReturn, 'name' | 'chamberName' | 'membershipStatus' | 'category1' | 'category2' | 'numberOfAppUsersWithThisMemberSetAsTheirEmployer' | 'numberOfDeletedAndNonDeletedDiscounts' | 'hasLoggedInToAdminPortal' | 'hasLogo' | 'createdAtUtcMilli' | 'createdAtUtcTimestamp'>
    )>, newestDiscounts: Array<(
      { __typename?: 'DeletedAndNonDeletedDiscountStatsReturn' }
      & Pick<DeletedAndNonDeletedDiscountStatsReturn, 'title' | 'chamberName' | 'chamberMemberName' | 'offeredTo' | 'views' | 'numberOfRedemptions' | 'category1' | 'category2' | 'createdAtUtcMilli' | 'createdAtUtcTimestamp' | 'deletedAtUtcMilli' | 'deletedAtUtcTimestamp'>
    )>, discountsPerCategoryBreakdown: Array<(
      { __typename?: 'DiscountsPerCategoryBreakdownReturn' }
      & Pick<DiscountsPerCategoryBreakdownReturn, 'categoryName' | 'numberOfDiscounts' | 'percentageVersusOtherCategories'>
    )>, contactableChambersSortedByNewest: Array<(
      { __typename?: 'ContactableChamberReturn' }
      & Pick<ContactableChamberReturn, 'id' | 'name' | 'createdAtUtcMilli' | 'createdAtUtcTimestamp'>
    )>, contactableCompaniesSortedByNewest: Array<(
      { __typename?: 'ContactableCompanyReturn' }
      & Pick<ContactableCompanyReturn, 'id' | 'chamberId' | 'chamberName' | 'name' | 'createdAtUtcMilli' | 'createdAtUtcTimestamp'>
    )> }
  ) }
);

export type GetSampleLoginInfoEmailQueryVariables = Exact<{
  type: LoginInfoEmailTypes;
  chamberMemberId?: Maybe<Scalars['String']>;
}>;


export type GetSampleLoginInfoEmailQuery = (
  { __typename?: 'Query' }
  & { getSampleLoginInfoEmail: (
    { __typename?: 'SampleLoginInfoEmailReturn' }
    & Pick<SampleLoginInfoEmailReturn, 'emailHtml'>
  ) }
);

export type GetSampleMemberLoginCredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSampleMemberLoginCredentialsQuery = (
  { __typename?: 'Query' }
  & { getSampleMemberLoginCredentials: (
    { __typename?: 'GetSampleMemberLoginCredentialsReturn' }
    & Pick<GetSampleMemberLoginCredentialsReturn, 'sampleCSVRawTextOfAllMemberLoginCredentials'>
  ) }
);

export type GetShopLocalCampaignQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetShopLocalCampaignQuery = (
  { __typename?: 'Query' }
  & { getShopLocalCampaign?: Maybe<(
    { __typename?: 'ShopLocalCampaignReturn' }
    & Pick<ShopLocalCampaignReturn, 'id' | 'chamberId' | 'campaignName' | 'prizeDescription' | 'startDateUtcMilli' | 'endDateUtcMilli' | 'participatingMemberIds' | 'status' | 'createdAtUtcMilli' | 'updatedAtUtcMilli'>
    & { participatingMembers: Array<(
      { __typename?: 'ShopLocalCampaignParticipatingMembersReturn' }
      & Pick<ShopLocalCampaignParticipatingMembersReturn, 'memberId' | 'participationCode'>
    )>, prizeDrawParticipants?: Maybe<Array<(
      { __typename?: 'ShopLocalCampaignPrizeDrawParticipantsReturn' }
      & Pick<ShopLocalCampaignPrizeDrawParticipantsReturn, 'userEmail' | 'redeemedAtMemberIds'>
    )>> }
  )> }
);

export type GetSystemWidePerkStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemWidePerkStatsQuery = (
  { __typename?: 'Query' }
  & { getSystemWidePerkStats: (
    { __typename?: 'SystemWidePerkStatsReturn' }
    & Pick<SystemWidePerkStatsReturn, 'totalActivePerks' | 'numberOfMembersOfferingPerks' | 'percentOfMembersOfferingPerks' | 'totalPerkViews' | 'numOfAllMemberPerks' | 'numOfLocalMemberOnlyPerks' | 'numOfGeneralPublicPerks' | 'numOfCompanyEmployeesOnlyPerks' | 'totalNumOfMembers' | 'totalNumOfChambers' | 'totalLifeTimeCategoryAdvertisingBannersRevenueForCurrentChamber' | 'totalLifeTimeRevenueFromMemberProfileAndPerksBannersForCurrentChamber' | 'totalNumOfMembersWithActiveMemberProfileAndPerksBannersForCurrentChamber'>
    & { totalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersForCurrentChamber?: Maybe<Array<(
      { __typename?: 'TotalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersReturn' }
      & Pick<TotalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersReturn, 'categoryId' | 'totalNumOfMembers'>
    )>> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserReturn' }
    & Pick<UserReturn, 'id' | 'email' | 'chamberId' | 'chamberMemberId' | 'marketingEmailAccepted'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'isSandbox' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ), directoryBanner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, profileBanner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, chamberEngageSettings: (
        { __typename?: 'ChamberEngageSettingsReturn' }
        & Pick<ChamberEngageSettingsReturn, 'isActive'>
      ) }
    ), chamberMemberInfo?: Maybe<(
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'hasSignedUp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    )> }
  ) }
);

export type GetUser_RootAdminChamberAdminQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUser_RootAdminChamberAdminQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserReturn' }
    & Pick<UserReturn, 'id' | 'email' | 'chamberId' | 'chamberMemberId' | 'marketingEmailAccepted'>
    & { chamberInfo: (
      { __typename?: 'ChamberReturn' }
      & Pick<ChamberReturn, 'id' | 'name' | 'website' | 'supportContactEmail' | 'googleMapsApi_City_ProvinceShort_CountryShort' | 'supportContactName' | 'supportLink' | 'phoneNumber' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'jobPostingLink' | 'eventsLink' | 'newsletterLink' | 'surveyLink' | 'advocacyWinsLink' | 'memberSignupLink' | 'sponsorLogo' | 'sponsorLogoLink' | 'description' | 'logo' | 'isSandbox' | 'hideInApp'>
      & { address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ), directoryBanner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, profileBanner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, chamberEngageSettings: (
        { __typename?: 'ChamberEngageSettingsReturn' }
        & Pick<ChamberEngageSettingsReturn, 'isActive' | 'memberProfileAndPerkBannerAnnualBannerFeeInDollars'>
        & { categoryAdvertisingBannersFeeOptions: Array<(
          { __typename?: 'CategoryAdvertisingBannersFeeOptionsReturn' }
          & Pick<CategoryAdvertisingBannersFeeOptionsReturn, 'timeLengthInMonths' | 'bannerFeeInDollars'>
        )> }
      ) }
    ), chamberMemberInfo?: Maybe<(
      { __typename?: 'ChamberMemberReturn' }
      & Pick<ChamberMemberReturn, 'id' | 'chamberId' | 'categoryId' | 'credentialsSentAtUtcMilli' | 'adminConsoleContactName' | 'name' | 'description' | 'website' | 'phoneNumber' | 'contactName' | 'email' | 'logo' | 'facebook' | 'instagram' | 'threads' | 'linkedin' | 'twitter' | 'tiktok' | 'youtube' | 'showInApp' | 'hasSignedUp' | 'isSuspended'>
      & { customContacts?: Maybe<Array<(
        { __typename?: 'CustomContactReturn' }
        & Pick<CustomContactReturn, 'id' | 'name' | 'email' | 'jobTitle' | 'phoneNumber'>
      )>>, banner?: Maybe<Array<(
        { __typename?: 'BannerReturn' }
        & Pick<BannerReturn, 'contentUrl' | 'imageLink' | 'contentType' | 'position'>
      )>>, activeMemberProfileAndPerksBannersSettings?: Maybe<(
        { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsReturn' }
        & { activationInfo: (
          { __typename?: 'ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn' }
          & Pick<ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn, 'startDateUtcMilli' | 'chamberMemberHasAcceptedAgreement'>
        ) }
      )>, address: (
        { __typename?: 'AddressReturn' }
        & Pick<AddressReturn, 'streetAddress' | 'city' | 'province' | 'postalCode'>
      ) }
    )> }
  ) }
);


export const AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberDocument = gql`
    mutation acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember($categoryId: ChamberMemberCategory!) {
  acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember(
    categoryId: $categoryId
  ) {
    agreementAcceptedSuccessfully
  }
}
    `;
export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationFn = Apollo.MutationFunction<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationVariables>;

/**
 * __useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation__
 *
 * To run a mutation, you first call `useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation, { data, loading, error }] = useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationVariables>(AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberDocument, options);
      }
export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationHookResult = ReturnType<typeof useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation>;
export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationResult = Apollo.MutationResult<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation>;
export type AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationOptions = Apollo.BaseMutationOptions<AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutationVariables>;
export const AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberDocument = gql`
    mutation acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember {
  acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember {
    agreementAcceptedSuccessfully
  }
}
    `;
export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationFn = Apollo.MutationFunction<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationVariables>;

/**
 * __useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation__
 *
 * To run a mutation, you first call `useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation, { data, loading, error }] = useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationVariables>(AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberDocument, options);
      }
export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationHookResult = ReturnType<typeof useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation>;
export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationResult = Apollo.MutationResult<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation>;
export type AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationOptions = Apollo.BaseMutationOptions<AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation, AcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutationVariables>;
export const AcceptPerksLinkRequestDocument = gql`
    mutation acceptPerksLinkRequest($requestId: String!) {
  acceptPerksLinkRequest(requestId: $requestId) {
    perksLinkAcceptedSuccessfully
  }
}
    `;
export type AcceptPerksLinkRequestMutationFn = Apollo.MutationFunction<AcceptPerksLinkRequestMutation, AcceptPerksLinkRequestMutationVariables>;

/**
 * __useAcceptPerksLinkRequestMutation__
 *
 * To run a mutation, you first call `useAcceptPerksLinkRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPerksLinkRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPerksLinkRequestMutation, { data, loading, error }] = useAcceptPerksLinkRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useAcceptPerksLinkRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptPerksLinkRequestMutation, AcceptPerksLinkRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptPerksLinkRequestMutation, AcceptPerksLinkRequestMutationVariables>(AcceptPerksLinkRequestDocument, options);
      }
export type AcceptPerksLinkRequestMutationHookResult = ReturnType<typeof useAcceptPerksLinkRequestMutation>;
export type AcceptPerksLinkRequestMutationResult = Apollo.MutationResult<AcceptPerksLinkRequestMutation>;
export type AcceptPerksLinkRequestMutationOptions = Apollo.BaseMutationOptions<AcceptPerksLinkRequestMutation, AcceptPerksLinkRequestMutationVariables>;
export const ActivateBannerForCategoryAdvertisingBannerDocument = gql`
    mutation activateBannerForCategoryAdvertisingBanner($chamberId: String, $categoryId: ChamberMemberCategory!, $activationInfo: CategoryAdvertisingBannerActivationInfoInput!) {
  activateBannerForCategoryAdvertisingBanner(
    chamberId: $chamberId
    categoryId: $categoryId
    activationInfo: $activationInfo
  ) {
    bannerActivatedSuccessfully
  }
}
    `;
export type ActivateBannerForCategoryAdvertisingBannerMutationFn = Apollo.MutationFunction<ActivateBannerForCategoryAdvertisingBannerMutation, ActivateBannerForCategoryAdvertisingBannerMutationVariables>;

/**
 * __useActivateBannerForCategoryAdvertisingBannerMutation__
 *
 * To run a mutation, you first call `useActivateBannerForCategoryAdvertisingBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBannerForCategoryAdvertisingBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBannerForCategoryAdvertisingBannerMutation, { data, loading, error }] = useActivateBannerForCategoryAdvertisingBannerMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      categoryId: // value for 'categoryId'
 *      activationInfo: // value for 'activationInfo'
 *   },
 * });
 */
export function useActivateBannerForCategoryAdvertisingBannerMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBannerForCategoryAdvertisingBannerMutation, ActivateBannerForCategoryAdvertisingBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBannerForCategoryAdvertisingBannerMutation, ActivateBannerForCategoryAdvertisingBannerMutationVariables>(ActivateBannerForCategoryAdvertisingBannerDocument, options);
      }
export type ActivateBannerForCategoryAdvertisingBannerMutationHookResult = ReturnType<typeof useActivateBannerForCategoryAdvertisingBannerMutation>;
export type ActivateBannerForCategoryAdvertisingBannerMutationResult = Apollo.MutationResult<ActivateBannerForCategoryAdvertisingBannerMutation>;
export type ActivateBannerForCategoryAdvertisingBannerMutationOptions = Apollo.BaseMutationOptions<ActivateBannerForCategoryAdvertisingBannerMutation, ActivateBannerForCategoryAdvertisingBannerMutationVariables>;
export const ActivateMemberProfileAndPerksBannersForChamberMemberDocument = gql`
    mutation activateMemberProfileAndPerksBannersForChamberMember($chamberId: String, $chamberMemberId: String!, $startDateUtcMilli: Float!) {
  activateMemberProfileAndPerksBannersForChamberMember(
    chamberId: $chamberId
    chamberMemberId: $chamberMemberId
    startDateUtcMilli: $startDateUtcMilli
  ) {
    activationSuccessful
  }
}
    `;
export type ActivateMemberProfileAndPerksBannersForChamberMemberMutationFn = Apollo.MutationFunction<ActivateMemberProfileAndPerksBannersForChamberMemberMutation, ActivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;

/**
 * __useActivateMemberProfileAndPerksBannersForChamberMemberMutation__
 *
 * To run a mutation, you first call `useActivateMemberProfileAndPerksBannersForChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMemberProfileAndPerksBannersForChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMemberProfileAndPerksBannersForChamberMemberMutation, { data, loading, error }] = useActivateMemberProfileAndPerksBannersForChamberMemberMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      chamberMemberId: // value for 'chamberMemberId'
 *      startDateUtcMilli: // value for 'startDateUtcMilli'
 *   },
 * });
 */
export function useActivateMemberProfileAndPerksBannersForChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<ActivateMemberProfileAndPerksBannersForChamberMemberMutation, ActivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateMemberProfileAndPerksBannersForChamberMemberMutation, ActivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>(ActivateMemberProfileAndPerksBannersForChamberMemberDocument, options);
      }
export type ActivateMemberProfileAndPerksBannersForChamberMemberMutationHookResult = ReturnType<typeof useActivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type ActivateMemberProfileAndPerksBannersForChamberMemberMutationResult = Apollo.MutationResult<ActivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type ActivateMemberProfileAndPerksBannersForChamberMemberMutationOptions = Apollo.BaseMutationOptions<ActivateMemberProfileAndPerksBannersForChamberMemberMutation, ActivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;
export const ChamberChangeAdminConsoleLoginEmailDocument = gql`
    mutation chamberChangeAdminConsoleLoginEmail($currentAdminConsoleLoginEmail: String!, $newAdminConsoleLoginEmail: String!, $chamberId: String!) {
  chamberChangeAdminConsoleLoginEmail(
    currentAdminConsoleLoginEmail: $currentAdminConsoleLoginEmail
    newAdminConsoleLoginEmail: $newAdminConsoleLoginEmail
    chamberId: $chamberId
  ) {
    password
    failureReason
    adminConsoleLoginEmailChangedSuccessfully
    email
  }
}
    `;
export type ChamberChangeAdminConsoleLoginEmailMutationFn = Apollo.MutationFunction<ChamberChangeAdminConsoleLoginEmailMutation, ChamberChangeAdminConsoleLoginEmailMutationVariables>;

/**
 * __useChamberChangeAdminConsoleLoginEmailMutation__
 *
 * To run a mutation, you first call `useChamberChangeAdminConsoleLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChamberChangeAdminConsoleLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chamberChangeAdminConsoleLoginEmailMutation, { data, loading, error }] = useChamberChangeAdminConsoleLoginEmailMutation({
 *   variables: {
 *      currentAdminConsoleLoginEmail: // value for 'currentAdminConsoleLoginEmail'
 *      newAdminConsoleLoginEmail: // value for 'newAdminConsoleLoginEmail'
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useChamberChangeAdminConsoleLoginEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChamberChangeAdminConsoleLoginEmailMutation, ChamberChangeAdminConsoleLoginEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChamberChangeAdminConsoleLoginEmailMutation, ChamberChangeAdminConsoleLoginEmailMutationVariables>(ChamberChangeAdminConsoleLoginEmailDocument, options);
      }
export type ChamberChangeAdminConsoleLoginEmailMutationHookResult = ReturnType<typeof useChamberChangeAdminConsoleLoginEmailMutation>;
export type ChamberChangeAdminConsoleLoginEmailMutationResult = Apollo.MutationResult<ChamberChangeAdminConsoleLoginEmailMutation>;
export type ChamberChangeAdminConsoleLoginEmailMutationOptions = Apollo.BaseMutationOptions<ChamberChangeAdminConsoleLoginEmailMutation, ChamberChangeAdminConsoleLoginEmailMutationVariables>;
export const ChamberMemberChangeAdminConsoleLoginEmailDocument = gql`
    mutation chamberMemberChangeAdminConsoleLoginEmail($currentAdminConsoleLoginEmail: String!, $newAdminConsoleLoginEmail: String!, $chamberMemberId: String!) {
  chamberMemberChangeAdminConsoleLoginEmail(
    currentAdminConsoleLoginEmail: $currentAdminConsoleLoginEmail
    newAdminConsoleLoginEmail: $newAdminConsoleLoginEmail
    chamberMemberId: $chamberMemberId
  ) {
    password
    failureReason
    adminConsoleLoginEmailChangedSuccessfully
    email
  }
}
    `;
export type ChamberMemberChangeAdminConsoleLoginEmailMutationFn = Apollo.MutationFunction<ChamberMemberChangeAdminConsoleLoginEmailMutation, ChamberMemberChangeAdminConsoleLoginEmailMutationVariables>;

/**
 * __useChamberMemberChangeAdminConsoleLoginEmailMutation__
 *
 * To run a mutation, you first call `useChamberMemberChangeAdminConsoleLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChamberMemberChangeAdminConsoleLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chamberMemberChangeAdminConsoleLoginEmailMutation, { data, loading, error }] = useChamberMemberChangeAdminConsoleLoginEmailMutation({
 *   variables: {
 *      currentAdminConsoleLoginEmail: // value for 'currentAdminConsoleLoginEmail'
 *      newAdminConsoleLoginEmail: // value for 'newAdminConsoleLoginEmail'
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useChamberMemberChangeAdminConsoleLoginEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChamberMemberChangeAdminConsoleLoginEmailMutation, ChamberMemberChangeAdminConsoleLoginEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChamberMemberChangeAdminConsoleLoginEmailMutation, ChamberMemberChangeAdminConsoleLoginEmailMutationVariables>(ChamberMemberChangeAdminConsoleLoginEmailDocument, options);
      }
export type ChamberMemberChangeAdminConsoleLoginEmailMutationHookResult = ReturnType<typeof useChamberMemberChangeAdminConsoleLoginEmailMutation>;
export type ChamberMemberChangeAdminConsoleLoginEmailMutationResult = Apollo.MutationResult<ChamberMemberChangeAdminConsoleLoginEmailMutation>;
export type ChamberMemberChangeAdminConsoleLoginEmailMutationOptions = Apollo.BaseMutationOptions<ChamberMemberChangeAdminConsoleLoginEmailMutation, ChamberMemberChangeAdminConsoleLoginEmailMutationVariables>;
export const ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerDocument = gql`
    mutation changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner($chamberId: String, $categoryId: ChamberMemberCategory!, $autoApproveBannerContentUpdate: Boolean!) {
  changeAutoApprovalForBannerContentOfCategoryAdvertisingBanner(
    chamberId: $chamberId
    categoryId: $categoryId
    autoApproveBannerContentUpdate: $autoApproveBannerContentUpdate
  ) {
    autoApprovalUpdateSuccessful
  }
}
    `;
export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationFn = Apollo.MutationFunction<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation, ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationVariables>;

/**
 * __useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation__
 *
 * To run a mutation, you first call `useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation, { data, loading, error }] = useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      categoryId: // value for 'categoryId'
 *      autoApproveBannerContentUpdate: // value for 'autoApproveBannerContentUpdate'
 *   },
 * });
 */
export function useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation, ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation, ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationVariables>(ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerDocument, options);
      }
export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationHookResult = ReturnType<typeof useChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation>;
export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationResult = Apollo.MutationResult<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation>;
export type ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationOptions = Apollo.BaseMutationOptions<ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutation, ChangeAutoApprovalForBannerContentOfCategoryAdvertisingBannerMutationVariables>;
export const CreateAllMemberLoginCredentialsDocument = gql`
    mutation createAllMemberLoginCredentials {
  createAllMemberLoginCredentials {
    processId
  }
}
    `;
export type CreateAllMemberLoginCredentialsMutationFn = Apollo.MutationFunction<CreateAllMemberLoginCredentialsMutation, CreateAllMemberLoginCredentialsMutationVariables>;

/**
 * __useCreateAllMemberLoginCredentialsMutation__
 *
 * To run a mutation, you first call `useCreateAllMemberLoginCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllMemberLoginCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllMemberLoginCredentialsMutation, { data, loading, error }] = useCreateAllMemberLoginCredentialsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAllMemberLoginCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAllMemberLoginCredentialsMutation, CreateAllMemberLoginCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAllMemberLoginCredentialsMutation, CreateAllMemberLoginCredentialsMutationVariables>(CreateAllMemberLoginCredentialsDocument, options);
      }
export type CreateAllMemberLoginCredentialsMutationHookResult = ReturnType<typeof useCreateAllMemberLoginCredentialsMutation>;
export type CreateAllMemberLoginCredentialsMutationResult = Apollo.MutationResult<CreateAllMemberLoginCredentialsMutation>;
export type CreateAllMemberLoginCredentialsMutationOptions = Apollo.BaseMutationOptions<CreateAllMemberLoginCredentialsMutation, CreateAllMemberLoginCredentialsMutationVariables>;
export const CreateChamberContactableCompanyDocument = gql`
    mutation createChamberContactableCompany($input: CreateChamberContactableCompanyMutationInput!) {
  createChamberContactableCompany(input: $input) {
    id
    chamberId
    name
  }
}
    `;
export type CreateChamberContactableCompanyMutationFn = Apollo.MutationFunction<CreateChamberContactableCompanyMutation, CreateChamberContactableCompanyMutationVariables>;

/**
 * __useCreateChamberContactableCompanyMutation__
 *
 * To run a mutation, you first call `useCreateChamberContactableCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChamberContactableCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChamberContactableCompanyMutation, { data, loading, error }] = useCreateChamberContactableCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChamberContactableCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateChamberContactableCompanyMutation, CreateChamberContactableCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChamberContactableCompanyMutation, CreateChamberContactableCompanyMutationVariables>(CreateChamberContactableCompanyDocument, options);
      }
export type CreateChamberContactableCompanyMutationHookResult = ReturnType<typeof useCreateChamberContactableCompanyMutation>;
export type CreateChamberContactableCompanyMutationResult = Apollo.MutationResult<CreateChamberContactableCompanyMutation>;
export type CreateChamberContactableCompanyMutationOptions = Apollo.BaseMutationOptions<CreateChamberContactableCompanyMutation, CreateChamberContactableCompanyMutationVariables>;
export const CreateChamberMembersDocument = gql`
    mutation createChamberMembers($input: CreateChamberMembersMutationInput!) {
  createChamberMembers(input: $input) {
    results {
      chamberMemberAddedSuccessfully
      failureReason
      email
      password
    }
  }
}
    `;
export type CreateChamberMembersMutationFn = Apollo.MutationFunction<CreateChamberMembersMutation, CreateChamberMembersMutationVariables>;

/**
 * __useCreateChamberMembersMutation__
 *
 * To run a mutation, you first call `useCreateChamberMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChamberMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChamberMembersMutation, { data, loading, error }] = useCreateChamberMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChamberMembersMutation(baseOptions?: Apollo.MutationHookOptions<CreateChamberMembersMutation, CreateChamberMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChamberMembersMutation, CreateChamberMembersMutationVariables>(CreateChamberMembersDocument, options);
      }
export type CreateChamberMembersMutationHookResult = ReturnType<typeof useCreateChamberMembersMutation>;
export type CreateChamberMembersMutationResult = Apollo.MutationResult<CreateChamberMembersMutation>;
export type CreateChamberMembersMutationOptions = Apollo.BaseMutationOptions<CreateChamberMembersMutation, CreateChamberMembersMutationVariables>;
export const CreateChambersDocument = gql`
    mutation createChambers($input: CreateChamberMutationInput!) {
  createChambers(input: $input) {
    results {
      chamberAddedSuccessfully
      email
      password
      failureReason
    }
  }
}
    `;
export type CreateChambersMutationFn = Apollo.MutationFunction<CreateChambersMutation, CreateChambersMutationVariables>;

/**
 * __useCreateChambersMutation__
 *
 * To run a mutation, you first call `useCreateChambersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChambersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChambersMutation, { data, loading, error }] = useCreateChambersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChambersMutation(baseOptions?: Apollo.MutationHookOptions<CreateChambersMutation, CreateChambersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChambersMutation, CreateChambersMutationVariables>(CreateChambersDocument, options);
      }
export type CreateChambersMutationHookResult = ReturnType<typeof useCreateChambersMutation>;
export type CreateChambersMutationResult = Apollo.MutationResult<CreateChambersMutation>;
export type CreateChambersMutationOptions = Apollo.BaseMutationOptions<CreateChambersMutation, CreateChambersMutationVariables>;
export const CreateDiscountsDocument = gql`
    mutation createDiscounts($input: CreateDiscountsMutationInput!) {
  createDiscounts(input: $input) {
    discountsAddedSuccessfully
  }
}
    `;
export type CreateDiscountsMutationFn = Apollo.MutationFunction<CreateDiscountsMutation, CreateDiscountsMutationVariables>;

/**
 * __useCreateDiscountsMutation__
 *
 * To run a mutation, you first call `useCreateDiscountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountsMutation, { data, loading, error }] = useCreateDiscountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiscountsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscountsMutation, CreateDiscountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscountsMutation, CreateDiscountsMutationVariables>(CreateDiscountsDocument, options);
      }
export type CreateDiscountsMutationHookResult = ReturnType<typeof useCreateDiscountsMutation>;
export type CreateDiscountsMutationResult = Apollo.MutationResult<CreateDiscountsMutation>;
export type CreateDiscountsMutationOptions = Apollo.BaseMutationOptions<CreateDiscountsMutation, CreateDiscountsMutationVariables>;
export const CreatePerkPartnerDiscountDocument = gql`
    mutation createPerkPartnerDiscount($input: PerkPartnerDiscountInput!) {
  createPerkPartnerDiscount(input: $input) {
    perkPartnerDiscountAddedSuccessfully
  }
}
    `;
export type CreatePerkPartnerDiscountMutationFn = Apollo.MutationFunction<CreatePerkPartnerDiscountMutation, CreatePerkPartnerDiscountMutationVariables>;

/**
 * __useCreatePerkPartnerDiscountMutation__
 *
 * To run a mutation, you first call `useCreatePerkPartnerDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerkPartnerDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerkPartnerDiscountMutation, { data, loading, error }] = useCreatePerkPartnerDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerkPartnerDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerkPartnerDiscountMutation, CreatePerkPartnerDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerkPartnerDiscountMutation, CreatePerkPartnerDiscountMutationVariables>(CreatePerkPartnerDiscountDocument, options);
      }
export type CreatePerkPartnerDiscountMutationHookResult = ReturnType<typeof useCreatePerkPartnerDiscountMutation>;
export type CreatePerkPartnerDiscountMutationResult = Apollo.MutationResult<CreatePerkPartnerDiscountMutation>;
export type CreatePerkPartnerDiscountMutationOptions = Apollo.BaseMutationOptions<CreatePerkPartnerDiscountMutation, CreatePerkPartnerDiscountMutationVariables>;
export const CreatePerkPartnerDiscountAdminUserDocument = gql`
    mutation createPerkPartnerDiscountAdminUser($input: PerkPartnerDiscountAdminUserInput!) {
  createPerkPartnerDiscountAdminUser(input: $input) {
    perkPartnerDiscountAdminUserCreatedSuccessfully
    email
    password
    failureReason
  }
}
    `;
export type CreatePerkPartnerDiscountAdminUserMutationFn = Apollo.MutationFunction<CreatePerkPartnerDiscountAdminUserMutation, CreatePerkPartnerDiscountAdminUserMutationVariables>;

/**
 * __useCreatePerkPartnerDiscountAdminUserMutation__
 *
 * To run a mutation, you first call `useCreatePerkPartnerDiscountAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerkPartnerDiscountAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerkPartnerDiscountAdminUserMutation, { data, loading, error }] = useCreatePerkPartnerDiscountAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePerkPartnerDiscountAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<CreatePerkPartnerDiscountAdminUserMutation, CreatePerkPartnerDiscountAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePerkPartnerDiscountAdminUserMutation, CreatePerkPartnerDiscountAdminUserMutationVariables>(CreatePerkPartnerDiscountAdminUserDocument, options);
      }
export type CreatePerkPartnerDiscountAdminUserMutationHookResult = ReturnType<typeof useCreatePerkPartnerDiscountAdminUserMutation>;
export type CreatePerkPartnerDiscountAdminUserMutationResult = Apollo.MutationResult<CreatePerkPartnerDiscountAdminUserMutation>;
export type CreatePerkPartnerDiscountAdminUserMutationOptions = Apollo.BaseMutationOptions<CreatePerkPartnerDiscountAdminUserMutation, CreatePerkPartnerDiscountAdminUserMutationVariables>;
export const CreateRewardCampaignDocument = gql`
    mutation createRewardCampaign($input: RewardCampaignInput!) {
  createRewardCampaign(input: $input) {
    campaignCreatedSuccessfully
  }
}
    `;
export type CreateRewardCampaignMutationFn = Apollo.MutationFunction<CreateRewardCampaignMutation, CreateRewardCampaignMutationVariables>;

/**
 * __useCreateRewardCampaignMutation__
 *
 * To run a mutation, you first call `useCreateRewardCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRewardCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRewardCampaignMutation, { data, loading, error }] = useCreateRewardCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRewardCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateRewardCampaignMutation, CreateRewardCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRewardCampaignMutation, CreateRewardCampaignMutationVariables>(CreateRewardCampaignDocument, options);
      }
export type CreateRewardCampaignMutationHookResult = ReturnType<typeof useCreateRewardCampaignMutation>;
export type CreateRewardCampaignMutationResult = Apollo.MutationResult<CreateRewardCampaignMutation>;
export type CreateRewardCampaignMutationOptions = Apollo.BaseMutationOptions<CreateRewardCampaignMutation, CreateRewardCampaignMutationVariables>;
export const CreateShopLocalCampaignDocument = gql`
    mutation createShopLocalCampaign($input: ShopLocalCampaignInput!) {
  createShopLocalCampaign(input: $input) {
    shopLocalCampaignCreatedSuccessfully
  }
}
    `;
export type CreateShopLocalCampaignMutationFn = Apollo.MutationFunction<CreateShopLocalCampaignMutation, CreateShopLocalCampaignMutationVariables>;

/**
 * __useCreateShopLocalCampaignMutation__
 *
 * To run a mutation, you first call `useCreateShopLocalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopLocalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopLocalCampaignMutation, { data, loading, error }] = useCreateShopLocalCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopLocalCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopLocalCampaignMutation, CreateShopLocalCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopLocalCampaignMutation, CreateShopLocalCampaignMutationVariables>(CreateShopLocalCampaignDocument, options);
      }
export type CreateShopLocalCampaignMutationHookResult = ReturnType<typeof useCreateShopLocalCampaignMutation>;
export type CreateShopLocalCampaignMutationResult = Apollo.MutationResult<CreateShopLocalCampaignMutation>;
export type CreateShopLocalCampaignMutationOptions = Apollo.BaseMutationOptions<CreateShopLocalCampaignMutation, CreateShopLocalCampaignMutationVariables>;
export const DeactivateMemberProfileAndPerksBannersForChamberMemberDocument = gql`
    mutation deactivateMemberProfileAndPerksBannersForChamberMember($chamberId: String, $chamberMemberId: String!) {
  deactivateMemberProfileAndPerksBannersForChamberMember(
    chamberId: $chamberId
    chamberMemberId: $chamberMemberId
  ) {
    deactivationSuccessful
  }
}
    `;
export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutationFn = Apollo.MutationFunction<DeactivateMemberProfileAndPerksBannersForChamberMemberMutation, DeactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;

/**
 * __useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation__
 *
 * To run a mutation, you first call `useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMemberProfileAndPerksBannersForChamberMemberMutation, { data, loading, error }] = useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateMemberProfileAndPerksBannersForChamberMemberMutation, DeactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateMemberProfileAndPerksBannersForChamberMemberMutation, DeactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>(DeactivateMemberProfileAndPerksBannersForChamberMemberDocument, options);
      }
export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutationHookResult = ReturnType<typeof useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutationResult = Apollo.MutationResult<DeactivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type DeactivateMemberProfileAndPerksBannersForChamberMemberMutationOptions = Apollo.BaseMutationOptions<DeactivateMemberProfileAndPerksBannersForChamberMemberMutation, DeactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;
export const DeleteChamberMemberDocument = gql`
    mutation deleteChamberMember($id: String!) {
  deleteChamberMember(id: $id) {
    id
  }
}
    `;
export type DeleteChamberMemberMutationFn = Apollo.MutationFunction<DeleteChamberMemberMutation, DeleteChamberMemberMutationVariables>;

/**
 * __useDeleteChamberMemberMutation__
 *
 * To run a mutation, you first call `useDeleteChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChamberMemberMutation, { data, loading, error }] = useDeleteChamberMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChamberMemberMutation, DeleteChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChamberMemberMutation, DeleteChamberMemberMutationVariables>(DeleteChamberMemberDocument, options);
      }
export type DeleteChamberMemberMutationHookResult = ReturnType<typeof useDeleteChamberMemberMutation>;
export type DeleteChamberMemberMutationResult = Apollo.MutationResult<DeleteChamberMemberMutation>;
export type DeleteChamberMemberMutationOptions = Apollo.BaseMutationOptions<DeleteChamberMemberMutation, DeleteChamberMemberMutationVariables>;
export const DeleteChamberDocument = gql`
    mutation deleteChamber($id: String!) {
  deleteChamber(id: $id) {
    id
  }
}
    `;
export type DeleteChamberMutationFn = Apollo.MutationFunction<DeleteChamberMutation, DeleteChamberMutationVariables>;

/**
 * __useDeleteChamberMutation__
 *
 * To run a mutation, you first call `useDeleteChamberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChamberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChamberMutation, { data, loading, error }] = useDeleteChamberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChamberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChamberMutation, DeleteChamberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChamberMutation, DeleteChamberMutationVariables>(DeleteChamberDocument, options);
      }
export type DeleteChamberMutationHookResult = ReturnType<typeof useDeleteChamberMutation>;
export type DeleteChamberMutationResult = Apollo.MutationResult<DeleteChamberMutation>;
export type DeleteChamberMutationOptions = Apollo.BaseMutationOptions<DeleteChamberMutation, DeleteChamberMutationVariables>;
export const DeleteDiscountDocument = gql`
    mutation deleteDiscount($id: String!) {
  deleteDiscount(id: $id) {
    id
  }
}
    `;
export type DeleteDiscountMutationFn = Apollo.MutationFunction<DeleteDiscountMutation, DeleteDiscountMutationVariables>;

/**
 * __useDeleteDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscountMutation, { data, loading, error }] = useDeleteDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument, options);
      }
export type DeleteDiscountMutationHookResult = ReturnType<typeof useDeleteDiscountMutation>;
export type DeleteDiscountMutationResult = Apollo.MutationResult<DeleteDiscountMutation>;
export type DeleteDiscountMutationOptions = Apollo.BaseMutationOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>;
export const DeletePerkPartnerDiscountDocument = gql`
    mutation deletePerkPartnerDiscount($id: String!) {
  deletePerkPartnerDiscount(id: $id) {
    id
  }
}
    `;
export type DeletePerkPartnerDiscountMutationFn = Apollo.MutationFunction<DeletePerkPartnerDiscountMutation, DeletePerkPartnerDiscountMutationVariables>;

/**
 * __useDeletePerkPartnerDiscountMutation__
 *
 * To run a mutation, you first call `useDeletePerkPartnerDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerkPartnerDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerkPartnerDiscountMutation, { data, loading, error }] = useDeletePerkPartnerDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePerkPartnerDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeletePerkPartnerDiscountMutation, DeletePerkPartnerDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePerkPartnerDiscountMutation, DeletePerkPartnerDiscountMutationVariables>(DeletePerkPartnerDiscountDocument, options);
      }
export type DeletePerkPartnerDiscountMutationHookResult = ReturnType<typeof useDeletePerkPartnerDiscountMutation>;
export type DeletePerkPartnerDiscountMutationResult = Apollo.MutationResult<DeletePerkPartnerDiscountMutation>;
export type DeletePerkPartnerDiscountMutationOptions = Apollo.BaseMutationOptions<DeletePerkPartnerDiscountMutation, DeletePerkPartnerDiscountMutationVariables>;
export const DeletePerkPartnerDiscountAdminUserDocument = gql`
    mutation deletePerkPartnerDiscountAdminUser($id: String!) {
  deletePerkPartnerDiscountAdminUser(id: $id) {
    id
  }
}
    `;
export type DeletePerkPartnerDiscountAdminUserMutationFn = Apollo.MutationFunction<DeletePerkPartnerDiscountAdminUserMutation, DeletePerkPartnerDiscountAdminUserMutationVariables>;

/**
 * __useDeletePerkPartnerDiscountAdminUserMutation__
 *
 * To run a mutation, you first call `useDeletePerkPartnerDiscountAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerkPartnerDiscountAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerkPartnerDiscountAdminUserMutation, { data, loading, error }] = useDeletePerkPartnerDiscountAdminUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePerkPartnerDiscountAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<DeletePerkPartnerDiscountAdminUserMutation, DeletePerkPartnerDiscountAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePerkPartnerDiscountAdminUserMutation, DeletePerkPartnerDiscountAdminUserMutationVariables>(DeletePerkPartnerDiscountAdminUserDocument, options);
      }
export type DeletePerkPartnerDiscountAdminUserMutationHookResult = ReturnType<typeof useDeletePerkPartnerDiscountAdminUserMutation>;
export type DeletePerkPartnerDiscountAdminUserMutationResult = Apollo.MutationResult<DeletePerkPartnerDiscountAdminUserMutation>;
export type DeletePerkPartnerDiscountAdminUserMutationOptions = Apollo.BaseMutationOptions<DeletePerkPartnerDiscountAdminUserMutation, DeletePerkPartnerDiscountAdminUserMutationVariables>;
export const DeletePerksLinkRequestDocument = gql`
    mutation deletePerksLinkRequest($requestId: String!, $deletionReason: PerksLinkDeletionReasonEnum!) {
  deletePerksLinkRequest(requestId: $requestId, deletionReason: $deletionReason) {
    perksLinkDeletedSuccessfully
  }
}
    `;
export type DeletePerksLinkRequestMutationFn = Apollo.MutationFunction<DeletePerksLinkRequestMutation, DeletePerksLinkRequestMutationVariables>;

/**
 * __useDeletePerksLinkRequestMutation__
 *
 * To run a mutation, you first call `useDeletePerksLinkRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePerksLinkRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePerksLinkRequestMutation, { data, loading, error }] = useDeletePerksLinkRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      deletionReason: // value for 'deletionReason'
 *   },
 * });
 */
export function useDeletePerksLinkRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeletePerksLinkRequestMutation, DeletePerksLinkRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePerksLinkRequestMutation, DeletePerksLinkRequestMutationVariables>(DeletePerksLinkRequestDocument, options);
      }
export type DeletePerksLinkRequestMutationHookResult = ReturnType<typeof useDeletePerksLinkRequestMutation>;
export type DeletePerksLinkRequestMutationResult = Apollo.MutationResult<DeletePerksLinkRequestMutation>;
export type DeletePerksLinkRequestMutationOptions = Apollo.BaseMutationOptions<DeletePerksLinkRequestMutation, DeletePerksLinkRequestMutationVariables>;
export const EndRewardCampaignDocument = gql`
    mutation endRewardCampaign($id: String!) {
  endRewardCampaign(id: $id) {
    campaignEndedSuccessfully
  }
}
    `;
export type EndRewardCampaignMutationFn = Apollo.MutationFunction<EndRewardCampaignMutation, EndRewardCampaignMutationVariables>;

/**
 * __useEndRewardCampaignMutation__
 *
 * To run a mutation, you first call `useEndRewardCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndRewardCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endRewardCampaignMutation, { data, loading, error }] = useEndRewardCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEndRewardCampaignMutation(baseOptions?: Apollo.MutationHookOptions<EndRewardCampaignMutation, EndRewardCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndRewardCampaignMutation, EndRewardCampaignMutationVariables>(EndRewardCampaignDocument, options);
      }
export type EndRewardCampaignMutationHookResult = ReturnType<typeof useEndRewardCampaignMutation>;
export type EndRewardCampaignMutationResult = Apollo.MutationResult<EndRewardCampaignMutation>;
export type EndRewardCampaignMutationOptions = Apollo.BaseMutationOptions<EndRewardCampaignMutation, EndRewardCampaignMutationVariables>;
export const EndShopLocalCampaignDocument = gql`
    mutation endShopLocalCampaign($id: String!) {
  endShopLocalCampaign(id: $id) {
    shopLocalCampaignEndedSuccessfully
  }
}
    `;
export type EndShopLocalCampaignMutationFn = Apollo.MutationFunction<EndShopLocalCampaignMutation, EndShopLocalCampaignMutationVariables>;

/**
 * __useEndShopLocalCampaignMutation__
 *
 * To run a mutation, you first call `useEndShopLocalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndShopLocalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endShopLocalCampaignMutation, { data, loading, error }] = useEndShopLocalCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEndShopLocalCampaignMutation(baseOptions?: Apollo.MutationHookOptions<EndShopLocalCampaignMutation, EndShopLocalCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndShopLocalCampaignMutation, EndShopLocalCampaignMutationVariables>(EndShopLocalCampaignDocument, options);
      }
export type EndShopLocalCampaignMutationHookResult = ReturnType<typeof useEndShopLocalCampaignMutation>;
export type EndShopLocalCampaignMutationResult = Apollo.MutationResult<EndShopLocalCampaignMutation>;
export type EndShopLocalCampaignMutationOptions = Apollo.BaseMutationOptions<EndShopLocalCampaignMutation, EndShopLocalCampaignMutationVariables>;
export const ExcludeChamberEngageActivationFromInvoiceDocument = gql`
    mutation excludeChamberEngageActivationFromInvoice($activationId: String!, $activationType: ChamberEngageActivationTypeEnum!) {
  excludeChamberEngageActivationFromInvoice(
    activationId: $activationId
    activationType: $activationType
  ) {
    excluded
  }
}
    `;
export type ExcludeChamberEngageActivationFromInvoiceMutationFn = Apollo.MutationFunction<ExcludeChamberEngageActivationFromInvoiceMutation, ExcludeChamberEngageActivationFromInvoiceMutationVariables>;

/**
 * __useExcludeChamberEngageActivationFromInvoiceMutation__
 *
 * To run a mutation, you first call `useExcludeChamberEngageActivationFromInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeChamberEngageActivationFromInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeChamberEngageActivationFromInvoiceMutation, { data, loading, error }] = useExcludeChamberEngageActivationFromInvoiceMutation({
 *   variables: {
 *      activationId: // value for 'activationId'
 *      activationType: // value for 'activationType'
 *   },
 * });
 */
export function useExcludeChamberEngageActivationFromInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ExcludeChamberEngageActivationFromInvoiceMutation, ExcludeChamberEngageActivationFromInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludeChamberEngageActivationFromInvoiceMutation, ExcludeChamberEngageActivationFromInvoiceMutationVariables>(ExcludeChamberEngageActivationFromInvoiceDocument, options);
      }
export type ExcludeChamberEngageActivationFromInvoiceMutationHookResult = ReturnType<typeof useExcludeChamberEngageActivationFromInvoiceMutation>;
export type ExcludeChamberEngageActivationFromInvoiceMutationResult = Apollo.MutationResult<ExcludeChamberEngageActivationFromInvoiceMutation>;
export type ExcludeChamberEngageActivationFromInvoiceMutationOptions = Apollo.BaseMutationOptions<ExcludeChamberEngageActivationFromInvoiceMutation, ExcludeChamberEngageActivationFromInvoiceMutationVariables>;
export const GetSupportCenterUrlDocument = gql`
    mutation GetSupportCenterUrl($nonce: String!, $state: String!) {
  getSupportCenterUrl(nonce: $nonce, state: $state)
}
    `;
export type GetSupportCenterUrlMutationFn = Apollo.MutationFunction<GetSupportCenterUrlMutation, GetSupportCenterUrlMutationVariables>;

/**
 * __useGetSupportCenterUrlMutation__
 *
 * To run a mutation, you first call `useGetSupportCenterUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSupportCenterUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSupportCenterUrlMutation, { data, loading, error }] = useGetSupportCenterUrlMutation({
 *   variables: {
 *      nonce: // value for 'nonce'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetSupportCenterUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSupportCenterUrlMutation, GetSupportCenterUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSupportCenterUrlMutation, GetSupportCenterUrlMutationVariables>(GetSupportCenterUrlDocument, options);
      }
export type GetSupportCenterUrlMutationHookResult = ReturnType<typeof useGetSupportCenterUrlMutation>;
export type GetSupportCenterUrlMutationResult = Apollo.MutationResult<GetSupportCenterUrlMutation>;
export type GetSupportCenterUrlMutationOptions = Apollo.BaseMutationOptions<GetSupportCenterUrlMutation, GetSupportCenterUrlMutationVariables>;
export const IncludeChamberEngageActivationInInvoiceDocument = gql`
    mutation includeChamberEngageActivationInInvoice($activationId: String!, $activationType: ChamberEngageActivationTypeEnum!) {
  includeChamberEngageActivationInInvoice(
    activationId: $activationId
    activationType: $activationType
  ) {
    included
  }
}
    `;
export type IncludeChamberEngageActivationInInvoiceMutationFn = Apollo.MutationFunction<IncludeChamberEngageActivationInInvoiceMutation, IncludeChamberEngageActivationInInvoiceMutationVariables>;

/**
 * __useIncludeChamberEngageActivationInInvoiceMutation__
 *
 * To run a mutation, you first call `useIncludeChamberEngageActivationInInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncludeChamberEngageActivationInInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [includeChamberEngageActivationInInvoiceMutation, { data, loading, error }] = useIncludeChamberEngageActivationInInvoiceMutation({
 *   variables: {
 *      activationId: // value for 'activationId'
 *      activationType: // value for 'activationType'
 *   },
 * });
 */
export function useIncludeChamberEngageActivationInInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<IncludeChamberEngageActivationInInvoiceMutation, IncludeChamberEngageActivationInInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncludeChamberEngageActivationInInvoiceMutation, IncludeChamberEngageActivationInInvoiceMutationVariables>(IncludeChamberEngageActivationInInvoiceDocument, options);
      }
export type IncludeChamberEngageActivationInInvoiceMutationHookResult = ReturnType<typeof useIncludeChamberEngageActivationInInvoiceMutation>;
export type IncludeChamberEngageActivationInInvoiceMutationResult = Apollo.MutationResult<IncludeChamberEngageActivationInInvoiceMutation>;
export type IncludeChamberEngageActivationInInvoiceMutationOptions = Apollo.BaseMutationOptions<IncludeChamberEngageActivationInInvoiceMutation, IncludeChamberEngageActivationInInvoiceMutationVariables>;
export const MarkChamberEngageActivationInvoicedDocument = gql`
    mutation MarkChamberEngageActivationInvoiced($activationsToMarkInvoiced: [ChamberEngageActivationToMarkInvoicedInput!]!) {
  markChamberEngageActivationInvoiced(
    activationsToMarkInvoiced: $activationsToMarkInvoiced
  ) {
    marked
  }
}
    `;
export type MarkChamberEngageActivationInvoicedMutationFn = Apollo.MutationFunction<MarkChamberEngageActivationInvoicedMutation, MarkChamberEngageActivationInvoicedMutationVariables>;

/**
 * __useMarkChamberEngageActivationInvoicedMutation__
 *
 * To run a mutation, you first call `useMarkChamberEngageActivationInvoicedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChamberEngageActivationInvoicedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChamberEngageActivationInvoicedMutation, { data, loading, error }] = useMarkChamberEngageActivationInvoicedMutation({
 *   variables: {
 *      activationsToMarkInvoiced: // value for 'activationsToMarkInvoiced'
 *   },
 * });
 */
export function useMarkChamberEngageActivationInvoicedMutation(baseOptions?: Apollo.MutationHookOptions<MarkChamberEngageActivationInvoicedMutation, MarkChamberEngageActivationInvoicedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkChamberEngageActivationInvoicedMutation, MarkChamberEngageActivationInvoicedMutationVariables>(MarkChamberEngageActivationInvoicedDocument, options);
      }
export type MarkChamberEngageActivationInvoicedMutationHookResult = ReturnType<typeof useMarkChamberEngageActivationInvoicedMutation>;
export type MarkChamberEngageActivationInvoicedMutationResult = Apollo.MutationResult<MarkChamberEngageActivationInvoicedMutation>;
export type MarkChamberEngageActivationInvoicedMutationOptions = Apollo.BaseMutationOptions<MarkChamberEngageActivationInvoicedMutation, MarkChamberEngageActivationInvoicedMutationVariables>;
export const ReactivateBannerForCategoryAdvertisingBannerDocument = gql`
    mutation reactivateBannerForCategoryAdvertisingBanner($chamberId: String, $categoryId: ChamberMemberCategory!, $reactivationInfo: CategoryAdvertisingBannerReactivationInfoInput!) {
  reactivateBannerForCategoryAdvertisingBanner(
    chamberId: $chamberId
    categoryId: $categoryId
    reactivationInfo: $reactivationInfo
  ) {
    bannerActivatedSuccessfully
  }
}
    `;
export type ReactivateBannerForCategoryAdvertisingBannerMutationFn = Apollo.MutationFunction<ReactivateBannerForCategoryAdvertisingBannerMutation, ReactivateBannerForCategoryAdvertisingBannerMutationVariables>;

/**
 * __useReactivateBannerForCategoryAdvertisingBannerMutation__
 *
 * To run a mutation, you first call `useReactivateBannerForCategoryAdvertisingBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateBannerForCategoryAdvertisingBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateBannerForCategoryAdvertisingBannerMutation, { data, loading, error }] = useReactivateBannerForCategoryAdvertisingBannerMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      categoryId: // value for 'categoryId'
 *      reactivationInfo: // value for 'reactivationInfo'
 *   },
 * });
 */
export function useReactivateBannerForCategoryAdvertisingBannerMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateBannerForCategoryAdvertisingBannerMutation, ReactivateBannerForCategoryAdvertisingBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateBannerForCategoryAdvertisingBannerMutation, ReactivateBannerForCategoryAdvertisingBannerMutationVariables>(ReactivateBannerForCategoryAdvertisingBannerDocument, options);
      }
export type ReactivateBannerForCategoryAdvertisingBannerMutationHookResult = ReturnType<typeof useReactivateBannerForCategoryAdvertisingBannerMutation>;
export type ReactivateBannerForCategoryAdvertisingBannerMutationResult = Apollo.MutationResult<ReactivateBannerForCategoryAdvertisingBannerMutation>;
export type ReactivateBannerForCategoryAdvertisingBannerMutationOptions = Apollo.BaseMutationOptions<ReactivateBannerForCategoryAdvertisingBannerMutation, ReactivateBannerForCategoryAdvertisingBannerMutationVariables>;
export const ReactivateMemberProfileAndPerksBannersForChamberMemberDocument = gql`
    mutation reactivateMemberProfileAndPerksBannersForChamberMember($chamberId: String, $chamberMemberId: String!) {
  reactivateMemberProfileAndPerksBannersForChamberMember(
    chamberId: $chamberId
    chamberMemberId: $chamberMemberId
  ) {
    activationSuccessful
  }
}
    `;
export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutationFn = Apollo.MutationFunction<ReactivateMemberProfileAndPerksBannersForChamberMemberMutation, ReactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;

/**
 * __useReactivateMemberProfileAndPerksBannersForChamberMemberMutation__
 *
 * To run a mutation, you first call `useReactivateMemberProfileAndPerksBannersForChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateMemberProfileAndPerksBannersForChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateMemberProfileAndPerksBannersForChamberMemberMutation, { data, loading, error }] = useReactivateMemberProfileAndPerksBannersForChamberMemberMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useReactivateMemberProfileAndPerksBannersForChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateMemberProfileAndPerksBannersForChamberMemberMutation, ReactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateMemberProfileAndPerksBannersForChamberMemberMutation, ReactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>(ReactivateMemberProfileAndPerksBannersForChamberMemberDocument, options);
      }
export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutationHookResult = ReturnType<typeof useReactivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutationResult = Apollo.MutationResult<ReactivateMemberProfileAndPerksBannersForChamberMemberMutation>;
export type ReactivateMemberProfileAndPerksBannersForChamberMemberMutationOptions = Apollo.BaseMutationOptions<ReactivateMemberProfileAndPerksBannersForChamberMemberMutation, ReactivateMemberProfileAndPerksBannersForChamberMemberMutationVariables>;
export const RequestPerksLinkDocument = gql`
    mutation requestPerksLink($requestingLinkToChamberId: String!) {
  requestPerksLink(requestingLinkToChamberId: $requestingLinkToChamberId) {
    perksLinkRequestedSuccessfully
  }
}
    `;
export type RequestPerksLinkMutationFn = Apollo.MutationFunction<RequestPerksLinkMutation, RequestPerksLinkMutationVariables>;

/**
 * __useRequestPerksLinkMutation__
 *
 * To run a mutation, you first call `useRequestPerksLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPerksLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPerksLinkMutation, { data, loading, error }] = useRequestPerksLinkMutation({
 *   variables: {
 *      requestingLinkToChamberId: // value for 'requestingLinkToChamberId'
 *   },
 * });
 */
export function useRequestPerksLinkMutation(baseOptions?: Apollo.MutationHookOptions<RequestPerksLinkMutation, RequestPerksLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPerksLinkMutation, RequestPerksLinkMutationVariables>(RequestPerksLinkDocument, options);
      }
export type RequestPerksLinkMutationHookResult = ReturnType<typeof useRequestPerksLinkMutation>;
export type RequestPerksLinkMutationResult = Apollo.MutationResult<RequestPerksLinkMutation>;
export type RequestPerksLinkMutationOptions = Apollo.BaseMutationOptions<RequestPerksLinkMutation, RequestPerksLinkMutationVariables>;
export const SendLoginInfoEmailToChamberMemberDocument = gql`
    mutation sendLoginInfoEmailToChamberMember($adminConsoleLoginEmail: String!, $chamberMemberId: String!, $type: LoginInfoEmailTypes!) {
  sendLoginInfoEmailToChamberMember(
    adminConsoleLoginEmail: $adminConsoleLoginEmail
    chamberMemberId: $chamberMemberId
    type: $type
  ) {
    loginInfoEmailSentSuccessfully
  }
}
    `;
export type SendLoginInfoEmailToChamberMemberMutationFn = Apollo.MutationFunction<SendLoginInfoEmailToChamberMemberMutation, SendLoginInfoEmailToChamberMemberMutationVariables>;

/**
 * __useSendLoginInfoEmailToChamberMemberMutation__
 *
 * To run a mutation, you first call `useSendLoginInfoEmailToChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLoginInfoEmailToChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLoginInfoEmailToChamberMemberMutation, { data, loading, error }] = useSendLoginInfoEmailToChamberMemberMutation({
 *   variables: {
 *      adminConsoleLoginEmail: // value for 'adminConsoleLoginEmail'
 *      chamberMemberId: // value for 'chamberMemberId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSendLoginInfoEmailToChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<SendLoginInfoEmailToChamberMemberMutation, SendLoginInfoEmailToChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendLoginInfoEmailToChamberMemberMutation, SendLoginInfoEmailToChamberMemberMutationVariables>(SendLoginInfoEmailToChamberMemberDocument, options);
      }
export type SendLoginInfoEmailToChamberMemberMutationHookResult = ReturnType<typeof useSendLoginInfoEmailToChamberMemberMutation>;
export type SendLoginInfoEmailToChamberMemberMutationResult = Apollo.MutationResult<SendLoginInfoEmailToChamberMemberMutation>;
export type SendLoginInfoEmailToChamberMemberMutationOptions = Apollo.BaseMutationOptions<SendLoginInfoEmailToChamberMemberMutation, SendLoginInfoEmailToChamberMemberMutationVariables>;
export const SetHasSignedUpForChamberMemberDocument = gql`
    mutation setHasSignedUpForChamberMember {
  setHasSignedUpForChamberMember {
    successfullySetHasSignedUp
  }
}
    `;
export type SetHasSignedUpForChamberMemberMutationFn = Apollo.MutationFunction<SetHasSignedUpForChamberMemberMutation, SetHasSignedUpForChamberMemberMutationVariables>;

/**
 * __useSetHasSignedUpForChamberMemberMutation__
 *
 * To run a mutation, you first call `useSetHasSignedUpForChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHasSignedUpForChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHasSignedUpForChamberMemberMutation, { data, loading, error }] = useSetHasSignedUpForChamberMemberMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetHasSignedUpForChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<SetHasSignedUpForChamberMemberMutation, SetHasSignedUpForChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHasSignedUpForChamberMemberMutation, SetHasSignedUpForChamberMemberMutationVariables>(SetHasSignedUpForChamberMemberDocument, options);
      }
export type SetHasSignedUpForChamberMemberMutationHookResult = ReturnType<typeof useSetHasSignedUpForChamberMemberMutation>;
export type SetHasSignedUpForChamberMemberMutationResult = Apollo.MutationResult<SetHasSignedUpForChamberMemberMutation>;
export type SetHasSignedUpForChamberMemberMutationOptions = Apollo.BaseMutationOptions<SetHasSignedUpForChamberMemberMutation, SetHasSignedUpForChamberMemberMutationVariables>;
export const SetNewTemporaryChamberMemberPasswordDocument = gql`
    mutation setNewTemporaryChamberMemberPassword($adminConsoleLoginEmail: String!, $chamberMemberId: String!) {
  setNewTemporaryChamberMemberPassword(
    adminConsoleLoginEmail: $adminConsoleLoginEmail
    chamberMemberId: $chamberMemberId
  ) {
    password
    failureReason
  }
}
    `;
export type SetNewTemporaryChamberMemberPasswordMutationFn = Apollo.MutationFunction<SetNewTemporaryChamberMemberPasswordMutation, SetNewTemporaryChamberMemberPasswordMutationVariables>;

/**
 * __useSetNewTemporaryChamberMemberPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewTemporaryChamberMemberPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewTemporaryChamberMemberPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewTemporaryChamberMemberPasswordMutation, { data, loading, error }] = useSetNewTemporaryChamberMemberPasswordMutation({
 *   variables: {
 *      adminConsoleLoginEmail: // value for 'adminConsoleLoginEmail'
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useSetNewTemporaryChamberMemberPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetNewTemporaryChamberMemberPasswordMutation, SetNewTemporaryChamberMemberPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNewTemporaryChamberMemberPasswordMutation, SetNewTemporaryChamberMemberPasswordMutationVariables>(SetNewTemporaryChamberMemberPasswordDocument, options);
      }
export type SetNewTemporaryChamberMemberPasswordMutationHookResult = ReturnType<typeof useSetNewTemporaryChamberMemberPasswordMutation>;
export type SetNewTemporaryChamberMemberPasswordMutationResult = Apollo.MutationResult<SetNewTemporaryChamberMemberPasswordMutation>;
export type SetNewTemporaryChamberMemberPasswordMutationOptions = Apollo.BaseMutationOptions<SetNewTemporaryChamberMemberPasswordMutation, SetNewTemporaryChamberMemberPasswordMutationVariables>;
export const UpdateBannerContentForCategoryAdvertisingBannerDocument = gql`
    mutation updateBannerContentForCategoryAdvertisingBanner($chamberId: String, $categoryId: ChamberMemberCategory!, $banner: [BannerInput!]) {
  updateBannerContentForCategoryAdvertisingBanner(
    chamberId: $chamberId
    categoryId: $categoryId
    banner: $banner
  ) {
    bannerActivatedSuccessfully
  }
}
    `;
export type UpdateBannerContentForCategoryAdvertisingBannerMutationFn = Apollo.MutationFunction<UpdateBannerContentForCategoryAdvertisingBannerMutation, UpdateBannerContentForCategoryAdvertisingBannerMutationVariables>;

/**
 * __useUpdateBannerContentForCategoryAdvertisingBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerContentForCategoryAdvertisingBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerContentForCategoryAdvertisingBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerContentForCategoryAdvertisingBannerMutation, { data, loading, error }] = useUpdateBannerContentForCategoryAdvertisingBannerMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      categoryId: // value for 'categoryId'
 *      banner: // value for 'banner'
 *   },
 * });
 */
export function useUpdateBannerContentForCategoryAdvertisingBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerContentForCategoryAdvertisingBannerMutation, UpdateBannerContentForCategoryAdvertisingBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerContentForCategoryAdvertisingBannerMutation, UpdateBannerContentForCategoryAdvertisingBannerMutationVariables>(UpdateBannerContentForCategoryAdvertisingBannerDocument, options);
      }
export type UpdateBannerContentForCategoryAdvertisingBannerMutationHookResult = ReturnType<typeof useUpdateBannerContentForCategoryAdvertisingBannerMutation>;
export type UpdateBannerContentForCategoryAdvertisingBannerMutationResult = Apollo.MutationResult<UpdateBannerContentForCategoryAdvertisingBannerMutation>;
export type UpdateBannerContentForCategoryAdvertisingBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerContentForCategoryAdvertisingBannerMutation, UpdateBannerContentForCategoryAdvertisingBannerMutationVariables>;
export const UpdateChamberDocument = gql`
    mutation updateChamber($id: String!, $input: ChamberInput!) {
  updateChamber(id: $id, input: $input) {
    id
    name
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    supportContactEmail
    googleMapsApi_City_ProvinceShort_CountryShort
    supportContactName
    supportLink
    phoneNumber
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    jobPostingLink
    eventsLink
    newsletterLink
    surveyLink
    advocacyWinsLink
    memberSignupLink
    sponsorLogo
    sponsorLogoLink
    logo
    numberOfMembersFromInitialSubscriptionAgreement
    description
    hideInApp
  }
}
    `;
export type UpdateChamberMutationFn = Apollo.MutationFunction<UpdateChamberMutation, UpdateChamberMutationVariables>;

/**
 * __useUpdateChamberMutation__
 *
 * To run a mutation, you first call `useUpdateChamberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChamberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChamberMutation, { data, loading, error }] = useUpdateChamberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChamberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChamberMutation, UpdateChamberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChamberMutation, UpdateChamberMutationVariables>(UpdateChamberDocument, options);
      }
export type UpdateChamberMutationHookResult = ReturnType<typeof useUpdateChamberMutation>;
export type UpdateChamberMutationResult = Apollo.MutationResult<UpdateChamberMutation>;
export type UpdateChamberMutationOptions = Apollo.BaseMutationOptions<UpdateChamberMutation, UpdateChamberMutationVariables>;
export const UpdateChamberEngageSettingsForChamberDocument = gql`
    mutation updateChamberEngageSettingsForChamber($chamberId: String!, $chamberEngageSettings: ChamberEngageSettingsInput!) {
  updateChamberEngageSettingsForChamber(
    chamberId: $chamberId
    chamberEngageSettings: $chamberEngageSettings
  ) {
    settingsUpdatedSuccessfully
  }
}
    `;
export type UpdateChamberEngageSettingsForChamberMutationFn = Apollo.MutationFunction<UpdateChamberEngageSettingsForChamberMutation, UpdateChamberEngageSettingsForChamberMutationVariables>;

/**
 * __useUpdateChamberEngageSettingsForChamberMutation__
 *
 * To run a mutation, you first call `useUpdateChamberEngageSettingsForChamberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChamberEngageSettingsForChamberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChamberEngageSettingsForChamberMutation, { data, loading, error }] = useUpdateChamberEngageSettingsForChamberMutation({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      chamberEngageSettings: // value for 'chamberEngageSettings'
 *   },
 * });
 */
export function useUpdateChamberEngageSettingsForChamberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChamberEngageSettingsForChamberMutation, UpdateChamberEngageSettingsForChamberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChamberEngageSettingsForChamberMutation, UpdateChamberEngageSettingsForChamberMutationVariables>(UpdateChamberEngageSettingsForChamberDocument, options);
      }
export type UpdateChamberEngageSettingsForChamberMutationHookResult = ReturnType<typeof useUpdateChamberEngageSettingsForChamberMutation>;
export type UpdateChamberEngageSettingsForChamberMutationResult = Apollo.MutationResult<UpdateChamberEngageSettingsForChamberMutation>;
export type UpdateChamberEngageSettingsForChamberMutationOptions = Apollo.BaseMutationOptions<UpdateChamberEngageSettingsForChamberMutation, UpdateChamberEngageSettingsForChamberMutationVariables>;
export const UpdateChamberMemberDocument = gql`
    mutation updateChamberMember($id: String!, $input: ChamberMemberInput!) {
  updateChamberMember(id: $id, input: $input) {
    id
    chamberId
    categoryId
    customContacts {
      id
      name
      email
      jobTitle
      phoneNumber
    }
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
    credentialsSentAtUtcMilli
    adminConsoleContactName
    name
    description
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    phoneNumber
    contactName
    email
    logo
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    showInApp
    isSuspended
  }
}
    `;
export type UpdateChamberMemberMutationFn = Apollo.MutationFunction<UpdateChamberMemberMutation, UpdateChamberMemberMutationVariables>;

/**
 * __useUpdateChamberMemberMutation__
 *
 * To run a mutation, you first call `useUpdateChamberMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChamberMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChamberMemberMutation, { data, loading, error }] = useUpdateChamberMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChamberMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChamberMemberMutation, UpdateChamberMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChamberMemberMutation, UpdateChamberMemberMutationVariables>(UpdateChamberMemberDocument, options);
      }
export type UpdateChamberMemberMutationHookResult = ReturnType<typeof useUpdateChamberMemberMutation>;
export type UpdateChamberMemberMutationResult = Apollo.MutationResult<UpdateChamberMemberMutation>;
export type UpdateChamberMemberMutationOptions = Apollo.BaseMutationOptions<UpdateChamberMemberMutation, UpdateChamberMemberMutationVariables>;
export const UpdateDiscountDocument = gql`
    mutation updateDiscount($id: String!, $input: DiscountInput!) {
  updateDiscount(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateDiscountMutationFn = Apollo.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;

/**
 * __useUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountMutation, { data, loading, error }] = useUpdateDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, options);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = Apollo.MutationResult<UpdateDiscountMutation>;
export type UpdateDiscountMutationOptions = Apollo.BaseMutationOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>;
export const UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationDocument = gql`
    mutation updateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivation($activationId: String!, $newActivationFeeInDollars: Float!) {
  updateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivation(
    activationId: $activationId
    newActivationFeeInDollars: $newActivationFeeInDollars
  ) {
    updated
  }
}
    `;
export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationFn = Apollo.MutationFunction<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation, UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationVariables>;

/**
 * __useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation__
 *
 * To run a mutation, you first call `useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation, { data, loading, error }] = useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation({
 *   variables: {
 *      activationId: // value for 'activationId'
 *      newActivationFeeInDollars: // value for 'newActivationFeeInDollars'
 *   },
 * });
 */
export function useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation, UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation, UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationVariables>(UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationDocument, options);
      }
export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationHookResult = ReturnType<typeof useUpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation>;
export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationResult = Apollo.MutationResult<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation>;
export type UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationOptions = Apollo.BaseMutationOptions<UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutation, UpdateMemberProfileAndPerksBannersActivationFeeOfChamberEngageActivationMutationVariables>;
export const UpdatePerkPartnerDiscountDocument = gql`
    mutation updatePerkPartnerDiscount($id: String!, $input: PerkPartnerDiscountInput!) {
  updatePerkPartnerDiscount(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdatePerkPartnerDiscountMutationFn = Apollo.MutationFunction<UpdatePerkPartnerDiscountMutation, UpdatePerkPartnerDiscountMutationVariables>;

/**
 * __useUpdatePerkPartnerDiscountMutation__
 *
 * To run a mutation, you first call `useUpdatePerkPartnerDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerkPartnerDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerkPartnerDiscountMutation, { data, loading, error }] = useUpdatePerkPartnerDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePerkPartnerDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePerkPartnerDiscountMutation, UpdatePerkPartnerDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePerkPartnerDiscountMutation, UpdatePerkPartnerDiscountMutationVariables>(UpdatePerkPartnerDiscountDocument, options);
      }
export type UpdatePerkPartnerDiscountMutationHookResult = ReturnType<typeof useUpdatePerkPartnerDiscountMutation>;
export type UpdatePerkPartnerDiscountMutationResult = Apollo.MutationResult<UpdatePerkPartnerDiscountMutation>;
export type UpdatePerkPartnerDiscountMutationOptions = Apollo.BaseMutationOptions<UpdatePerkPartnerDiscountMutation, UpdatePerkPartnerDiscountMutationVariables>;
export const UpdateRewardCampaignDocument = gql`
    mutation updateRewardCampaign($id: String!, $input: RewardCampaignInput!) {
  updateRewardCampaign(id: $id, input: $input) {
    campaignUpdatedSuccessfully
  }
}
    `;
export type UpdateRewardCampaignMutationFn = Apollo.MutationFunction<UpdateRewardCampaignMutation, UpdateRewardCampaignMutationVariables>;

/**
 * __useUpdateRewardCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateRewardCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRewardCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRewardCampaignMutation, { data, loading, error }] = useUpdateRewardCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRewardCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRewardCampaignMutation, UpdateRewardCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRewardCampaignMutation, UpdateRewardCampaignMutationVariables>(UpdateRewardCampaignDocument, options);
      }
export type UpdateRewardCampaignMutationHookResult = ReturnType<typeof useUpdateRewardCampaignMutation>;
export type UpdateRewardCampaignMutationResult = Apollo.MutationResult<UpdateRewardCampaignMutation>;
export type UpdateRewardCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateRewardCampaignMutation, UpdateRewardCampaignMutationVariables>;
export const UpdateShopLocalCampaignDocument = gql`
    mutation updateShopLocalCampaign($id: String!, $input: ShopLocalCampaignInput!) {
  updateShopLocalCampaign(id: $id, input: $input) {
    shopLocalCampaignUpdatedSuccessfully
  }
}
    `;
export type UpdateShopLocalCampaignMutationFn = Apollo.MutationFunction<UpdateShopLocalCampaignMutation, UpdateShopLocalCampaignMutationVariables>;

/**
 * __useUpdateShopLocalCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateShopLocalCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopLocalCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopLocalCampaignMutation, { data, loading, error }] = useUpdateShopLocalCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopLocalCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopLocalCampaignMutation, UpdateShopLocalCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopLocalCampaignMutation, UpdateShopLocalCampaignMutationVariables>(UpdateShopLocalCampaignDocument, options);
      }
export type UpdateShopLocalCampaignMutationHookResult = ReturnType<typeof useUpdateShopLocalCampaignMutation>;
export type UpdateShopLocalCampaignMutationResult = Apollo.MutationResult<UpdateShopLocalCampaignMutation>;
export type UpdateShopLocalCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateShopLocalCampaignMutation, UpdateShopLocalCampaignMutationVariables>;
export const GetActiveCategoryAdvertisingBannersForChamberMemberDocument = gql`
    query getActiveCategoryAdvertisingBannersForChamberMember {
  getActiveCategoryAdvertisingBannersForChamberMember {
    categoryId
    bannerSettings {
      activationInfo {
        banner {
          contentUrl
          imageLink
          contentType
          position
          waitingForApprovalByChamberAdmin
        }
        chamberMemberId
        activationStartDateUtcMilli
        chamberMemberHasAcceptedAgreement
        autoApproveBannerContentUpdate
      }
    }
  }
}
    `;

/**
 * __useGetActiveCategoryAdvertisingBannersForChamberMemberQuery__
 *
 * To run a query within a React component, call `useGetActiveCategoryAdvertisingBannersForChamberMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCategoryAdvertisingBannersForChamberMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCategoryAdvertisingBannersForChamberMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCategoryAdvertisingBannersForChamberMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveCategoryAdvertisingBannersForChamberMemberQuery, GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveCategoryAdvertisingBannersForChamberMemberQuery, GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables>(GetActiveCategoryAdvertisingBannersForChamberMemberDocument, options);
      }
export function useGetActiveCategoryAdvertisingBannersForChamberMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveCategoryAdvertisingBannersForChamberMemberQuery, GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveCategoryAdvertisingBannersForChamberMemberQuery, GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables>(GetActiveCategoryAdvertisingBannersForChamberMemberDocument, options);
        }
export type GetActiveCategoryAdvertisingBannersForChamberMemberQueryHookResult = ReturnType<typeof useGetActiveCategoryAdvertisingBannersForChamberMemberQuery>;
export type GetActiveCategoryAdvertisingBannersForChamberMemberLazyQueryHookResult = ReturnType<typeof useGetActiveCategoryAdvertisingBannersForChamberMemberLazyQuery>;
export type GetActiveCategoryAdvertisingBannersForChamberMemberQueryResult = Apollo.QueryResult<GetActiveCategoryAdvertisingBannersForChamberMemberQuery, GetActiveCategoryAdvertisingBannersForChamberMemberQueryVariables>;
export const GetActiveShopLocalCampaignForChamberMemberDocument = gql`
    query getActiveShopLocalCampaignForChamberMember {
  getActiveShopLocalCampaignForChamber {
    id
    chamberId
    campaignName
    prizeDescription
    startDateUtcMilli
    endDateUtcMilli
    participatingMembers {
      memberId
      participationCode
    }
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetActiveShopLocalCampaignForChamberMemberQuery__
 *
 * To run a query within a React component, call `useGetActiveShopLocalCampaignForChamberMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveShopLocalCampaignForChamberMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveShopLocalCampaignForChamberMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveShopLocalCampaignForChamberMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveShopLocalCampaignForChamberMemberQuery, GetActiveShopLocalCampaignForChamberMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveShopLocalCampaignForChamberMemberQuery, GetActiveShopLocalCampaignForChamberMemberQueryVariables>(GetActiveShopLocalCampaignForChamberMemberDocument, options);
      }
export function useGetActiveShopLocalCampaignForChamberMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveShopLocalCampaignForChamberMemberQuery, GetActiveShopLocalCampaignForChamberMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveShopLocalCampaignForChamberMemberQuery, GetActiveShopLocalCampaignForChamberMemberQueryVariables>(GetActiveShopLocalCampaignForChamberMemberDocument, options);
        }
export type GetActiveShopLocalCampaignForChamberMemberQueryHookResult = ReturnType<typeof useGetActiveShopLocalCampaignForChamberMemberQuery>;
export type GetActiveShopLocalCampaignForChamberMemberLazyQueryHookResult = ReturnType<typeof useGetActiveShopLocalCampaignForChamberMemberLazyQuery>;
export type GetActiveShopLocalCampaignForChamberMemberQueryResult = Apollo.QueryResult<GetActiveShopLocalCampaignForChamberMemberQuery, GetActiveShopLocalCampaignForChamberMemberQueryVariables>;
export const GetAdminConsoleLoginEmailForChamberDocument = gql`
    query getAdminConsoleLoginEmailForChamber($chamberId: String!) {
  getAdminConsoleLoginEmailForChamber(chamberId: $chamberId) {
    adminConsoleLoginEmail
  }
}
    `;

/**
 * __useGetAdminConsoleLoginEmailForChamberQuery__
 *
 * To run a query within a React component, call `useGetAdminConsoleLoginEmailForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminConsoleLoginEmailForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminConsoleLoginEmailForChamberQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetAdminConsoleLoginEmailForChamberQuery(baseOptions: Apollo.QueryHookOptions<GetAdminConsoleLoginEmailForChamberQuery, GetAdminConsoleLoginEmailForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminConsoleLoginEmailForChamberQuery, GetAdminConsoleLoginEmailForChamberQueryVariables>(GetAdminConsoleLoginEmailForChamberDocument, options);
      }
export function useGetAdminConsoleLoginEmailForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminConsoleLoginEmailForChamberQuery, GetAdminConsoleLoginEmailForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminConsoleLoginEmailForChamberQuery, GetAdminConsoleLoginEmailForChamberQueryVariables>(GetAdminConsoleLoginEmailForChamberDocument, options);
        }
export type GetAdminConsoleLoginEmailForChamberQueryHookResult = ReturnType<typeof useGetAdminConsoleLoginEmailForChamberQuery>;
export type GetAdminConsoleLoginEmailForChamberLazyQueryHookResult = ReturnType<typeof useGetAdminConsoleLoginEmailForChamberLazyQuery>;
export type GetAdminConsoleLoginEmailForChamberQueryResult = Apollo.QueryResult<GetAdminConsoleLoginEmailForChamberQuery, GetAdminConsoleLoginEmailForChamberQueryVariables>;
export const GetAdminConsoleLoginEmailForChamberMemberDocument = gql`
    query getAdminConsoleLoginEmailForChamberMember($chamberMemberId: String!) {
  getAdminConsoleLoginEmailForChamberMember(chamberMemberId: $chamberMemberId) {
    adminConsoleLoginEmail
  }
}
    `;

/**
 * __useGetAdminConsoleLoginEmailForChamberMemberQuery__
 *
 * To run a query within a React component, call `useGetAdminConsoleLoginEmailForChamberMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminConsoleLoginEmailForChamberMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminConsoleLoginEmailForChamberMemberQuery({
 *   variables: {
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useGetAdminConsoleLoginEmailForChamberMemberQuery(baseOptions: Apollo.QueryHookOptions<GetAdminConsoleLoginEmailForChamberMemberQuery, GetAdminConsoleLoginEmailForChamberMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminConsoleLoginEmailForChamberMemberQuery, GetAdminConsoleLoginEmailForChamberMemberQueryVariables>(GetAdminConsoleLoginEmailForChamberMemberDocument, options);
      }
export function useGetAdminConsoleLoginEmailForChamberMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminConsoleLoginEmailForChamberMemberQuery, GetAdminConsoleLoginEmailForChamberMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminConsoleLoginEmailForChamberMemberQuery, GetAdminConsoleLoginEmailForChamberMemberQueryVariables>(GetAdminConsoleLoginEmailForChamberMemberDocument, options);
        }
export type GetAdminConsoleLoginEmailForChamberMemberQueryHookResult = ReturnType<typeof useGetAdminConsoleLoginEmailForChamberMemberQuery>;
export type GetAdminConsoleLoginEmailForChamberMemberLazyQueryHookResult = ReturnType<typeof useGetAdminConsoleLoginEmailForChamberMemberLazyQuery>;
export type GetAdminConsoleLoginEmailForChamberMemberQueryResult = Apollo.QueryResult<GetAdminConsoleLoginEmailForChamberMemberQuery, GetAdminConsoleLoginEmailForChamberMemberQueryVariables>;
export const GetAllAdminUsersForPerkPartnerDiscountDocument = gql`
    query getAllAdminUsersForPerkPartnerDiscount($perkPartnerDiscountId: String!) {
  getAllAdminUsersForPerkPartnerDiscount(
    perkPartnerDiscountId: $perkPartnerDiscountId
  ) {
    id
    email
    perkPartnerDiscountId
    adminConsoleLoginEmail
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetAllAdminUsersForPerkPartnerDiscountQuery__
 *
 * To run a query within a React component, call `useGetAllAdminUsersForPerkPartnerDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdminUsersForPerkPartnerDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdminUsersForPerkPartnerDiscountQuery({
 *   variables: {
 *      perkPartnerDiscountId: // value for 'perkPartnerDiscountId'
 *   },
 * });
 */
export function useGetAllAdminUsersForPerkPartnerDiscountQuery(baseOptions: Apollo.QueryHookOptions<GetAllAdminUsersForPerkPartnerDiscountQuery, GetAllAdminUsersForPerkPartnerDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAdminUsersForPerkPartnerDiscountQuery, GetAllAdminUsersForPerkPartnerDiscountQueryVariables>(GetAllAdminUsersForPerkPartnerDiscountDocument, options);
      }
export function useGetAllAdminUsersForPerkPartnerDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAdminUsersForPerkPartnerDiscountQuery, GetAllAdminUsersForPerkPartnerDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAdminUsersForPerkPartnerDiscountQuery, GetAllAdminUsersForPerkPartnerDiscountQueryVariables>(GetAllAdminUsersForPerkPartnerDiscountDocument, options);
        }
export type GetAllAdminUsersForPerkPartnerDiscountQueryHookResult = ReturnType<typeof useGetAllAdminUsersForPerkPartnerDiscountQuery>;
export type GetAllAdminUsersForPerkPartnerDiscountLazyQueryHookResult = ReturnType<typeof useGetAllAdminUsersForPerkPartnerDiscountLazyQuery>;
export type GetAllAdminUsersForPerkPartnerDiscountQueryResult = Apollo.QueryResult<GetAllAdminUsersForPerkPartnerDiscountQuery, GetAllAdminUsersForPerkPartnerDiscountQueryVariables>;
export const GetAllCategoriesDocument = gql`
    query getAllCategories {
  getAllCategories {
    id
    name
  }
}
    `;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetAllCategoryAdvertisingBannersForChamberDocument = gql`
    query getAllCategoryAdvertisingBannersForChamber($chamberId: String) {
  getAllCategoryAdvertisingBannersForChamber(chamberId: $chamberId) {
    id
    chamberId
    categoryAdvertisingBanners {
      categoryId
      bannerSettings {
        activationInfo {
          banner {
            contentUrl
            imageLink
            contentType
            position
            waitingForApprovalByChamberAdmin
          }
          chamberMemberId
          bannerFeeOptionSetAtActivationTime {
            timeLengthInMonths
            bannerFeeInDollars
          }
          activationStartDateUtcMilli
          categoryAdvertisingBannersActivationFeeCpaSplitPercentageSetAtActivationTime
          autoApproveBannerContentUpdate
        }
        reactivationInfo {
          banner {
            contentUrl
            imageLink
            contentType
            position
            waitingForApprovalByChamberAdmin
          }
          chamberMemberId
          bannerFeeOptionSetAtActivationTime {
            timeLengthInMonths
            bannerFeeInDollars
          }
          activationStartDateUtcMilli
          categoryAdvertisingBannersActivationFeeCpaSplitPercentageSetAtActivationTime
        }
      }
    }
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetAllCategoryAdvertisingBannersForChamberQuery__
 *
 * To run a query within a React component, call `useGetAllCategoryAdvertisingBannersForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoryAdvertisingBannersForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoryAdvertisingBannersForChamberQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetAllCategoryAdvertisingBannersForChamberQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoryAdvertisingBannersForChamberQuery, GetAllCategoryAdvertisingBannersForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoryAdvertisingBannersForChamberQuery, GetAllCategoryAdvertisingBannersForChamberQueryVariables>(GetAllCategoryAdvertisingBannersForChamberDocument, options);
      }
export function useGetAllCategoryAdvertisingBannersForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoryAdvertisingBannersForChamberQuery, GetAllCategoryAdvertisingBannersForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoryAdvertisingBannersForChamberQuery, GetAllCategoryAdvertisingBannersForChamberQueryVariables>(GetAllCategoryAdvertisingBannersForChamberDocument, options);
        }
export type GetAllCategoryAdvertisingBannersForChamberQueryHookResult = ReturnType<typeof useGetAllCategoryAdvertisingBannersForChamberQuery>;
export type GetAllCategoryAdvertisingBannersForChamberLazyQueryHookResult = ReturnType<typeof useGetAllCategoryAdvertisingBannersForChamberLazyQuery>;
export type GetAllCategoryAdvertisingBannersForChamberQueryResult = Apollo.QueryResult<GetAllCategoryAdvertisingBannersForChamberQuery, GetAllCategoryAdvertisingBannersForChamberQueryVariables>;
export const GetAllChamberEngageInvoiceDataDocument = gql`
    query getAllChamberEngageInvoiceData {
  getAllChamberEngageInvoiceData {
    year
    monthNum
    totalActivationFeeInDollars
    allInvoicesSentInMonth
    chamberData {
      chamberId
      chamberName
      memberProfileAndPerksBannersActivated
      memberProfileAndPerksBannersTotalActivationFeeInDollars
      categoryAdvertisingActivated
      categoryAdvertisingTotalActivationFeeInDollars
      activations {
        activationId
        chamberMemberId
        chamberMemberName
        activationType
        activationLengthInMonths
        activationFeeInDollars
        includeInInvoice
        invoiceSentAtUtcMilli
        categoryAdvertisingCategoryId
      }
      allInvoicesSentForChamberInMonth
    }
  }
}
    `;

/**
 * __useGetAllChamberEngageInvoiceDataQuery__
 *
 * To run a query within a React component, call `useGetAllChamberEngageInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChamberEngageInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChamberEngageInvoiceDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllChamberEngageInvoiceDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChamberEngageInvoiceDataQuery, GetAllChamberEngageInvoiceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChamberEngageInvoiceDataQuery, GetAllChamberEngageInvoiceDataQueryVariables>(GetAllChamberEngageInvoiceDataDocument, options);
      }
export function useGetAllChamberEngageInvoiceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChamberEngageInvoiceDataQuery, GetAllChamberEngageInvoiceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChamberEngageInvoiceDataQuery, GetAllChamberEngageInvoiceDataQueryVariables>(GetAllChamberEngageInvoiceDataDocument, options);
        }
export type GetAllChamberEngageInvoiceDataQueryHookResult = ReturnType<typeof useGetAllChamberEngageInvoiceDataQuery>;
export type GetAllChamberEngageInvoiceDataLazyQueryHookResult = ReturnType<typeof useGetAllChamberEngageInvoiceDataLazyQuery>;
export type GetAllChamberEngageInvoiceDataQueryResult = Apollo.QueryResult<GetAllChamberEngageInvoiceDataQuery, GetAllChamberEngageInvoiceDataQueryVariables>;
export const GetAllChamberMembersDocument = gql`
    query getAllChamberMembers($chamberId: String!, $getHasSignedUp: Boolean, $getNumberOfUsers: Boolean) {
  getAllChamberMembers(
    chamberId: $chamberId
    getHasSignedUp: $getHasSignedUp
    getNumberOfUsers: $getNumberOfUsers
  ) {
    id
    chamberId
    categoryId
    customContacts {
      id
      name
      email
      jobTitle
      phoneNumber
    }
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
    activeMemberProfileAndPerksBannersSettings {
      activationInfo {
        startDateUtcMilli
        chamberMemberHasAcceptedAgreement
      }
    }
    credentialsSentAtUtcMilli
    adminConsoleContactName
    name
    description
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    phoneNumber
    contactName
    email
    logo
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    showInApp
    hasSignedUp
    numberOfUsers
    adminConsoleLoginEmail
    createdAtUtcMilli
    isSuspended
  }
}
    `;

/**
 * __useGetAllChamberMembersQuery__
 *
 * To run a query within a React component, call `useGetAllChamberMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChamberMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChamberMembersQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      getHasSignedUp: // value for 'getHasSignedUp'
 *      getNumberOfUsers: // value for 'getNumberOfUsers'
 *   },
 * });
 */
export function useGetAllChamberMembersQuery(baseOptions: Apollo.QueryHookOptions<GetAllChamberMembersQuery, GetAllChamberMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChamberMembersQuery, GetAllChamberMembersQueryVariables>(GetAllChamberMembersDocument, options);
      }
export function useGetAllChamberMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChamberMembersQuery, GetAllChamberMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChamberMembersQuery, GetAllChamberMembersQueryVariables>(GetAllChamberMembersDocument, options);
        }
export type GetAllChamberMembersQueryHookResult = ReturnType<typeof useGetAllChamberMembersQuery>;
export type GetAllChamberMembersLazyQueryHookResult = ReturnType<typeof useGetAllChamberMembersLazyQuery>;
export type GetAllChamberMembersQueryResult = Apollo.QueryResult<GetAllChamberMembersQuery, GetAllChamberMembersQueryVariables>;
export const GetAllChamberMembers_RootAndChamberAdminOnlyDocument = gql`
    query getAllChamberMembers_RootAndChamberAdminOnly($chamberId: String!) {
  getAllChamberMembers(chamberId: $chamberId) {
    id
    chamberId
    categoryId
    customContacts {
      id
      name
      email
      jobTitle
      phoneNumber
    }
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
    credentialsSentAtUtcMilli
    adminConsoleContactName
    name
    description
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    phoneNumber
    contactName
    email
    logo
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    showInApp
    hasSignedUp
    numberOfUsers
    adminConsoleLoginEmail
    createdAtUtcMilli
    activeMemberProfileAndPerksBannersSettings {
      activationInfo {
        startDateUtcMilli
        memberProfileAndPerkBannerActivationFeeInDollarsAtTimeOfActivation
        memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation
        chamberMemberHasAcceptedAgreement
      }
      reactivationInfo {
        startDateUtcMilli
        memberProfileAndPerkBannerActivationFeeInDollarsAtTimeOfActivation
        memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation
        chamberMemberHasAcceptedAgreement
      }
    }
    isSuspended
  }
}
    `;

/**
 * __useGetAllChamberMembers_RootAndChamberAdminOnlyQuery__
 *
 * To run a query within a React component, call `useGetAllChamberMembers_RootAndChamberAdminOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChamberMembers_RootAndChamberAdminOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChamberMembers_RootAndChamberAdminOnlyQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetAllChamberMembers_RootAndChamberAdminOnlyQuery(baseOptions: Apollo.QueryHookOptions<GetAllChamberMembers_RootAndChamberAdminOnlyQuery, GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChamberMembers_RootAndChamberAdminOnlyQuery, GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables>(GetAllChamberMembers_RootAndChamberAdminOnlyDocument, options);
      }
export function useGetAllChamberMembers_RootAndChamberAdminOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChamberMembers_RootAndChamberAdminOnlyQuery, GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChamberMembers_RootAndChamberAdminOnlyQuery, GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables>(GetAllChamberMembers_RootAndChamberAdminOnlyDocument, options);
        }
export type GetAllChamberMembers_RootAndChamberAdminOnlyQueryHookResult = ReturnType<typeof useGetAllChamberMembers_RootAndChamberAdminOnlyQuery>;
export type GetAllChamberMembers_RootAndChamberAdminOnlyLazyQueryHookResult = ReturnType<typeof useGetAllChamberMembers_RootAndChamberAdminOnlyLazyQuery>;
export type GetAllChamberMembers_RootAndChamberAdminOnlyQueryResult = Apollo.QueryResult<GetAllChamberMembers_RootAndChamberAdminOnlyQuery, GetAllChamberMembers_RootAndChamberAdminOnlyQueryVariables>;
export const GetAllChambersDocument = gql`
    query getAllChambers($getAllSandbox: Boolean) {
  getAllChambers(getAllSandbox: $getAllSandbox) {
    id
    name
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    supportContactEmail
    googleMapsApi_City_ProvinceShort_CountryShort
    supportContactName
    supportLink
    phoneNumber
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    jobPostingLink
    eventsLink
    newsletterLink
    surveyLink
    advocacyWinsLink
    memberSignupLink
    sponsorLogo
    sponsorLogoLink
    logo
    numberOfMembersFromInitialSubscriptionAgreement
    description
    createdAtUtcMilli
    subscriptionDate
    hideInApp
    isSandbox
  }
}
    `;

/**
 * __useGetAllChambersQuery__
 *
 * To run a query within a React component, call `useGetAllChambersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChambersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChambersQuery({
 *   variables: {
 *      getAllSandbox: // value for 'getAllSandbox'
 *   },
 * });
 */
export function useGetAllChambersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChambersQuery, GetAllChambersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChambersQuery, GetAllChambersQueryVariables>(GetAllChambersDocument, options);
      }
export function useGetAllChambersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChambersQuery, GetAllChambersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChambersQuery, GetAllChambersQueryVariables>(GetAllChambersDocument, options);
        }
export type GetAllChambersQueryHookResult = ReturnType<typeof useGetAllChambersQuery>;
export type GetAllChambersLazyQueryHookResult = ReturnType<typeof useGetAllChambersLazyQuery>;
export type GetAllChambersQueryResult = Apollo.QueryResult<GetAllChambersQuery, GetAllChambersQueryVariables>;
export const GetAllChambersForRootAdminChamberEngageDocument = gql`
    query getAllChambersForRootAdminChamberEngage($getAllSandbox: Boolean) {
  getAllChambers(getAllSandbox: $getAllSandbox) {
    id
    name
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    supportContactEmail
    googleMapsApi_City_ProvinceShort_CountryShort
    supportContactName
    supportLink
    phoneNumber
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    jobPostingLink
    eventsLink
    newsletterLink
    surveyLink
    advocacyWinsLink
    memberSignupLink
    sponsorLogo
    sponsorLogoLink
    logo
    numberOfMembersFromInitialSubscriptionAgreement
    description
    createdAtUtcMilli
    subscriptionDate
    chamberEngageSettings {
      isActive
      memberProfileAndPerkBannerActivationFeeInDollars
      memberProfileAndPerkBannerAnnualBannerFeeInDollars
      categoryAdvertisingBannersFeeOptions {
        timeLengthInMonths
        bannerFeeInDollars
      }
      categoryAdvertisingBannersActivationFeeCpaSplitPercentage
    }
    hideInApp
  }
}
    `;

/**
 * __useGetAllChambersForRootAdminChamberEngageQuery__
 *
 * To run a query within a React component, call `useGetAllChambersForRootAdminChamberEngageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChambersForRootAdminChamberEngageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChambersForRootAdminChamberEngageQuery({
 *   variables: {
 *      getAllSandbox: // value for 'getAllSandbox'
 *   },
 * });
 */
export function useGetAllChambersForRootAdminChamberEngageQuery(baseOptions?: Apollo.QueryHookOptions<GetAllChambersForRootAdminChamberEngageQuery, GetAllChambersForRootAdminChamberEngageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllChambersForRootAdminChamberEngageQuery, GetAllChambersForRootAdminChamberEngageQueryVariables>(GetAllChambersForRootAdminChamberEngageDocument, options);
      }
export function useGetAllChambersForRootAdminChamberEngageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllChambersForRootAdminChamberEngageQuery, GetAllChambersForRootAdminChamberEngageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllChambersForRootAdminChamberEngageQuery, GetAllChambersForRootAdminChamberEngageQueryVariables>(GetAllChambersForRootAdminChamberEngageDocument, options);
        }
export type GetAllChambersForRootAdminChamberEngageQueryHookResult = ReturnType<typeof useGetAllChambersForRootAdminChamberEngageQuery>;
export type GetAllChambersForRootAdminChamberEngageLazyQueryHookResult = ReturnType<typeof useGetAllChambersForRootAdminChamberEngageLazyQuery>;
export type GetAllChambersForRootAdminChamberEngageQueryResult = Apollo.QueryResult<GetAllChambersForRootAdminChamberEngageQuery, GetAllChambersForRootAdminChamberEngageQueryVariables>;
export const GetAllContactableCompaniesForChamberDocument = gql`
    query getAllContactableCompaniesForChamber {
  getAllContactableCompaniesForChamber {
    id
    chamberId
    chamberName
    name
    createdAtUtcMilli
  }
}
    `;

/**
 * __useGetAllContactableCompaniesForChamberQuery__
 *
 * To run a query within a React component, call `useGetAllContactableCompaniesForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContactableCompaniesForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContactableCompaniesForChamberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContactableCompaniesForChamberQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContactableCompaniesForChamberQuery, GetAllContactableCompaniesForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContactableCompaniesForChamberQuery, GetAllContactableCompaniesForChamberQueryVariables>(GetAllContactableCompaniesForChamberDocument, options);
      }
export function useGetAllContactableCompaniesForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContactableCompaniesForChamberQuery, GetAllContactableCompaniesForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContactableCompaniesForChamberQuery, GetAllContactableCompaniesForChamberQueryVariables>(GetAllContactableCompaniesForChamberDocument, options);
        }
export type GetAllContactableCompaniesForChamberQueryHookResult = ReturnType<typeof useGetAllContactableCompaniesForChamberQuery>;
export type GetAllContactableCompaniesForChamberLazyQueryHookResult = ReturnType<typeof useGetAllContactableCompaniesForChamberLazyQuery>;
export type GetAllContactableCompaniesForChamberQueryResult = Apollo.QueryResult<GetAllContactableCompaniesForChamberQuery, GetAllContactableCompaniesForChamberQueryVariables>;
export const GetAllDeletedDiscountsFromChamberIdDocument = gql`
    query getAllDeletedDiscountsFromChamberId($chamberId: String!) {
  getAllDeletedDiscountsFromChamberId(chamberId: $chamberId) {
    id
    title
    description
    chamberId
    chamberMemberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      hideInApp
    }
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      isSuspended
    }
    memberOnly
    isAvailableToAllMembers
    isReoccuring
    daysAvailable
    monthsAvailable
    isPrivate
    views
    numberOfRedemptions
    createdAtUtcMilli
    deletedAtUtcMilli
  }
}
    `;

/**
 * __useGetAllDeletedDiscountsFromChamberIdQuery__
 *
 * To run a query within a React component, call `useGetAllDeletedDiscountsFromChamberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDeletedDiscountsFromChamberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDeletedDiscountsFromChamberIdQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetAllDeletedDiscountsFromChamberIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllDeletedDiscountsFromChamberIdQuery, GetAllDeletedDiscountsFromChamberIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDeletedDiscountsFromChamberIdQuery, GetAllDeletedDiscountsFromChamberIdQueryVariables>(GetAllDeletedDiscountsFromChamberIdDocument, options);
      }
export function useGetAllDeletedDiscountsFromChamberIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDeletedDiscountsFromChamberIdQuery, GetAllDeletedDiscountsFromChamberIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDeletedDiscountsFromChamberIdQuery, GetAllDeletedDiscountsFromChamberIdQueryVariables>(GetAllDeletedDiscountsFromChamberIdDocument, options);
        }
export type GetAllDeletedDiscountsFromChamberIdQueryHookResult = ReturnType<typeof useGetAllDeletedDiscountsFromChamberIdQuery>;
export type GetAllDeletedDiscountsFromChamberIdLazyQueryHookResult = ReturnType<typeof useGetAllDeletedDiscountsFromChamberIdLazyQuery>;
export type GetAllDeletedDiscountsFromChamberIdQueryResult = Apollo.QueryResult<GetAllDeletedDiscountsFromChamberIdQuery, GetAllDeletedDiscountsFromChamberIdQueryVariables>;
export const GetAllDeletedPerkPartnerDiscountsDocument = gql`
    query getAllDeletedPerkPartnerDiscounts {
  getAllDeletedPerkPartnerDiscounts {
    id
    companyName
    title
    description
    verifiedChamberMemberCapUserRedirectLink
    unverifiedChamberMemberCapUserRedirectLink
    logo
    availableToChamberIds
    createdAtUtcMilli
    updatedAtUtcMilli
    deletedAtUtcMilli
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
  }
}
    `;

/**
 * __useGetAllDeletedPerkPartnerDiscountsQuery__
 *
 * To run a query within a React component, call `useGetAllDeletedPerkPartnerDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDeletedPerkPartnerDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDeletedPerkPartnerDiscountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDeletedPerkPartnerDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDeletedPerkPartnerDiscountsQuery, GetAllDeletedPerkPartnerDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDeletedPerkPartnerDiscountsQuery, GetAllDeletedPerkPartnerDiscountsQueryVariables>(GetAllDeletedPerkPartnerDiscountsDocument, options);
      }
export function useGetAllDeletedPerkPartnerDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDeletedPerkPartnerDiscountsQuery, GetAllDeletedPerkPartnerDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDeletedPerkPartnerDiscountsQuery, GetAllDeletedPerkPartnerDiscountsQueryVariables>(GetAllDeletedPerkPartnerDiscountsDocument, options);
        }
export type GetAllDeletedPerkPartnerDiscountsQueryHookResult = ReturnType<typeof useGetAllDeletedPerkPartnerDiscountsQuery>;
export type GetAllDeletedPerkPartnerDiscountsLazyQueryHookResult = ReturnType<typeof useGetAllDeletedPerkPartnerDiscountsLazyQuery>;
export type GetAllDeletedPerkPartnerDiscountsQueryResult = Apollo.QueryResult<GetAllDeletedPerkPartnerDiscountsQuery, GetAllDeletedPerkPartnerDiscountsQueryVariables>;
export const GetAllDiscountsFromChamberIdDocument = gql`
    query getAllDiscountsFromChamberId($chamberId: String!, $userId: String, $localDay: DayEnum, $localMonth: MonthEnum) {
  getAllDiscountsFromChamberId(
    chamberId: $chamberId
    userId: $userId
    localDay: $localDay
    localMonth: $localMonth
  ) {
    id
    title
    description
    chamberId
    chamberMemberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      hideInApp
    }
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      isSuspended
    }
    memberOnly
    isAvailableToAllMembers
    isReoccuring
    daysAvailable
    monthsAvailable
    isPrivate
    views
    numberOfRedemptions
    createdAtUtcMilli
  }
}
    `;

/**
 * __useGetAllDiscountsFromChamberIdQuery__
 *
 * To run a query within a React component, call `useGetAllDiscountsFromChamberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDiscountsFromChamberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDiscountsFromChamberIdQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *      userId: // value for 'userId'
 *      localDay: // value for 'localDay'
 *      localMonth: // value for 'localMonth'
 *   },
 * });
 */
export function useGetAllDiscountsFromChamberIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllDiscountsFromChamberIdQuery, GetAllDiscountsFromChamberIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDiscountsFromChamberIdQuery, GetAllDiscountsFromChamberIdQueryVariables>(GetAllDiscountsFromChamberIdDocument, options);
      }
export function useGetAllDiscountsFromChamberIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDiscountsFromChamberIdQuery, GetAllDiscountsFromChamberIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDiscountsFromChamberIdQuery, GetAllDiscountsFromChamberIdQueryVariables>(GetAllDiscountsFromChamberIdDocument, options);
        }
export type GetAllDiscountsFromChamberIdQueryHookResult = ReturnType<typeof useGetAllDiscountsFromChamberIdQuery>;
export type GetAllDiscountsFromChamberIdLazyQueryHookResult = ReturnType<typeof useGetAllDiscountsFromChamberIdLazyQuery>;
export type GetAllDiscountsFromChamberIdQueryResult = Apollo.QueryResult<GetAllDiscountsFromChamberIdQuery, GetAllDiscountsFromChamberIdQueryVariables>;
export const GetAllDiscountsFromChamberMemberIdDocument = gql`
    query getAllDiscountsFromChamberMemberId($chamberMemberId: String!, $userId: String, $localDay: DayEnum, $localMonth: MonthEnum) {
  getAllDiscountsFromChamberMemberId(
    chamberMemberId: $chamberMemberId
    userId: $userId
    localDay: $localDay
    localMonth: $localMonth
  ) {
    id
    title
    description
    chamberId
    chamberMemberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      hideInApp
    }
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      isSuspended
    }
    memberOnly
    isAvailableToAllMembers
    isReoccuring
    daysAvailable
    monthsAvailable
    isPrivate
    numberOfRedemptions
    views
  }
}
    `;

/**
 * __useGetAllDiscountsFromChamberMemberIdQuery__
 *
 * To run a query within a React component, call `useGetAllDiscountsFromChamberMemberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDiscountsFromChamberMemberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDiscountsFromChamberMemberIdQuery({
 *   variables: {
 *      chamberMemberId: // value for 'chamberMemberId'
 *      userId: // value for 'userId'
 *      localDay: // value for 'localDay'
 *      localMonth: // value for 'localMonth'
 *   },
 * });
 */
export function useGetAllDiscountsFromChamberMemberIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllDiscountsFromChamberMemberIdQuery, GetAllDiscountsFromChamberMemberIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDiscountsFromChamberMemberIdQuery, GetAllDiscountsFromChamberMemberIdQueryVariables>(GetAllDiscountsFromChamberMemberIdDocument, options);
      }
export function useGetAllDiscountsFromChamberMemberIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDiscountsFromChamberMemberIdQuery, GetAllDiscountsFromChamberMemberIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDiscountsFromChamberMemberIdQuery, GetAllDiscountsFromChamberMemberIdQueryVariables>(GetAllDiscountsFromChamberMemberIdDocument, options);
        }
export type GetAllDiscountsFromChamberMemberIdQueryHookResult = ReturnType<typeof useGetAllDiscountsFromChamberMemberIdQuery>;
export type GetAllDiscountsFromChamberMemberIdLazyQueryHookResult = ReturnType<typeof useGetAllDiscountsFromChamberMemberIdLazyQuery>;
export type GetAllDiscountsFromChamberMemberIdQueryResult = Apollo.QueryResult<GetAllDiscountsFromChamberMemberIdQuery, GetAllDiscountsFromChamberMemberIdQueryVariables>;
export const GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliDocument = gql`
    query getAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli($chamberId: String!) {
  getChamber(chamberId: $chamberId) {
    id
    allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli
  }
}
    `;

/**
 * __useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery__
 *
 * To run a query within a React component, call `useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery(baseOptions: Apollo.QueryHookOptions<GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery, GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery, GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables>(GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliDocument, options);
      }
export function useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery, GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery, GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables>(GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliDocument, options);
        }
export type GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryHookResult = ReturnType<typeof useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery>;
export type GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliLazyQueryHookResult = ReturnType<typeof useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliLazyQuery>;
export type GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryResult = Apollo.QueryResult<GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery, GetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQueryVariables>;
export const GetAllMemberLoginCredentialsDocument = gql`
    query getAllMemberLoginCredentials($processId: String!) {
  getAllMemberLoginCredentials(processId: $processId) {
    status
    successCSVRawTextOfAllMemberLoginCredentials
  }
}
    `;

/**
 * __useGetAllMemberLoginCredentialsQuery__
 *
 * To run a query within a React component, call `useGetAllMemberLoginCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMemberLoginCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMemberLoginCredentialsQuery({
 *   variables: {
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useGetAllMemberLoginCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetAllMemberLoginCredentialsQuery, GetAllMemberLoginCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMemberLoginCredentialsQuery, GetAllMemberLoginCredentialsQueryVariables>(GetAllMemberLoginCredentialsDocument, options);
      }
export function useGetAllMemberLoginCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMemberLoginCredentialsQuery, GetAllMemberLoginCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMemberLoginCredentialsQuery, GetAllMemberLoginCredentialsQueryVariables>(GetAllMemberLoginCredentialsDocument, options);
        }
export type GetAllMemberLoginCredentialsQueryHookResult = ReturnType<typeof useGetAllMemberLoginCredentialsQuery>;
export type GetAllMemberLoginCredentialsLazyQueryHookResult = ReturnType<typeof useGetAllMemberLoginCredentialsLazyQuery>;
export type GetAllMemberLoginCredentialsQueryResult = Apollo.QueryResult<GetAllMemberLoginCredentialsQuery, GetAllMemberLoginCredentialsQueryVariables>;
export const GetAllPerkPartnerDiscountsDocument = gql`
    query getAllPerkPartnerDiscounts {
  getAllPerkPartnerDiscounts {
    id
    companyName
    title
    description
    verifiedChamberMemberCapUserRedirectLink
    unverifiedChamberMemberCapUserRedirectLink
    logo
    availableToChamberIds
    availableToChambers {
      id
      name
    }
    createdAtUtcMilli
    updatedAtUtcMilli
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
  }
}
    `;

/**
 * __useGetAllPerkPartnerDiscountsQuery__
 *
 * To run a query within a React component, call `useGetAllPerkPartnerDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPerkPartnerDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPerkPartnerDiscountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPerkPartnerDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPerkPartnerDiscountsQuery, GetAllPerkPartnerDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPerkPartnerDiscountsQuery, GetAllPerkPartnerDiscountsQueryVariables>(GetAllPerkPartnerDiscountsDocument, options);
      }
export function useGetAllPerkPartnerDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPerkPartnerDiscountsQuery, GetAllPerkPartnerDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPerkPartnerDiscountsQuery, GetAllPerkPartnerDiscountsQueryVariables>(GetAllPerkPartnerDiscountsDocument, options);
        }
export type GetAllPerkPartnerDiscountsQueryHookResult = ReturnType<typeof useGetAllPerkPartnerDiscountsQuery>;
export type GetAllPerkPartnerDiscountsLazyQueryHookResult = ReturnType<typeof useGetAllPerkPartnerDiscountsLazyQuery>;
export type GetAllPerkPartnerDiscountsQueryResult = Apollo.QueryResult<GetAllPerkPartnerDiscountsQuery, GetAllPerkPartnerDiscountsQueryVariables>;
export const GetAllPerksLinksForChamberDocument = gql`
    query getAllPerksLinksForChamber {
  getAllPerksLinksForChamber {
    pendingOrAcceptedPerksLinkRequestInfo {
      id
      status
      requestorChamberId
      requesteeChamberId
      linkedAtTimeUtcMilli
      createdAtUtcMilli
    }
    chamberId
    chamberName
    chamberContactEmail
    chamberContactNumber
  }
}
    `;

/**
 * __useGetAllPerksLinksForChamberQuery__
 *
 * To run a query within a React component, call `useGetAllPerksLinksForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPerksLinksForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPerksLinksForChamberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPerksLinksForChamberQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPerksLinksForChamberQuery, GetAllPerksLinksForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPerksLinksForChamberQuery, GetAllPerksLinksForChamberQueryVariables>(GetAllPerksLinksForChamberDocument, options);
      }
export function useGetAllPerksLinksForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPerksLinksForChamberQuery, GetAllPerksLinksForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPerksLinksForChamberQuery, GetAllPerksLinksForChamberQueryVariables>(GetAllPerksLinksForChamberDocument, options);
        }
export type GetAllPerksLinksForChamberQueryHookResult = ReturnType<typeof useGetAllPerksLinksForChamberQuery>;
export type GetAllPerksLinksForChamberLazyQueryHookResult = ReturnType<typeof useGetAllPerksLinksForChamberLazyQuery>;
export type GetAllPerksLinksForChamberQueryResult = Apollo.QueryResult<GetAllPerksLinksForChamberQuery, GetAllPerksLinksForChamberQueryVariables>;
export const GetAllRewardCampaignsForChamberDocument = gql`
    query getAllRewardCampaignsForChamber {
  getAllRewardCampaignsForChamber {
    id
    chamberId
    sponsoringChamberMemberId
    campaignName
    prizeDescription
    startDateUtcMilli
    endDateUtcMilli
    prizeDrawParticipants {
      email
      ballotsEarned
    }
    createdAtUtcMilli
    updatedAtUtcMilli
    isCreatedByCpaAdmin
  }
}
    `;

/**
 * __useGetAllRewardCampaignsForChamberQuery__
 *
 * To run a query within a React component, call `useGetAllRewardCampaignsForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRewardCampaignsForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRewardCampaignsForChamberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRewardCampaignsForChamberQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRewardCampaignsForChamberQuery, GetAllRewardCampaignsForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRewardCampaignsForChamberQuery, GetAllRewardCampaignsForChamberQueryVariables>(GetAllRewardCampaignsForChamberDocument, options);
      }
export function useGetAllRewardCampaignsForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRewardCampaignsForChamberQuery, GetAllRewardCampaignsForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRewardCampaignsForChamberQuery, GetAllRewardCampaignsForChamberQueryVariables>(GetAllRewardCampaignsForChamberDocument, options);
        }
export type GetAllRewardCampaignsForChamberQueryHookResult = ReturnType<typeof useGetAllRewardCampaignsForChamberQuery>;
export type GetAllRewardCampaignsForChamberLazyQueryHookResult = ReturnType<typeof useGetAllRewardCampaignsForChamberLazyQuery>;
export type GetAllRewardCampaignsForChamberQueryResult = Apollo.QueryResult<GetAllRewardCampaignsForChamberQuery, GetAllRewardCampaignsForChamberQueryVariables>;
export const GetAllShopLocalCampaignsForChamberDocument = gql`
    query getAllShopLocalCampaignsForChamber {
  getAllShopLocalCampaignsForChamber {
    id
    chamberId
    campaignName
    prizeDescription
    startDateUtcMilli
    endDateUtcMilli
    participatingMemberIds
    participatingMembers {
      memberId
      participationCode
    }
    prizeDrawParticipants {
      userEmail
      redeemedAtMemberIds
    }
    status
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetAllShopLocalCampaignsForChamberQuery__
 *
 * To run a query within a React component, call `useGetAllShopLocalCampaignsForChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShopLocalCampaignsForChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShopLocalCampaignsForChamberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllShopLocalCampaignsForChamberQuery(baseOptions?: Apollo.QueryHookOptions<GetAllShopLocalCampaignsForChamberQuery, GetAllShopLocalCampaignsForChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllShopLocalCampaignsForChamberQuery, GetAllShopLocalCampaignsForChamberQueryVariables>(GetAllShopLocalCampaignsForChamberDocument, options);
      }
export function useGetAllShopLocalCampaignsForChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllShopLocalCampaignsForChamberQuery, GetAllShopLocalCampaignsForChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllShopLocalCampaignsForChamberQuery, GetAllShopLocalCampaignsForChamberQueryVariables>(GetAllShopLocalCampaignsForChamberDocument, options);
        }
export type GetAllShopLocalCampaignsForChamberQueryHookResult = ReturnType<typeof useGetAllShopLocalCampaignsForChamberQuery>;
export type GetAllShopLocalCampaignsForChamberLazyQueryHookResult = ReturnType<typeof useGetAllShopLocalCampaignsForChamberLazyQuery>;
export type GetAllShopLocalCampaignsForChamberQueryResult = Apollo.QueryResult<GetAllShopLocalCampaignsForChamberQuery, GetAllShopLocalCampaignsForChamberQueryVariables>;
export const GetChamberDocument = gql`
    query getChamber($chamberId: String!) {
  getChamber(chamberId: $chamberId) {
    id
    name
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    supportContactEmail
    googleMapsApi_City_ProvinceShort_CountryShort
    supportContactName
    supportLink
    phoneNumber
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    jobPostingLink
    eventsLink
    newsletterLink
    surveyLink
    advocacyWinsLink
    memberSignupLink
    sponsorLogo
    sponsorLogoLink
    logo
    numberOfMembersFromInitialSubscriptionAgreement
    description
    createdAtUtcMilli
    directoryBanner {
      contentUrl
      imageLink
      contentType
      position
    }
    profileBanner {
      contentUrl
      imageLink
      contentType
      position
    }
    hideInApp
  }
}
    `;

/**
 * __useGetChamberQuery__
 *
 * To run a query within a React component, call `useGetChamberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChamberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChamberQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetChamberQuery(baseOptions: Apollo.QueryHookOptions<GetChamberQuery, GetChamberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChamberQuery, GetChamberQueryVariables>(GetChamberDocument, options);
      }
export function useGetChamberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChamberQuery, GetChamberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChamberQuery, GetChamberQueryVariables>(GetChamberDocument, options);
        }
export type GetChamberQueryHookResult = ReturnType<typeof useGetChamberQuery>;
export type GetChamberLazyQueryHookResult = ReturnType<typeof useGetChamberLazyQuery>;
export type GetChamberQueryResult = Apollo.QueryResult<GetChamberQuery, GetChamberQueryVariables>;
export const GetChamberMemberDocument = gql`
    query getChamberMember($chamberMemberId: String!) {
  getChamberMember(chamberMemberId: $chamberMemberId) {
    id
    chamberId
    categoryId
    customContacts {
      id
      name
      email
      jobTitle
      phoneNumber
    }
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
    activeMemberProfileAndPerksBannersSettings {
      activationInfo {
        startDateUtcMilli
        chamberMemberHasAcceptedAgreement
      }
    }
    credentialsSentAtUtcMilli
    adminConsoleContactName
    name
    description
    website
    address {
      streetAddress
      city
      province
      postalCode
    }
    phoneNumber
    contactName
    email
    logo
    facebook
    instagram
    threads
    linkedin
    twitter
    tiktok
    youtube
    showInApp
    isSuspended
  }
}
    `;

/**
 * __useGetChamberMemberQuery__
 *
 * To run a query within a React component, call `useGetChamberMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChamberMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChamberMemberQuery({
 *   variables: {
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useGetChamberMemberQuery(baseOptions: Apollo.QueryHookOptions<GetChamberMemberQuery, GetChamberMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChamberMemberQuery, GetChamberMemberQueryVariables>(GetChamberMemberDocument, options);
      }
export function useGetChamberMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChamberMemberQuery, GetChamberMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChamberMemberQuery, GetChamberMemberQueryVariables>(GetChamberMemberDocument, options);
        }
export type GetChamberMemberQueryHookResult = ReturnType<typeof useGetChamberMemberQuery>;
export type GetChamberMemberLazyQueryHookResult = ReturnType<typeof useGetChamberMemberLazyQuery>;
export type GetChamberMemberQueryResult = Apollo.QueryResult<GetChamberMemberQuery, GetChamberMemberQueryVariables>;
export const GetDiscountDocument = gql`
    query getDiscount($discountId: String!) {
  getDiscount(discountId: $discountId) {
    id
    title
    description
    chamberId
    chamberMemberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      hideInApp
    }
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      isSuspended
    }
    memberOnly
    isReoccuring
    daysAvailable
    monthsAvailable
    isAvailableToAllMembers
    isPrivate
    privateEmails
    redeemSettings {
      whereToRedeem
      howToRedeem {
        code
        websiteToEnterCodeAt
        contactEmail
        contactNumber
        contactName
      }
    }
    expiryTimeUtcMilli
    startTimeUtcMilli
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
  }
}
    `;

/**
 * __useGetDiscountQuery__
 *
 * To run a query within a React component, call `useGetDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountQuery({
 *   variables: {
 *      discountId: // value for 'discountId'
 *   },
 * });
 */
export function useGetDiscountQuery(baseOptions: Apollo.QueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, options);
      }
export function useGetDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, options);
        }
export type GetDiscountQueryHookResult = ReturnType<typeof useGetDiscountQuery>;
export type GetDiscountLazyQueryHookResult = ReturnType<typeof useGetDiscountLazyQuery>;
export type GetDiscountQueryResult = Apollo.QueryResult<GetDiscountQuery, GetDiscountQueryVariables>;
export const GetPerkPartnerDiscountDocument = gql`
    query getPerkPartnerDiscount($id: String!) {
  getPerkPartnerDiscount(id: $id) {
    id
    companyName
    title
    description
    verifiedChamberMemberCapUserRedirectLink
    unverifiedChamberMemberCapUserRedirectLink
    logo
    availableToChamberIds
    availableToChambers {
      id
      name
    }
    createdAtUtcMilli
    updatedAtUtcMilli
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
  }
}
    `;

/**
 * __useGetPerkPartnerDiscountQuery__
 *
 * To run a query within a React component, call `useGetPerkPartnerDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerkPartnerDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerkPartnerDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerkPartnerDiscountQuery(baseOptions: Apollo.QueryHookOptions<GetPerkPartnerDiscountQuery, GetPerkPartnerDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerkPartnerDiscountQuery, GetPerkPartnerDiscountQueryVariables>(GetPerkPartnerDiscountDocument, options);
      }
export function useGetPerkPartnerDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerkPartnerDiscountQuery, GetPerkPartnerDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerkPartnerDiscountQuery, GetPerkPartnerDiscountQueryVariables>(GetPerkPartnerDiscountDocument, options);
        }
export type GetPerkPartnerDiscountQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountQuery>;
export type GetPerkPartnerDiscountLazyQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountLazyQuery>;
export type GetPerkPartnerDiscountQueryResult = Apollo.QueryResult<GetPerkPartnerDiscountQuery, GetPerkPartnerDiscountQueryVariables>;
export const GetPerkPartnerDiscountAdminUserDocument = gql`
    query getPerkPartnerDiscountAdminUser {
  getPerkPartnerDiscountAdminUser {
    id
    email
    perkPartnerDiscountId
    adminConsoleLoginEmail
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetPerkPartnerDiscountAdminUserQuery__
 *
 * To run a query within a React component, call `useGetPerkPartnerDiscountAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerkPartnerDiscountAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerkPartnerDiscountAdminUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPerkPartnerDiscountAdminUserQuery(baseOptions?: Apollo.QueryHookOptions<GetPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountAdminUserDocument, options);
      }
export function useGetPerkPartnerDiscountAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountAdminUserDocument, options);
        }
export type GetPerkPartnerDiscountAdminUserQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountAdminUserQuery>;
export type GetPerkPartnerDiscountAdminUserLazyQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountAdminUserLazyQuery>;
export type GetPerkPartnerDiscountAdminUserQueryResult = Apollo.QueryResult<GetPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountAdminUserQueryVariables>;
export const GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserDocument = gql`
    query getPerkPartnerDiscountForPerkPartnerDiscountAdminUser($id: String!) {
  getPerkPartnerDiscount(id: $id) {
    id
    companyName
    title
    description
    verifiedChamberMemberCapUserRedirectLink
    unverifiedChamberMemberCapUserRedirectLink
    logo
    createdAtUtcMilli
    updatedAtUtcMilli
    banner {
      contentUrl
      imageLink
      contentType
      position
    }
  }
}
    `;

/**
 * __useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery__
 *
 * To run a query within a React component, call `useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery(baseOptions: Apollo.QueryHookOptions<GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserDocument, options);
      }
export function useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserDocument, options);
        }
export type GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery>;
export type GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserLazyQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountForPerkPartnerDiscountAdminUserLazyQuery>;
export type GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryResult = Apollo.QueryResult<GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountForPerkPartnerDiscountAdminUserQueryVariables>;
export const GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserDocument = gql`
    query getPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUser($id: String!) {
  getPerkPartnerDiscountStats(id: $id) {
    id
    totalViewCount
    totalOpenCount
    totalOpensFromViewsConversionRatePercentage
    splitByChamber {
      chamberId
      chamberName
      totalViewCount
      totalOpenCount
    }
    timeseriesData {
      allChambers {
        id
        name
        timeseriesData {
          monthAndYear
          totalOpenCount
          totalViewCount
        }
      }
      chambers {
        id
        name
        timeseriesData {
          monthAndYear
          totalOpenCount
          totalViewCount
        }
      }
    }
  }
}
    `;

/**
 * __useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery__
 *
 * To run a query within a React component, call `useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery(baseOptions: Apollo.QueryHookOptions<GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserDocument, options);
      }
export function useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables>(GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserDocument, options);
        }
export type GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery>;
export type GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserLazyQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserLazyQuery>;
export type GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryResult = Apollo.QueryResult<GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery, GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQueryVariables>;
export const GetPerkPartnerDiscountStatsForRootAdminDocument = gql`
    query getPerkPartnerDiscountStatsForRootAdmin($id: String!) {
  getPerkPartnerDiscountStats(id: $id) {
    id
    totalViewCount
    totalIneligibleViewCount
    totalUnverifiedViewCount
    totalVerifiedViewCount
    totalOpenCount
    totalIneligibleOpenCount
    totalUnverifiedOpenCount
    totalVerifiedOpenCount
    totalOpensFromViewsConversionRatePercentage
    totalRedeemCount
    totalUnverifiedRedeemCount
    totalVerifiedRedeemCount
    totalVerifiedNoAccessToEmailRedeemCount
    totalRedeemCountFromOpensConversionRatePercentage
    splitByChamber {
      chamberId
      chamberName
      chamberIsEnabledForPerkPartnerDiscount
      totalViewCount
      ineligibleViewCount
      unverifiedViewCount
      verifiedViewCount
      totalOpenCount
      ineligibleOpenCount
      unverifiedOpenCount
      verifiedOpenCount
      totalRedeemCount
      unverifiedRedeemCount
      verifiedRedeemCount
      verifiedNoAccessToEmailRedeemCount
    }
    timeseriesData {
      allChambers {
        id
        name
        timeseriesData {
          monthAndYear
          totalOpenCount
          totalViewCount
          totalRedeemCount
        }
      }
      chambers {
        id
        name
        timeseriesData {
          monthAndYear
          totalOpenCount
          totalViewCount
          totalRedeemCount
        }
      }
    }
  }
}
    `;

/**
 * __useGetPerkPartnerDiscountStatsForRootAdminQuery__
 *
 * To run a query within a React component, call `useGetPerkPartnerDiscountStatsForRootAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerkPartnerDiscountStatsForRootAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerkPartnerDiscountStatsForRootAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPerkPartnerDiscountStatsForRootAdminQuery(baseOptions: Apollo.QueryHookOptions<GetPerkPartnerDiscountStatsForRootAdminQuery, GetPerkPartnerDiscountStatsForRootAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPerkPartnerDiscountStatsForRootAdminQuery, GetPerkPartnerDiscountStatsForRootAdminQueryVariables>(GetPerkPartnerDiscountStatsForRootAdminDocument, options);
      }
export function useGetPerkPartnerDiscountStatsForRootAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPerkPartnerDiscountStatsForRootAdminQuery, GetPerkPartnerDiscountStatsForRootAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPerkPartnerDiscountStatsForRootAdminQuery, GetPerkPartnerDiscountStatsForRootAdminQueryVariables>(GetPerkPartnerDiscountStatsForRootAdminDocument, options);
        }
export type GetPerkPartnerDiscountStatsForRootAdminQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountStatsForRootAdminQuery>;
export type GetPerkPartnerDiscountStatsForRootAdminLazyQueryHookResult = ReturnType<typeof useGetPerkPartnerDiscountStatsForRootAdminLazyQuery>;
export type GetPerkPartnerDiscountStatsForRootAdminQueryResult = Apollo.QueryResult<GetPerkPartnerDiscountStatsForRootAdminQuery, GetPerkPartnerDiscountStatsForRootAdminQueryVariables>;
export const GetPremiumChamberMarketerLinkDocument = gql`
    query getPremiumChamberMarketerLink($chamberId: String!) {
  getChamber(chamberId: $chamberId) {
    id
    premiumChamberMarketerLink
  }
}
    `;

/**
 * __useGetPremiumChamberMarketerLinkQuery__
 *
 * To run a query within a React component, call `useGetPremiumChamberMarketerLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumChamberMarketerLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumChamberMarketerLinkQuery({
 *   variables: {
 *      chamberId: // value for 'chamberId'
 *   },
 * });
 */
export function useGetPremiumChamberMarketerLinkQuery(baseOptions: Apollo.QueryHookOptions<GetPremiumChamberMarketerLinkQuery, GetPremiumChamberMarketerLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPremiumChamberMarketerLinkQuery, GetPremiumChamberMarketerLinkQueryVariables>(GetPremiumChamberMarketerLinkDocument, options);
      }
export function useGetPremiumChamberMarketerLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPremiumChamberMarketerLinkQuery, GetPremiumChamberMarketerLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPremiumChamberMarketerLinkQuery, GetPremiumChamberMarketerLinkQueryVariables>(GetPremiumChamberMarketerLinkDocument, options);
        }
export type GetPremiumChamberMarketerLinkQueryHookResult = ReturnType<typeof useGetPremiumChamberMarketerLinkQuery>;
export type GetPremiumChamberMarketerLinkLazyQueryHookResult = ReturnType<typeof useGetPremiumChamberMarketerLinkLazyQuery>;
export type GetPremiumChamberMarketerLinkQueryResult = Apollo.QueryResult<GetPremiumChamberMarketerLinkQuery, GetPremiumChamberMarketerLinkQueryVariables>;
export const GetRewardCampaignDocument = gql`
    query getRewardCampaign($id: String!) {
  getRewardCampaign(id: $id) {
    id
    chamberId
    sponsoringChamberMemberId
    campaignName
    prizeDescription
    startDateUtcMilli
    endDateUtcMilli
    prizeDrawParticipants {
      email
      ballotsEarned
    }
    createdAtUtcMilli
    updatedAtUtcMilli
    isCreatedByCpaAdmin
  }
}
    `;

/**
 * __useGetRewardCampaignQuery__
 *
 * To run a query within a React component, call `useGetRewardCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRewardCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetRewardCampaignQuery, GetRewardCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardCampaignQuery, GetRewardCampaignQueryVariables>(GetRewardCampaignDocument, options);
      }
export function useGetRewardCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardCampaignQuery, GetRewardCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardCampaignQuery, GetRewardCampaignQueryVariables>(GetRewardCampaignDocument, options);
        }
export type GetRewardCampaignQueryHookResult = ReturnType<typeof useGetRewardCampaignQuery>;
export type GetRewardCampaignLazyQueryHookResult = ReturnType<typeof useGetRewardCampaignLazyQuery>;
export type GetRewardCampaignQueryResult = Apollo.QueryResult<GetRewardCampaignQuery, GetRewardCampaignQueryVariables>;
export const GetRootAdminStatsDocument = gql`
    query GetRootAdminStats {
  getRootAdminStats {
    totalChambers
    totalChamberMembers
    totalSuspendedChamberMembers
    totalDiscountViews
    totalDiscountRedemptions
    numberOfChamberMembersOfferingLiveDiscounts
    percentOfChamberMembersOfferingLiveDiscounts
    totalNumberOfLiveDiscounts
    totalNumberOfLiveAllMemberDiscounts
    totalNumberOfLiveLocalMemberOnlyDiscounts
    totalNumberOfLiveGeneralPublicDiscounts
    totalNumberOfLiveCompanyEmployeesOnlyDiscounts
    totalNumberOfAppUsers
    totalNumberOfAppUsersSignedUpWithAnEmployer
    totalNumberOfAppUsersSignedUpWithoutAnEmployer
    totalNumberOfAppUsersWithNewPerkNotificationEnabled
    totalPercentageOfAppUsersWithNewPerkNotificationEnabled
    totalNumberOfAppUsersWithNearbyPerkNotificationEnabled
    totalPercentageOfAppUsersWithNearbyPerkNotificationEnabled
    timeseriesAppUsersData {
      monthly {
        monthAndYear
        numberOfAppUsers
      }
    }
    chambersSortedByNewest {
      name
      subscriptionDate
      subscriptionUTCMilli
      numberOfAppUsers
      numberOfAppUsersSignedUpWithAnEmployer
      numberOfAppUsersSignedUpWithoutAnEmployer
      numberOfLiveDiscounts
      numberOfDiscountViews
      numberOfDiscountRedemptions
      numberOfChamberMembersLiveOnApp
      numberOfSuspendedChamberMembers
      numberOfMembersFromInitialSubscriptionAgreement
      numberChangeInNumberOfChamberMembersBetweenNowAndInitial
      percentageChangeInNumberOfChamberMembersBetweenNowAndInitial
      numberOfChamberMembersLoggedInToAdminPortal
      percentageOfChamberMembersLoggedInToAdminPortal
      numberOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin
      percentageOfChamberMembersThatWereEmailedTheirCredentialsBeforeFirstLogin
      numberOfChamberMembersOfferingLiveDiscounts
      percentageOfChamberMembersOfferingLiveDiscounts
      discountsPerCategoryBreakdown {
        categoryName
        numberOfDiscounts
        percentageVersusOtherCategories
      }
      numberOfAppUsersWithNewPerkNotificationEnabled
      percentageOfAppUsersWithNewPerkNotificationEnabled
      numberOfAppUsersWithNearbyPerkNotificationEnabled
      percentageOfAppUsersWithNearbyPerkNotificationEnabled
    }
    newestChamberMembers {
      name
      chamberName
      membershipStatus
      category1
      category2
      numberOfAppUsersWithThisMemberSetAsTheirEmployer
      numberOfDeletedAndNonDeletedDiscounts
      hasLoggedInToAdminPortal
      hasLogo
      createdAtUtcMilli
      createdAtUtcTimestamp
    }
    newestDiscounts {
      title
      chamberName
      chamberMemberName
      offeredTo
      views
      numberOfRedemptions
      category1
      category2
      createdAtUtcMilli
      createdAtUtcTimestamp
      deletedAtUtcMilli
      deletedAtUtcTimestamp
    }
    discountsPerCategoryBreakdown {
      categoryName
      numberOfDiscounts
      percentageVersusOtherCategories
    }
    contactableChambersSortedByNewest {
      id
      name
      createdAtUtcMilli
      createdAtUtcTimestamp
    }
    contactableCompaniesSortedByNewest {
      id
      chamberId
      chamberName
      name
      createdAtUtcMilli
      createdAtUtcTimestamp
    }
  }
}
    `;

/**
 * __useGetRootAdminStatsQuery__
 *
 * To run a query within a React component, call `useGetRootAdminStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootAdminStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootAdminStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRootAdminStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetRootAdminStatsQuery, GetRootAdminStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRootAdminStatsQuery, GetRootAdminStatsQueryVariables>(GetRootAdminStatsDocument, options);
      }
export function useGetRootAdminStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRootAdminStatsQuery, GetRootAdminStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRootAdminStatsQuery, GetRootAdminStatsQueryVariables>(GetRootAdminStatsDocument, options);
        }
export type GetRootAdminStatsQueryHookResult = ReturnType<typeof useGetRootAdminStatsQuery>;
export type GetRootAdminStatsLazyQueryHookResult = ReturnType<typeof useGetRootAdminStatsLazyQuery>;
export type GetRootAdminStatsQueryResult = Apollo.QueryResult<GetRootAdminStatsQuery, GetRootAdminStatsQueryVariables>;
export const GetSampleLoginInfoEmailDocument = gql`
    query getSampleLoginInfoEmail($type: LoginInfoEmailTypes!, $chamberMemberId: String) {
  getSampleLoginInfoEmail(type: $type, chamberMemberId: $chamberMemberId) {
    emailHtml
  }
}
    `;

/**
 * __useGetSampleLoginInfoEmailQuery__
 *
 * To run a query within a React component, call `useGetSampleLoginInfoEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleLoginInfoEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleLoginInfoEmailQuery({
 *   variables: {
 *      type: // value for 'type'
 *      chamberMemberId: // value for 'chamberMemberId'
 *   },
 * });
 */
export function useGetSampleLoginInfoEmailQuery(baseOptions: Apollo.QueryHookOptions<GetSampleLoginInfoEmailQuery, GetSampleLoginInfoEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSampleLoginInfoEmailQuery, GetSampleLoginInfoEmailQueryVariables>(GetSampleLoginInfoEmailDocument, options);
      }
export function useGetSampleLoginInfoEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSampleLoginInfoEmailQuery, GetSampleLoginInfoEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSampleLoginInfoEmailQuery, GetSampleLoginInfoEmailQueryVariables>(GetSampleLoginInfoEmailDocument, options);
        }
export type GetSampleLoginInfoEmailQueryHookResult = ReturnType<typeof useGetSampleLoginInfoEmailQuery>;
export type GetSampleLoginInfoEmailLazyQueryHookResult = ReturnType<typeof useGetSampleLoginInfoEmailLazyQuery>;
export type GetSampleLoginInfoEmailQueryResult = Apollo.QueryResult<GetSampleLoginInfoEmailQuery, GetSampleLoginInfoEmailQueryVariables>;
export const GetSampleMemberLoginCredentialsDocument = gql`
    query getSampleMemberLoginCredentials {
  getSampleMemberLoginCredentials {
    sampleCSVRawTextOfAllMemberLoginCredentials
  }
}
    `;

/**
 * __useGetSampleMemberLoginCredentialsQuery__
 *
 * To run a query within a React component, call `useGetSampleMemberLoginCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleMemberLoginCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleMemberLoginCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSampleMemberLoginCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<GetSampleMemberLoginCredentialsQuery, GetSampleMemberLoginCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSampleMemberLoginCredentialsQuery, GetSampleMemberLoginCredentialsQueryVariables>(GetSampleMemberLoginCredentialsDocument, options);
      }
export function useGetSampleMemberLoginCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSampleMemberLoginCredentialsQuery, GetSampleMemberLoginCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSampleMemberLoginCredentialsQuery, GetSampleMemberLoginCredentialsQueryVariables>(GetSampleMemberLoginCredentialsDocument, options);
        }
export type GetSampleMemberLoginCredentialsQueryHookResult = ReturnType<typeof useGetSampleMemberLoginCredentialsQuery>;
export type GetSampleMemberLoginCredentialsLazyQueryHookResult = ReturnType<typeof useGetSampleMemberLoginCredentialsLazyQuery>;
export type GetSampleMemberLoginCredentialsQueryResult = Apollo.QueryResult<GetSampleMemberLoginCredentialsQuery, GetSampleMemberLoginCredentialsQueryVariables>;
export const GetShopLocalCampaignDocument = gql`
    query getShopLocalCampaign($id: String!) {
  getShopLocalCampaign(id: $id) {
    id
    chamberId
    campaignName
    prizeDescription
    startDateUtcMilli
    endDateUtcMilli
    participatingMemberIds
    participatingMembers {
      memberId
      participationCode
    }
    prizeDrawParticipants {
      userEmail
      redeemedAtMemberIds
    }
    status
    createdAtUtcMilli
    updatedAtUtcMilli
  }
}
    `;

/**
 * __useGetShopLocalCampaignQuery__
 *
 * To run a query within a React component, call `useGetShopLocalCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopLocalCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopLocalCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShopLocalCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetShopLocalCampaignQuery, GetShopLocalCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopLocalCampaignQuery, GetShopLocalCampaignQueryVariables>(GetShopLocalCampaignDocument, options);
      }
export function useGetShopLocalCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopLocalCampaignQuery, GetShopLocalCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopLocalCampaignQuery, GetShopLocalCampaignQueryVariables>(GetShopLocalCampaignDocument, options);
        }
export type GetShopLocalCampaignQueryHookResult = ReturnType<typeof useGetShopLocalCampaignQuery>;
export type GetShopLocalCampaignLazyQueryHookResult = ReturnType<typeof useGetShopLocalCampaignLazyQuery>;
export type GetShopLocalCampaignQueryResult = Apollo.QueryResult<GetShopLocalCampaignQuery, GetShopLocalCampaignQueryVariables>;
export const GetSystemWidePerkStatsDocument = gql`
    query getSystemWidePerkStats {
  getSystemWidePerkStats {
    totalActivePerks
    numberOfMembersOfferingPerks
    percentOfMembersOfferingPerks
    totalPerkViews
    numOfAllMemberPerks
    numOfLocalMemberOnlyPerks
    numOfGeneralPublicPerks
    numOfCompanyEmployeesOnlyPerks
    totalNumOfMembers
    totalNumOfChambers
    totalLifeTimeCategoryAdvertisingBannersRevenueForCurrentChamber
    totalLifeTimeRevenueFromMemberProfileAndPerksBannersForCurrentChamber
    totalNumOfMembersWithActiveMemberProfileAndPerksBannersForCurrentChamber
    totalNumOfMembersPerCategoryWithActiveMemberProfileAndPerksBannersForCurrentChamber {
      categoryId
      totalNumOfMembers
    }
  }
}
    `;

/**
 * __useGetSystemWidePerkStatsQuery__
 *
 * To run a query within a React component, call `useGetSystemWidePerkStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemWidePerkStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemWidePerkStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemWidePerkStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemWidePerkStatsQuery, GetSystemWidePerkStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemWidePerkStatsQuery, GetSystemWidePerkStatsQueryVariables>(GetSystemWidePerkStatsDocument, options);
      }
export function useGetSystemWidePerkStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemWidePerkStatsQuery, GetSystemWidePerkStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemWidePerkStatsQuery, GetSystemWidePerkStatsQueryVariables>(GetSystemWidePerkStatsDocument, options);
        }
export type GetSystemWidePerkStatsQueryHookResult = ReturnType<typeof useGetSystemWidePerkStatsQuery>;
export type GetSystemWidePerkStatsLazyQueryHookResult = ReturnType<typeof useGetSystemWidePerkStatsLazyQuery>;
export type GetSystemWidePerkStatsQueryResult = Apollo.QueryResult<GetSystemWidePerkStatsQuery, GetSystemWidePerkStatsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($userId: String!) {
  getUser(userId: $userId) {
    id
    email
    chamberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      isSandbox
      directoryBanner {
        contentUrl
        imageLink
        contentType
        position
      }
      profileBanner {
        contentUrl
        imageLink
        contentType
        position
      }
      chamberEngageSettings {
        isActive
      }
      hideInApp
    }
    chamberMemberId
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      hasSignedUp
      isSuspended
    }
    marketingEmailAccepted
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUser_RootAdminChamberAdminDocument = gql`
    query getUser_RootAdminChamberAdmin($userId: String!) {
  getUser(userId: $userId) {
    id
    email
    chamberId
    chamberInfo {
      id
      name
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      supportContactEmail
      googleMapsApi_City_ProvinceShort_CountryShort
      supportContactName
      supportLink
      phoneNumber
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      jobPostingLink
      eventsLink
      newsletterLink
      surveyLink
      advocacyWinsLink
      memberSignupLink
      sponsorLogo
      sponsorLogoLink
      description
      logo
      isSandbox
      directoryBanner {
        contentUrl
        imageLink
        contentType
        position
      }
      profileBanner {
        contentUrl
        imageLink
        contentType
        position
      }
      chamberEngageSettings {
        isActive
        memberProfileAndPerkBannerAnnualBannerFeeInDollars
        categoryAdvertisingBannersFeeOptions {
          timeLengthInMonths
          bannerFeeInDollars
        }
      }
      hideInApp
    }
    chamberMemberId
    chamberMemberInfo {
      id
      chamberId
      categoryId
      customContacts {
        id
        name
        email
        jobTitle
        phoneNumber
      }
      banner {
        contentUrl
        imageLink
        contentType
        position
      }
      activeMemberProfileAndPerksBannersSettings {
        activationInfo {
          startDateUtcMilli
          chamberMemberHasAcceptedAgreement
        }
      }
      credentialsSentAtUtcMilli
      adminConsoleContactName
      name
      description
      website
      address {
        streetAddress
        city
        province
        postalCode
      }
      phoneNumber
      contactName
      email
      logo
      facebook
      instagram
      threads
      linkedin
      twitter
      tiktok
      youtube
      showInApp
      hasSignedUp
      isSuspended
    }
    marketingEmailAccepted
  }
}
    `;

/**
 * __useGetUser_RootAdminChamberAdminQuery__
 *
 * To run a query within a React component, call `useGetUser_RootAdminChamberAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUser_RootAdminChamberAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUser_RootAdminChamberAdminQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUser_RootAdminChamberAdminQuery(baseOptions: Apollo.QueryHookOptions<GetUser_RootAdminChamberAdminQuery, GetUser_RootAdminChamberAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUser_RootAdminChamberAdminQuery, GetUser_RootAdminChamberAdminQueryVariables>(GetUser_RootAdminChamberAdminDocument, options);
      }
export function useGetUser_RootAdminChamberAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUser_RootAdminChamberAdminQuery, GetUser_RootAdminChamberAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUser_RootAdminChamberAdminQuery, GetUser_RootAdminChamberAdminQueryVariables>(GetUser_RootAdminChamberAdminDocument, options);
        }
export type GetUser_RootAdminChamberAdminQueryHookResult = ReturnType<typeof useGetUser_RootAdminChamberAdminQuery>;
export type GetUser_RootAdminChamberAdminLazyQueryHookResult = ReturnType<typeof useGetUser_RootAdminChamberAdminLazyQuery>;
export type GetUser_RootAdminChamberAdminQueryResult = Apollo.QueryResult<GetUser_RootAdminChamberAdminQuery, GetUser_RootAdminChamberAdminQueryVariables>;