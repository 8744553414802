import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery,
  GetPerkPartnerDiscountStatsForRootAdminQuery,
  UserType,
} from "../../../graphql/types";

type Order = "asc" | "desc";

interface HeadCell {
  id: string;
  label: string;
}

const formatMonthYear = (monthYear: string) => {
  const [year, month] = monthYear.split("-");
  const date = new Date(parseInt(year), parseInt(month) - 1);
  return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
};

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | undefined },
  b: { [key in Key]: number | string | undefined }
) => number {
  return order === "desc"
    ? (a, b) => {
        if (orderBy === "name") {
          if (a[orderBy] === undefined || b[orderBy] === undefined) return 0;
          if (b[orderBy] < a[orderBy]!) return -1;
          if (b[orderBy] > a[orderBy]!) return 1;
          return 0;
        }

        // For non-name columns, if either value is undefined, put them at the end
        if (a[orderBy] === undefined && b[orderBy] === undefined) return 0;
        if (a[orderBy] === undefined) return 1;
        if (b[orderBy] === undefined) return -1;

        if (b[orderBy]! < a[orderBy]!) return -1;
        if (b[orderBy]! > a[orderBy]!) return 1;
        return 0;
      }
    : (a, b) => {
        if (orderBy === "name") {
          if (a[orderBy] === undefined || b[orderBy] === undefined) return 0;
          if (b[orderBy] < a[orderBy]!) return 1;
          if (b[orderBy] > a[orderBy]!) return -1;
          return 0;
        }

        // For non-name columns, if either value is undefined, put them at the end
        if (a[orderBy] === undefined && b[orderBy] === undefined) return 0;
        if (a[orderBy] === undefined) return 1;
        if (b[orderBy] === undefined) return -1;

        if (b[orderBy]! < a[orderBy]!) return 1;
        if (b[orderBy]! > a[orderBy]!) return -1;
        return 0;
      };
}

interface TableHeaderProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  userType: UserType | "";
}

function TimeseriesTableHeader(props: TableHeaderProps) {
  const { order, orderBy, onRequestSort, headCells, userType } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  // Group headers by month/year
  const monthGroups = new Map<string, HeadCell[]>();
  headCells.forEach((cell) => {
    if (cell.id === "name") return;
    const monthYear = cell.label.split(" ")[0] + " " + cell.label.split(" ")[1];
    if (!monthGroups.has(monthYear)) {
      monthGroups.set(monthYear, []);
    }
    monthGroups.get(monthYear)?.push(cell);
  });

  const getBorderStyle = (headCell: HeadCell) => {
    if (headCell.id === "name") return "1px solid rgba(224, 224, 224, 1)";
    if (headCell.id.includes("Opens")) {
      return userType === UserType.RootAdmin
        ? "none"
        : "1px solid rgba(224, 224, 224, 1)";
    }
    if (headCell.id.includes("Views")) return "none";
    return "1px solid rgba(224, 224, 224, 1)";
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          rowSpan={2}
          align="left"
          style={{
            fontWeight: 700,
            fontSize: "18px",
            padding: "8px",
            position: "sticky",
            left: 0,
            zIndex: 10,
            backgroundColor: "white",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <TableSortLabel
            active={orderBy === "name"}
            direction={orderBy === "name" ? order : "desc"}
            onClick={createSortHandler("name")}
          >
            Chamber
            {orderBy === "name" ? (
              <Box component="span" sx={{ ...visuallyHidden }}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {Array.from(monthGroups.entries()).map(([monthYear]) => (
          <TableCell
            key={monthYear}
            colSpan={userType === UserType.RootAdmin ? 3 : 2}
            align="center"
            style={{
              fontWeight: 700,
              fontSize: "18px",
              padding: "8px",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "none",
              position: "sticky",
              top: 0,
              zIndex: 4,
              backgroundColor: "white",
            }}
          >
            {monthYear}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {Array.from(monthGroups.values()).flatMap((cells) =>
          cells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              style={{
                fontWeight: 700,
                fontSize: "18px",
                padding: "8px",
                paddingTop: 0,
                borderRight: getBorderStyle(headCell),
                borderLeft: headCell.id.includes("Opens") ? "none" : undefined,
                position: "sticky",
                top: "40px", // Height of the month/year row
                zIndex: 4,
                backgroundColor: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id
                    ? order
                    : headCell.id === "name"
                    ? "asc"
                    : "desc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label.split(" ")[2]}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))
        )}
      </TableRow>
    </TableHead>
  );
}

interface PerkPartnerTimeseriesTableProps {
  timeseriesData:
    | GetPerkPartnerDiscountStatsForRootAdminQuery["getPerkPartnerDiscountStats"]["timeseriesData"]
    | GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery["getPerkPartnerDiscountStats"]["timeseriesData"]
    | undefined
    | null;
  userType: UserType | "";
}

export const PerkPartnerTimeseriesTable: React.FC<
  PerkPartnerTimeseriesTableProps
> = ({ timeseriesData, userType }) => {
  // Get the most recent month's Views column for initial sort
  const getInitialSortColumn = () => {
    if (!timeseriesData?.allChambers?.timeseriesData) return "name";

    const monthYears = timeseriesData.allChambers.timeseriesData.map(
      (point) => ({
        original: formatMonthYear(point.monthAndYear),
        timestamp: new Date(point.monthAndYear).getTime(),
      })
    );

    const latestMonth = monthYears.sort((a, b) => b.timestamp - a.timestamp)[0]
      ?.original;
    return latestMonth ? `${latestMonth} Views` : "name";
  };

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(getInitialSortColumn());
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  // Add useEffect to scroll to the right on mount
  React.useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft =
        tableContainerRef.current.scrollWidth;
    }
  }, [timeseriesData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder(property === "name" ? "asc" : "desc");
    } else {
      setOrder(isAsc ? "desc" : "asc");
    }
    setOrderBy(property);
    // Scroll to top when sort changes with smooth animation
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const headCells: HeadCell[] = [{ id: "name", label: "Chamber" }];
  if (timeseriesData?.allChambers?.timeseriesData) {
    // Create a Set to track unique month/years to avoid duplicates
    const uniqueMonthYears = new Set(
      timeseriesData.allChambers.timeseriesData.map((point) =>
        formatMonthYear(point.monthAndYear)
      )
    );

    // Add headers for each unique month/year
    Array.from(uniqueMonthYears).forEach((monthYear) => {
      headCells.push({ id: `${monthYear} Views`, label: `${monthYear} Views` });
      headCells.push({ id: `${monthYear} Opens`, label: `${monthYear} Opens` });
      if (userType === UserType.RootAdmin) {
        headCells.push({
          id: `${monthYear} Redirects`,
          label: `${monthYear} Redirects`,
        });
      }
    });
  }

  const visibleRows = React.useMemo(() => {
    const tableData =
      timeseriesData?.chambers?.map((chamber) => {
        const rowData: Record<string, any> = {
          name: chamber.name,
        };

        chamber.timeseriesData.forEach((point) => {
          const monthYear = formatMonthYear(point.monthAndYear);
          rowData[`${monthYear} Views`] = point.totalViewCount;
          rowData[`${monthYear} Opens`] = point.totalOpenCount;
          if (userType === UserType.RootAdmin) {
            rowData[`${monthYear} Redirects`] = point.totalRedeemCount;
          }
        });

        return rowData;
      }) || [];

    return stableSort(tableData, getComparator(order, orderBy));
  }, [timeseriesData, order, orderBy, userType]);

  const getBorderStyle = (headCell: HeadCell) => {
    if (headCell.id === "name") return "1px solid rgba(224, 224, 224, 1)";
    if (headCell.id.includes("Opens")) return "none";
    if (headCell.id.includes("Views")) return "none";
    return "1px solid rgba(224, 224, 224, 1)";
  };

  const getCellStyle = (
    headCell: HeadCell,
    isFirstColumn: boolean,
    isHeader: boolean = false
  ) => ({
    fontWeight: isHeader ? 700 : 400,
    fontSize: isHeader ? "18px" : "15px",
    padding: "8px",
    position: isFirstColumn ? ("sticky" as const) : undefined,
    left: isFirstColumn ? 0 : undefined,
    zIndex: isFirstColumn ? 5 : undefined,
    backgroundColor: isFirstColumn ? "white" : undefined,
    borderRight: getBorderStyle(headCell),
    borderLeft: headCell.id.includes("Opens") ? "none" : undefined,
  });

  if (!timeseriesData?.chambers) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          borderLeft: "1px solid rgba(224, 224, 224, 1)",
          borderRight: "1px solid rgba(224, 224, 224, 1)",
          borderBottom: 0,
          borderRadius: 1,
        }}
        elevation={0}
      >
        <TableContainer
          sx={{
            maxHeight: 440,
            scrollBehavior: "smooth",
          }}
          ref={tableContainerRef}
        >
          <Table stickyHeader aria-label="sticky table" size="medium">
            <TimeseriesTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              userType={userType}
            />
            <TableBody>
              {visibleRows.map((row, index) => (
                <TableRow hover key={index}>
                  {headCells.map((headCell, cellIndex) => (
                    <TableCell
                      key={headCell.id}
                      align={cellIndex === 0 ? "left" : "center"}
                      style={{
                        ...getCellStyle(headCell, cellIndex === 0),
                        borderRight:
                          headCell.id.includes("Opens") &&
                          userType !== UserType.RootAdmin
                            ? "1px solid rgba(224, 224, 224, 1)"
                            : getBorderStyle(headCell),
                      }}
                    >
                      {row[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default PerkPartnerTimeseriesTable;
