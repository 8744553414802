import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { Maybe, useGetAllChambersQuery } from "../../../graphql/types";
import Loader from "../Loader/Loader";
import { TextField, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../../themes";
import enumStrings from "../../enumStrings";

type ChamberDataObj = {
  id: string;
  name: string;
  province?: string;
};

type ProvinceGroup = {
  code: string;
  full: string;
  country: string;
  chambers: ChamberDataObj[];
};

const useStyles = makeStyles(() => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0px",
    marginTop: "10px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  provinceHeader: {
    backgroundColor: "#f5f5f5",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  provinceTitle: {
    margin: 0,
    fontSize: "0.94rem",
    fontWeight: 500,
  },
}));

export default function ChooseChambers({
  selectedChamberIds,
  handleChambersChange,
}: {
  selectedChamberIds: Maybe<string[]> | undefined;
  handleChambersChange: (e: string[]) => void;
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState<readonly ChamberDataObj[]>([]);
  const [fullList, setFullList] = useState<readonly ChamberDataObj[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleToggle =
    (newCheckState: boolean, value: ChamberDataObj) => () => {
      const newChecked = [...checked];

      if (newCheckState) {
        newChecked.push(value);
      } else {
        const currentIndex = checked.findIndex((obj) => obj.id === value.id);
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
      handleChambersChange(newChecked.map((chamber) => chamber.id));
    };

  const handleToggleProvince =
    (provinceCode: string, chambers: ChamberDataObj[]) => () => {
      const provinceChamberIds = new Set(chambers.map((c) => c.id));
      const isProvinceFullySelected = chambers.every((chamber) =>
        checked.some((c) => c.id === chamber.id)
      );

      let newChecked;
      if (isProvinceFullySelected) {
        // Remove all chambers from this province
        newChecked = checked.filter(
          (chamber) => !provinceChamberIds.has(chamber.id)
        );
      } else {
        // Add all chambers from this province that aren't already selected
        const existingCheckedIds = new Set(checked.map((c) => c.id));
        newChecked = [
          ...checked,
          ...chambers.filter((chamber) => !existingCheckedIds.has(chamber.id)),
        ];
      }

      setChecked(newChecked);
      handleChambersChange(newChecked.map((chamber) => chamber.id));
    };

  const handleToggleAll = (items: readonly ChamberDataObj[]) => () => {
    if (checked.length === items.length) {
      setChecked([]);
      handleChambersChange([]);
    } else {
      setChecked(fullList);
      handleChambersChange(fullList.map((chamber) => chamber.id));
    }
  };

  const { data } = useGetAllChambersQuery({
    variables: {
      getAllSandbox: true,
    },
  });

  const sandboxChamberIds = useMemo(() => {
    return (
      data?.getAllChambers
        ?.filter((chamber) => chamber.isSandbox)
        .map((chamber) => chamber.id) || []
    );
  }, [data?.getAllChambers]);

  useEffect(() => {
    const allChamberSelectableArray = (data?.getAllChambers || [])
      .filter((chamber) => !chamber.hideInApp)
      .map(({ id, name, address }) => ({
        id,
        name,
        province: address?.province,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    if (selectedChamberIds && selectedChamberIds.length > 0) {
      setChecked(
        (allChamberSelectableArray || []).filter((chamber) =>
          selectedChamberIds.includes(chamber.id)
        ) || []
      );
      setFullList(allChamberSelectableArray || []);
    } else {
      setChecked([]);
      setFullList(allChamberSelectableArray || []);
    }
    setIsLoading(false);
  }, [data?.getAllChambers, selectedChamberIds]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const groupedByProvince = useMemo(() => {
    const provinceMap = new Map<string, ProvinceGroup>();

    // Initialize provinces from enumStrings
    enumStrings.provinceDropDownSelect.dropdownOptions.forEach((province) => {
      provinceMap.set(province.code, {
        ...province,
        chambers: [],
      });
    });

    // Group chambers by province
    const filteredList = fullList.filter((chamber) =>
      chamber.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    filteredList.forEach((chamber) => {
      const provinceCode = chamber.province || "Unknown";
      const provinceGroup = provinceMap.get(provinceCode) || {
        code: provinceCode,
        full: provinceCode,
        country: "Unknown",
        chambers: [],
      };
      provinceGroup.chambers.push(chamber);
    });

    // Filter out provinces with no chambers
    return Array.from(provinceMap.values())
      .filter((group) => group.chambers.length > 0)
      .sort((a, b) => a.full.localeCompare(b.full));
  }, [fullList, searchInput]);

  const checkItems = useMemo(() => {
    return checked.filter((chamber) => !sandboxChamberIds.includes(chamber.id))
      .length;
  }, [checked, sandboxChamberIds]);

  if (!data?.getAllChambers || isLoading) {
    return (
      <div style={{ marginTop: "46px" }}>
        <Loader />
      </div>
    );
  }

  const customList = (title: ReactNode, provinceGroups: ProvinceGroup[]) => (
    <Card>
      <CardHeader sx={{ px: 2, py: 1 }} title={title} />
      <h3
        style={{
          marginBottom: "0px",
          paddingLeft: "20px",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {checkItems}/{fullList.length - sandboxChamberIds.length} chambers &
        boards selected
      </h3>
      <span
        style={{
          paddingLeft: "20px",
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        (Sandbox chambers not included in count)
      </span>
      <ListItem
        key={"select-all-list-item"}
        role="listitem"
        button
        onClick={handleToggleAll(fullList)}
      >
        <ListItemIcon>
          <Checkbox
            style={{
              color: "#E1A731",
            }}
            checked={
              checked.length === fullList.length && fullList.length !== 0
            }
            disabled={fullList.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        </ListItemIcon>
        <ListItemText id={"select-all"} primary={"Select All"} />
      </ListItem>
      <Divider />
      <List
        sx={{
          height: 350,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {provinceGroups.map((provinceGroup) => (
          <React.Fragment key={provinceGroup.code}>
            <div className={classes.provinceHeader}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  style={{ color: "#E1A731", marginRight: "8px" }}
                  checked={provinceGroup.chambers.every((chamber) =>
                    checked.some((c) => c.id === chamber.id)
                  )}
                  indeterminate={
                    provinceGroup.chambers.some((chamber) =>
                      checked.some((c) => c.id === chamber.id)
                    ) &&
                    !provinceGroup.chambers.every((chamber) =>
                      checked.some((c) => c.id === chamber.id)
                    )
                  }
                  onChange={handleToggleProvince(
                    provinceGroup.code,
                    provinceGroup.chambers
                  )}
                />
                <h6 className={classes.provinceTitle}>
                  {provinceGroup.full} (
                  {
                    provinceGroup.chambers.filter((chamber) =>
                      checked.some((c) => c.id === chamber.id)
                    ).length
                  }
                  /{provinceGroup.chambers.length})
                </h6>
              </div>
            </div>
            {provinceGroup.chambers.map((value) => {
              const labelId = `transfer-list-all-item-${value.id}-label`;
              const isChecked = checked.some(
                (chamber) => chamber.id === value.id
              );
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(!isChecked, value)}
                  style={{ paddingLeft: "32px" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      style={{
                        color: "#E1A731",
                      }}
                      checked={isChecked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItem>
              );
            })}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Card>
  );

  return (
    <Grid
      container
      style={{
        marginTop: "56px",
      }}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h1 style={{ marginBottom: "0px", textAlign: "center" }}>
        Set Availability For Chambers/Boards
      </h1>

      <Grid
        container
        style={{
          marginTop: "10px",
        }}
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {customList(
            <div className={classes.searchContainer}>
              <TextField
                style={{
                  borderBottom: "none",
                  border: "1px solid #9E9E9E",
                }}
                type="text"
                placeholder="Search by Chamber/Board Name"
                onChange={handleSearchChange}
                value={searchInput}
                className={classes.searchBar}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
                }}
              />
            </div>,
            groupedByProvince
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
